import { DefaultButton } from '@fluentui/react/lib/Button';
import React from 'react';
import './Org.css';
import OrgImageAdmin from '../img/icon-Org-leader.png';
import GroupImageLeader from '../img/icon-Group-leader.png';

interface IOrgGroupListProps {
  orgList: any[];
  groupList: any[];
  setOrg: any;
  setGroup: any;
}

class OrgGroupList extends React.Component<IOrgGroupListProps> {
  /*
  constructor(props: IOrgGroupListProps) {
    super(props);
  }
  */
  public displayGroups() {
    return (
      <div className='orgGroupDiv'>
        <div key='div1' className='bookCategoryHeader'>
          <label style={{ color: 'black' }}>
            <button className='bookCategoryMark' />
            小组
          </label>
        </div>
        <div className='listDiv'>
          {this.props.groupList.length === 0 && <p> There is no groups under your management</p>}
          {this.props.groupList.map((item, index) => (
            <ul key={index} className='buttonColor' style={{ padding: 0 }}>
              <DefaultButton
                className='orgGroupButton'
                styles={{
                  flexContainer: {
                    justifyContent: 'left',
                    marginLeft: 25
                  }
                }}
                //styles={ root: [flexContainer:[justifyContent:"left"]] }
                onClick={() => this.props.setGroup(index)}>
                <img id='OrgImage' src={GroupImageLeader} alt='Group Symbol'></img>{' '}
                <span style={{ position: 'absolute', right: 5 }}>{item.Size}</span>
                {item.Name}
              </DefaultButton>
            </ul>
          ))}
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    return (
      <div>
        <div className='orgGroupDiv'>
          <div key='div1' className='bookCategoryHeader'>
            <label style={{ color: 'black' }}>
              <button className='bookCategoryMark' />
              机构
            </label>
          </div>
          <div className='listDiv'>
            {this.props.orgList.map((item, index) => (
              <ul key={index} className='buttonColor' style={{ padding: 0 }}>
                <DefaultButton
                  className='orgGroupButton'
                  styles={{
                    flexContainer: {
                      justifyContent: 'left',
                      marginLeft: 25
                    }
                  }}
                  onClick={() => {
                    this.props.setOrg(index);
                  }}>
                  <img
                    id='OrgImage'
                    src={OrgImageAdmin}
                    alt='Org Syombol'
                    style={{ position: 'absolute', left: 5 }}></img>
                  <span style={{ position: 'absolute', right: 5 }}>{item.Size}</span>
                  {item.Name}
                </DefaultButton>
              </ul>
            ))}
            {/* <DefaultButton
              styles={{
                root: {
                  marginTop: "20px",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "25px",
                  marginLeft: "200px",
                },
              }}
            >
              创建机构
            </DefaultButton> */}
          </div>
        </div>
        <div className='vl'></div>
        {this.displayGroups()}
      </div>
    );
  }
}

export default OrgGroupList;
