import React from 'react';
import { _dataProvider } from '../dataProvider/DataProvider';

const DeleteUser: React.FC = () => {
  const handleDeleteUser = async () => {
    const result = await _dataProvider.deleteUser();
    if (result === 'SUCCESS') {
      alert('用户删除成功');
    } else if (result === 'NOT_SIGNED_IN') {
      alert('删除失败，请先登录');
    } else {
      alert('用户删除失败');
    }
  };

  return (
    <div>
      <h1>Delete User</h1>
      <button onClick={handleDeleteUser}>Delete User</button>
    </div>
  );
};

export default DeleteUser;
