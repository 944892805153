import { IBook } from '../dataProvider/IBookTable';
import React from 'react';
//import { AddIcon } from '@fluentui/react/react-icons';
//import { Icon } from '@fluentui/react/lib/Icon';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { PrimaryButton } from '@fluentui/react';
import Footer from '../components/Footer';
import i18n from '../i18n';
import { _smallScreen } from '../SharedCommon/utils';
import { HostOfficialServer } from '../SharedCommon/utils';

/*
https://www.w3schools.com/howto/howto_css_dropdown.asp
/// implement hoverable drop down to do "delete, publish, unpublish, etc...
/// or add delete in the class editing screen, which already have the class state
/// to change to publish, or private, etc.
*/
const iconClass = mergeStyles({
  fontSize: 100,
  margin: '25px 25px'
});

interface IContentState {
  bookContent?: {};
}

interface IOwnerBooksProps {
  books: IBook[];
  setBook: any; // (any, boolean) :void ;

  addBook: any;
  deleteBook: any;
}

interface IOwnerBooksState {
  contentHeight: string;
}

class OwnerBooks extends React.Component<IOwnerBooksProps, IOwnerBooksState> {
  constructor(props: IOwnerBooksProps) {
    super(props);
    this.state = {
      contentHeight: 'calc(100vh - 50px)'
    };
  }

  private handleAddBook = () => {
    const bookName = i18n.t('authoring.NoName');
    this.props.addBook(bookName);
    return;
  };

  render(): React.ReactNode {
    console.log('OwnerBooks.render');
    //const { name, key } = getSignInInfo();

    //x.addListener(myFunction) // Attach listener function on state changes
    if (_smallScreen) {
      // If media query matches
      return (
        <div className='HomeMatrixBooks'>
          <h3>This functionality only works with big screen / window</h3>
        </div>
      );
    }

    return (
      <>
        <div className='HomeMatrixBooks' style={{ height: this.state.contentHeight }}>
          <div key='div1' className='bookCategoryHeader'>
            <label>
              <button className='bookCategoryMark' />
              {i18n.t('authoring.Unpublished')}
            </label>
          </div>

          <div key='div2' className='ContentV' style={{ height: 'auto' }}>
            {this.props.books.map((book, index) => {
              return (
                <div key={index}>
                  {book.status === 0 && (
                    <div key={100 + index} className='FullscreenBookCoverDiv' onClick={() => this.props.setBook(index)}>
                      <h2 className='bookNameUnderImage'>{book.classNameChs}</h2>
                      <img
                        className='FullscreenBookCover'
                        key={index}
                        src={HostOfficialServer + '/lesson/' + book.name + '/cover'}
                        alt={''}
                        onError={(e) => {
                          (e.target as HTMLButtonElement).style.visibility = 'hidden';
                        }}
                      />
                      <div className='dropdown-content-4-book'>
                        <PrimaryButton
                          title='Delete'
                          ariaLabel='Delete'
                          style={{ border: '1px solid black' }}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            this.props.deleteBook(index);
                          }}>
                          Delete
                        </PrimaryButton>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            <div key={this.props.books.length} className='FullscreenBookCoverDiv' onClick={this.handleAddBook}>
              <FontIcon aria-label='Add new book' iconName='Add' className={iconClass} />
            </div>
          </div>

          <div key='div5' className='bookCategoryHeader'>
            <label>
              <button className='bookCategoryMark' />
              {i18n.t('authoring.Published')}
            </label>
          </div>

          <div className='ContentV' style={{ height: 'auto' }}>
            {this.props.books.map((book, index) => {
              return (
                <div key={index}>
                  {book.status === 1 && book.classType === 0 && (
                    <div
                      key={1000 + index}
                      className='FullscreenBookCoverDiv'
                      onClick={() => this.props.setBook(index)}>
                      <h2 className='bookNameUnderImage'>{book.classNameChs}</h2>
                      <img
                        className='FullscreenBookCover'
                        key={index}
                        src={HostOfficialServer + '/lesson/' + book.name + '/cover'}
                        alt={''}
                        onError={(e) => {
                          (e.target as HTMLButtonElement).style.visibility = 'hidden';
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
            {this.props.books.filter((book) => {
              return book.status === 1 && book.classType === 0;
            }).length === 0 && <p style={{ marginLeft: '15px' }}>None yet</p>}
          </div>

          <div key='div3' className='bookCategoryHeader'>
            <label>
              <button className='bookCategoryMark' />
              {i18n.t('authoring.Limited')}
            </label>
          </div>

          <div className='ContentV' style={{ height: 'auto' }}>
            {this.props.books.map((book, index) => {
              return (
                <div key={index}>
                  {book.status === 1 && book.classType! > 0 && (
                    <div key={200 + index} className='FullscreenBookCoverDiv' onClick={() => this.props.setBook(index)}>
                      <h2 className='bookNameUnderImage'>{book.classNameChs}</h2>
                      <img
                        className='FullscreenBookCover'
                        key={index}
                        src={HostOfficialServer + '/lesson/' + book.name + '/cover'}
                        alt={''}
                        onError={(e) => {
                          (e.target as HTMLButtonElement).style.visibility = 'hidden';
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
            {this.props.books.filter((book) => {
              return book.status === 1 && book.classType! > 0;
            }).length === 0 && <p style={{ marginLeft: '15px' }}>None yet</p>}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default OwnerBooks;
