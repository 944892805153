import React, { useState } from 'react';
import { IContactItem } from './GroupMgmt';
import { _dataProvider, IUser } from '../dataProvider/DataProvider';
import { t } from 'i18next';
import './ClassRegistration.css';
import { Dialog } from '@fluentui/react/lib/Dialog';
import ChecklistContainer from './CheckListContainer';
import { getOrgLogo } from '../SharedCommon/OrgList';

interface IAddNewGroupWindowProps {
  onClose: () => void;
  onAddGroup: () => void;
  users: IContactItem[];
}

const AddNewGroupWindow: React.FC<IAddNewGroupWindowProps> = ({ onClose, onAddGroup, users }) => {
  const [groupName, setGroupName] = useState('');
  const [groupMembers, setGroupMembers] = useState<string[]>([]);

  const convertToIUser = (contactItem: IContactItem): IUser => {
    return {
      userId: contactItem.userId,
      name: contactItem.name,
      displayName: contactItem.displayName,
      loginId: contactItem.loginId,
      isGroupLeader: false // Default value
    };
  };

  const handleGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      await _dataProvider.createSmallGroup(groupName, groupMembers);
      onAddGroup();
      onClose(); // Close the modal upon successful submission
    } catch (error) {
      console.error('Failed to create group:', error);
      alert(t('FailedToCreateGroup'));
    }
  };

  const IDigestLogo = getOrgLogo(_dataProvider.getOrgId());
  return (
    <Dialog
      hidden={false}
      onDismiss={onClose}
      dialogContentProps={{
        showCloseButton: true
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { borderRadius: '20px' } },
        containerClassName: 'PickGroupDialogue'
      }}>
      <div className='PickGroup'>
        <img alt='iDigestApp' className='centeriDigestLogo' src={IDigestLogo} />
        <br />
        <h2>{t('AddNewGroup')}</h2>
        <div className='input-container'>
          <h3>{t('SmallGroupName')}</h3>
          <input
            id='groupName'
            value={groupName}
            onChange={handleGroupNameChange}
            style={{
              padding: '10px',
              margin: 'auto',
              width: '50%',
              borderRadius: '25px'
            }}
          />
        </div>
        <ChecklistContainer
          otherUsers={users.map(convertToIUser)}
          selectedUsers={groupMembers}
          setSelectedUsers={setGroupMembers}
          handleUpdate={handleSubmit}
          addMemberButtonText='Create'
        />
      </div>
    </Dialog>
  );
};

export default AddNewGroupWindow;
