import React, { useState, useEffect } from 'react';
import './ClassManagement.css';
import { TextField, Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import { Input, SpinButton } from '@fluentui/react-components';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Image } from '@fluentui/react/lib/Image';
import { Link } from '@fluentui/react/lib/Link';
import { _dataProvider } from '../dataProvider/DataProvider';
import QRCode from 'qrcode'; // Frome here: https://www.npmjs.com/package/qrcode
import { ITrainingDetails, ICourse, IStudent } from '../dataProvider/IClassManagement';
import StudentOverviewList from './StudentOverviewList';
import CourseProgressReport from './CourseProgressReport';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import i18n from '../i18n';
import ExcelJS from 'exceljs';
import { SingleRoleUserMgmt } from './SingleRoleUserMgmt';
import { HostOfficialServer, _smallScreen } from '../SharedCommon/utils';

interface IButton {
  id: number;
  text: string;
  isEditing: boolean;
  selectedAction?: string;
  originalStatus?: string;
}

interface ITraining {
  trainingId: number;
  name: string;
  status: number;
  classes: any[];
  groups: any[];
  joinToken?: string;
}

interface ICourseDialogProps {
  isOpen: boolean;
  onClose: () => void;
  courses: ICourse[];
  onAddClass: (ClassId: number) => void;
}

interface IGroup {
  groupId: number;
  name: string;
  subTitle: string;
  createdTime: string;
  lastModifiedTime: string;
  lastMessageTime: string | null;
  status: number;
  disappearingMessages: number;
  isGroupLeader?: number;
  StudyGroupName?: string;
  memberCount?: number;
}

interface IQRCodeComponentProps {
  url: string;
}

interface IOrganization {
  id: number;
  name: string;
}

const ClassManagement = () => {
  let inCreateNew = false;
  const navigate = useNavigate();
  const isMobile = _smallScreen;
  const [contentHeight, setContentHeight] = useState(`${window.innerHeight - 52}px`);

  // Function to update content height based on window dimensions
  const updateDimensions = () => {
    if (!document || !document.getElementById('idHeaderMenuBar')) {
      return;
    }
    setContentHeight(`${window.innerHeight - document!.getElementById('idHeaderMenuBar')!.offsetHeight - 30}px`);
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const checkUserRole = async () => {
      const signInState = localStorage.getItem('SignInState');
      let role = 0;

      if (signInState) {
        const signInStateObj = JSON.parse(signInState);
        const targetOrg = signInStateObj.organizations?.find(
          (org: IOrganization) => org.id === _dataProvider.getOrgId()
        );

        role = targetOrg?.role ?? 0;
      }

      if (role !== 1) {
        navigate('/');
        console.log('role:', role);
      }
    };

    checkUserRole();
  }, [navigate]);

  const [buttons, setButtons] = useState<IButton[]>([]);
  const [selectedButtonId, setSelectedButtonId] = useState<number | null>(null);
  const [groups, setGroups] = useState<number[]>([]);
  const [activeGroup, setActiveGroup] = useState<string | null>(null);
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [addedCourses, setAddedCourses] = useState<ICourse[]>([]);
  const [activeCourseReport, setActiveCourseReport] = useState<string | null>(null);
  const [, setActiveCourseId] = useState<number | null>(null);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [, setSelectedTrainingStatus] = useState<number>(0);
  const [groupDetails, setGroupDetails] = useState<IGroup[]>([]);
  const [studentIDMap, setStudentIDMap] = useState(new Map());

  const currentTrainingId = selectedButtonId;
  const baseFrontendUrl = `${window.location.protocol}//${window.location.host}`;
  const [joinToken, setJoinToken] = useState('');
  const registrationLink = `${baseFrontendUrl}/#/classregister/${currentTrainingId}/${joinToken}`;

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState<ICourse | null>(null);

  const [groupList, setGroupList] = useState<IGroup[]>([]);
  const [isGroupDialogVisible, setIsGroupDialogVisible] = useState(false);
  const [isTrainingProgressDialogVisible, setIsTrainingProgressDialogVisible] = useState(false);

  const [, setAvailableCourses] = useState<ICourse[]>([]);

  const [isRemoveGroupDialogVisible, setIsRemoveGroupDialogVisible] = useState(false);
  const [groupToRemove, setGroupToRemove] = useState<IGroup | null>(null);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [students, setStudents] = useState<IStudent[]>([]);

  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [trainingToDelete, setTrainingToDelete] = useState<number | null>(null);
  const [totalStudentsCount, setTotalStudentsCount] = useState(0);
  const [showAllStudents] = useState(false);

  const [trainingData, setTrainingData] = useState<{ currentChapter: number; reminder: string }>({
    currentChapter: 0,
    reminder: ''
  });

  const dropdownOptions: IDropdownOption[] = [
    { key: 'ready', text: '准备' },
    { key: 'rename', text: '改名' },
    { key: 'registration', text: '报名' },
    { key: 'learning', text: '学习' },
    { key: 'completed', text: '完成' },
    { key: 'delete', text: '删除' }
  ];

  const dropdownStyles: Partial<IDropdownStyles> = {
    caretDownWrapper: {
      selectors: {
        '& .ms-Dropdown-caretDown': {
          color: 'black',
          fontWeight: 'bold',
          fontSize: '20px'
        }
      }
    }
  };

  const modalProps = {
    isBlocking: false,
    styles: {
      main: [
        {
          selectors: {
            ['']: {
              minWidth: '550px',
              borderRadius: '10px'
            },
            '.ms-Dialog-actionsRight': {
              marginRight: '100px'
            }
          }
        }
      ],
      closeButton: {
        marginRight: '12px',
        marginTop: '-20px'
      }
    }
  };

  const onDropdownChange = (buttonId: number, event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      const updatedButtons = buttons.map((btn) => {
        if (btn.id === buttonId) {
          if (option.key === 'delete') {
            return {
              ...btn,
              originalStatus: btn.selectedAction
            };
          }
          if (option.key === 'rename') {
            return {
              ...btn,
              isEditing: true,
              originalStatus: btn.selectedAction
            };
          } else {
            return { ...btn, selectedAction: option.key as string };
          }
        }
        return btn;
      });

      setButtons(updatedButtons);

      const button = updatedButtons.find((btn) => btn.id === buttonId);
      if (button) {
        handleSelectChange(button, option.key as string);
        let status: number;
        switch (option.key) {
          case 'ready':
            status = 0;
            break;
          case 'registration':
            status = 1;
            break;
          case 'learning':
            status = 2;
            break;
          case 'completed':
            status = 3;
            break;
          default:
            status = -1;
            break;
        }
        if (option.key !== 'rename' && option.key !== 'delete') {
          updateTrainingStatus(button.id, status);
          console.log('option key is', option.key);
        }
      }
    }
  };

  const handleCreateNew = () => {
    if (inCreateNew) {
      //still processing the last create
      return;
    }
    inCreateNew = true;
    const newTrainingDetails: ITrainingDetails = {
      name: `${i18n.t('NewTraining')}${buttons.length + 1}`,
      status: 0
    };

    _dataProvider
      .handleCreateNewTraining(newTrainingDetails)
      .then((result) => {
        console.log('training create: ', result);
        if (result && result.trainingId) {
          const newTrainingId = result.trainingId;

          setButtons((currentButtons) => [
            {
              id: newTrainingId,
              text: newTrainingDetails.name,
              isEditing: false,
              selectedAction: getStatusKey(0)
            },
            ...currentButtons
          ]);

          setSelectedButtonId(newTrainingId);
          fetchTrainingDetail(newTrainingId);
        } else {
          console.error('创建新课程失败');
        }
      })
      .catch((error) => {
        console.error('创建新课程过程中出错:', error);
      })
      .finally(() => {
        inCreateNew = false;
      });
  };

  const handleSelectButton = (id: number) => {
    setSelectedButtonId(id);
    fetchTrainingDetail(id);

    setActiveGroup(null);
    setActiveCourseReport(null);
  };

  const handleSelectChange = (button: IButton, action: string) => {
    if (action === 'delete') {
      showDeleteConfirmation(button.id);
    } else if (action === 'rename' && !button.isEditing) {
      startEditing(button.id);
    } else if (action === 'save') {
      handleFinishEditing(button.id);
    }
  };

  const handleNameChange = (id: number, newText: string) => {
    setButtons((currentButtons) =>
      currentButtons.map((button) => (button.id === id ? { ...button, text: newText } : button))
    );
  };

  const startEditing = (id: number) => {
    setButtons((currentButtons) =>
      currentButtons.map((button) => (button.id === id ? { ...button, isEditing: true } : button))
    );
  };

  const handleFinishEditing = async (id: number) => {
    const newName = buttons.find((button) => button.id === id)?.text;
    if (newName) {
      const result = await _dataProvider.handleRenameTraining(id, newName);
      if (result) {
        const updatedButtons = buttons.map((button) =>
          button.id === id
            ? {
                ...button,
                text: newName,
                isEditing: false,
                selectedAction: button.originalStatus
              }
            : button
        );
        setButtons(updatedButtons);
        fetchTrainings();
      } else {
        console.error('更新课程名称失败');
      }
    } else {
      setButtons((currentButtons) =>
        currentButtons.map((button) => (button.id === id ? { ...button, isEditing: false } : button))
      );
    }
  };

  const handleAddGroup = async (group: IGroup) => {
    if (selectedButtonId) {
      setIsGroupDialogVisible(false); //prevent multiple clicks
      await fetchTrainingDetail(selectedButtonId);
      //await fetchUserGroups();

      if (groups.includes(group.groupId)) {
        alert('该小组已被添加。');
        return;
      }

      try {
        await _dataProvider.handleAddGroup2Training(group.groupId, selectedButtonId);
        await fetchTrainingDetail(selectedButtonId);
      } catch (error) {
        console.error('添加学习小组失败:', error.response.data);
      }
    } else {
      console.error('没有选中的课程，无法添加学习小组');
    }
  };

  const handleGroupClick = async (groupId: number | null) => {
    if (groupId === null) {
      if (currentTrainingId !== null) {
        setActiveGroup('全部学员');
        setSelectedGroupId(null);
      } else {
        console.error('currentTrainingId is null, cannot fetch all users');
      }
    } else {
      const group = groupDetails.find((g) => g.groupId === groupId);
      if (group) {
        setActiveGroup(group.name);
        setSelectedGroupId(groupId);
      }
    }
  };

  const handleOpenGroupDialog = async () => {
    await fetchUserGroups();
    setIsGroupDialogVisible(true);
  };

  const getStatusKey = (status: number) => {
    switch (status) {
      case 0:
        return 'ready';
      case 1:
        return 'registration';
      case 2:
        return 'learning';
      case 3:
        return 'completed';
      default:
        return '';
    }
  };

  const fetchTrainings = async () => {
    const trainings: ITraining[] = await _dataProvider.fetchTrainings();
    const sortedTrainings = trainings.sort((a, b) => b.trainingId - a.trainingId);
    const updatedButtons = sortedTrainings.map((training: ITraining) => ({
      id: training.trainingId,
      text: training.name,
      isEditing: false,
      selectedAction: getStatusKey(training.status)
    }));

    setButtons(updatedButtons);
  };
  const fetchTrainingDetail = async (trainingId: number) => {
    const trainingDetail: any = await _dataProvider.fetchTrainingDetail(trainingId);

    if (!trainingDetail) {
      return;
    }

    const usersInGroups = new Set();

    const groupDetailsWithMemberCount = await Promise.all(
      trainingDetail.data.groups.map(async (group: any) => {
        const students = await _dataProvider.fetchStudentsForGroup(group.groupId, trainingId);
        students.forEach((student: IStudent) => {
          usersInGroups.add(student.key);
          // Add a new pairing of student key to real name to the map
          setStudentIDMap((prevMap) => new Map(prevMap).set(student.key, student.realName));
        });
        return {
          ...group,
          memberCount: students.length
        };
      })
    );

    console.log('users in groups:', usersInGroups);
    const totalUsersInGroups = usersInGroups.size;

    const convertedCourses = trainingDetail.data.classes.map((course: any) => ({
      ClassId: course.classId,
      ClassNameChs: course.name,
      imageUrl: course.coverImageUrl || '',
      isHovered: false,
      hasImageError: false
    }));

    setJoinToken(trainingDetail.data.joinToken || '');
    setGroups(trainingDetail.data.groups.map((group: any) => group.name));
    setAddedCourses(convertedCourses);
    setGroupDetails(groupDetailsWithMemberCount);
    setTotalStudentsCount(totalUsersInGroups);
    if (!trainingDetail.data.data) {
      trainingDetail.data.data = '{}';
    }
    setTrainingData(JSON.parse(trainingDetail.data.data));
  };

  const fetchCourses = async () => {
    const courses = await _dataProvider.fetchCourses();
    if (courses) {
      setCourses(courses);
    } else {
      setCourses([]);
    }
  };

  useEffect(() => {
    fetchTrainings();
    fetchCourses();
    setSelectedTrainingStatus(0);
  }, []);

  const handleAddClassToTraining = async (ClassId: number) => {
    if (selectedButtonId) {
      setIsDialogVisible(false);
      const isAlreadyAdded = addedCourses.some((course) => course.ClassId === ClassId);

      if (isAlreadyAdded) {
        alert('该课程已添加！');
        return;
      }
      const result = await _dataProvider.handleAddClassToTraining(selectedButtonId, ClassId);
      if (result) {
        const addedCourse = courses.find((course) => course.ClassId === ClassId);
        if (addedCourse) {
          setAddedCourses((prevCourses) => [...prevCourses, addedCourse]);
        }
      }
    } else {
      console.error('没有选中的培训，无法添加课程');
    }
  };

  const updateTrainingStatus = async (trainingId: number, newStatus: number) => {
    const result = await _dataProvider.updateTrainingStatus(trainingId, newStatus);
    if (result) {
      console.log('培训状态更新成功');
    } else {
      console.error('更新培训状态失败');
    }
  };

  const handleDeleteTraining = async (trainingId: number) => {
    const result = await _dataProvider.handleDeleteTraining(trainingId);
    if (result) {
      setButtons((prevButtons) => prevButtons.filter((button) => button.id !== trainingId));

      setSelectedButtonId(null);
      setActiveGroup(null);
      setActiveCourseReport(null);
      setAddedCourses([]);
      setGroupDetails([]);
      console.log('培训删除成功');
    } else {
      console.error('删除培训失败');
    }
  };

  const handleRemoveClassFromTraining = async (ClassId: number) => {
    if (selectedButtonId) {
      const result = await _dataProvider.handleRemoveClassFromTraining(selectedButtonId, ClassId);
      if (result) {
        setAddedCourses((currentCourses) => currentCourses.filter((course) => course.ClassId !== ClassId));
        setAvailableCourses((currentAvailableCourses) =>
          currentAvailableCourses.filter((course) => course.ClassId !== ClassId)
        );
        console.log('课程删除成功');
      } else {
        console.error('删除课程失败');
      }
    } else {
      console.error('没有选中的培训，无法删除课程');
    }
  };

  const handleRemoveGroup = async (groupId: number) => {
    if (selectedButtonId) {
      const result = await _dataProvider.handleRemoveGroupFromTraining(selectedButtonId, groupId);
      if (result) {
        console.log('小组已移除');
        setGroupDetails((prevGroupDetails) => prevGroupDetails.filter((group) => group.groupId !== groupId));

        // Update the pick-group list by fetching the training detail
        await fetchTrainingDetail(selectedButtonId);
      } else {
        console.error('移除小组失败');
      }
    } else {
      console.error('没有选中的课程，无法移除小组');
    }
  };

  // Function to generate XLS from JSON data
  function generateXLSFromJSON(jsonData: Record<string, any>): ExcelJS.Workbook {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    // Initialize arrays to store column headers and rows
    const headers: string[] = ['username', 'week']; // Initial headers for userId and week
    const rows: any[] = [];

    // Loop through each object in the JSON array
    jsonData.forEach((obj: any) => {
      // Add userId and week values to the headers array if they don't already exist
      if (!headers.includes('username')) {
        headers.push('username');
      }
      if (!headers.includes('week')) {
        headers.push('week');
      }
      // for the question headers
      // Loop through answers object to add QIDs as headers and corresponding answers as values
      Object.entries(obj.answers).forEach(([qid, answer]) => {
        if (!headers.includes(qid)) {
          headers.push(qid);
          console.log(answer);
        } // Add QID as header if it doesn't exist
      });
      console.log('here are all the groups');
      console.log(studentIDMap);
      const realName = studentIDMap.get(obj.userId) || 'not found';
      // Push values for userId, week, and answers into rows array
      rows.push({
        username: realName,
        week: obj.week,
        ...obj.answers // Spread operator to add answers as separate columns based on QIDs
      });
    });

    // Set headers based on keys
    worksheet.columns = headers.map((header) => ({ header: header, key: header }));

    // Add rows to the worksheet
    rows.forEach((row) => {
      worksheet.addRow(row);
    });

    return workbook;
  }

  async function save(fileHandle: any, blob: Blob) {
    try {
      const stream = await fileHandle.createWritable();
      await stream.write(blob);
      await stream.close();
      console.log('File saved successfully.');
    } catch (error) {
      console.error('Error saving file:', error);
    }
  }

  async function saveAs(blob: Blob, defaultFileName: string) {
    try {
      const fileHandle = await (window as any).showSaveFilePicker({
        suggestedName: defaultFileName
      });
      await save(fileHandle, blob);
    } catch (error) {
      console.error('Error saving file as:', error);
    }
  }

  async function processClassAnswersExcel(orgId: number | undefined, trainingId: number | null, classId: number) {
    try {
      console.log(orgId);
      console.log(trainingId);
      console.log(classId);

      // Use the processed data directly
      const jsonData = await _dataProvider.getClassAnswers(orgId, trainingId, classId);

      const workbook = generateXLSFromJSON(jsonData);
      try {
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        await saveAs(blob, 'StudentAnswers.xlsx');
      } catch (error) {
        console.error('Error downloading xls:', error);
      }
    } catch (error) {
      console.error('Error processing class answers:', error);
    }
  }

  //const showCourseReport = (course: ICourse) => {
  //  setActiveCourseReport(course.ClassNameChs + ' 课程学习统计报告');
  //  setActiveCourseId(course.ClassId);
  //  setActiveGroup(null);
  //};

  const handleMouseEnter = (ClassId: number) => {
    setAddedCourses((currentCourses) =>
      currentCourses.map((course) => (course.ClassId === ClassId ? { ...course, isHovered: true } : course))
    );
  };
  const handleMouseLeave = (ClassId: number) => {
    setAddedCourses((currentCourses) =>
      currentCourses.map((course) => (course.ClassId === ClassId ? { ...course, isHovered: false } : course))
    );
  };

  const CourseDialog: React.FC<ICourseDialogProps> = ({ isOpen, onClose, courses, onAddClass }) => {
    const allCoursesAdded = courses.every((course) =>
      addedCourses.some((addedCourse) => addedCourse.ClassId === course.ClassId)
    );

    if (allCoursesAdded) {
      return (
        <Dialog hidden={!isOpen} onDismiss={onClose} modalProps={modalProps}>
          <div className='course-dialog-container'>
            <p className='added-all-courses'>没有可以添加的课程</p>
          </div>
          <div className='dialog-close-button-container'>
            <DefaultButton onClick={onClose} className='dialog-close-button'>
              X
            </DefaultButton>
          </div>
        </Dialog>
      );
    }

    const availableCourses = courses.filter((course) => {
      const isAlreadyAdded = addedCourses.some((addedCourse) => addedCourse.ClassId === course.ClassId);
      return !isAlreadyAdded;
    });

    return (
      <Dialog hidden={!isOpen} onDismiss={onClose} modalProps={modalProps}>
        <div className='course-dialog-container'>
          {availableCourses.map((course) => (
            <DefaultButton key={course.ClassId} className='course-item' onClick={() => onAddClass(course.ClassId)}>
              <Image
                src={`${HostOfficialServer}/lesson/${course.ClassId}/cover`}
                alt={course.ClassNameChs}
                style={{ width: 140, height: 140 }}
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.style.display = 'none';
                  const parentElement = e.currentTarget.parentElement;
                  if (parentElement) {
                    const textElement = document.createElement('div');
                    textElement.textContent = course.ClassNameChs;
                    textElement.style.padding = '10px';
                    parentElement.appendChild(textElement);
                  }
                }}
              />
            </DefaultButton>
          ))}
        </div>
        <div className='dialog-close-button-container'>
          <DefaultButton onClick={onClose} className='dialog-close-button'>
            X
          </DefaultButton>
        </div>
      </Dialog>
    );
  };

  const handleOpenAddCourseModal = () => {
    setIsDialogVisible(true);
    console.log('Opening add course modal');
  };

  const QRCodeComponent: React.FC<IQRCodeComponentProps> = ({ url }) => {
    const [qrCodeSrc, setQrCodeSrc] = useState<string>('');

    useEffect(() => {
      QRCode.toDataURL(url)
        .then((url) => {
          setQrCodeSrc(url);
        })
        .catch((err) => {
          console.error('Error generating QR Code:', err);
        });
    }, [url]);

    return qrCodeSrc ? <img src={qrCodeSrc} alt='QR Code' /> : null;
  };

  const fetchUserGroups = async () => {
    const groups = await _dataProvider.getUserGroups();
    if (groups) {
      const leaderGroups = groups.filter((group: IGroup) => group.isGroupLeader === 1);
      setGroupList(leaderGroups);
    }
  };

  const handleRemoveButtonClick = (group: IGroup) => {
    setGroupToRemove(group);
    setIsRemoveGroupDialogVisible(true);
  };

  const dialogContentStyles = {
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
      padding: '20px 0',
      fontSize: '30px'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '20px 0'
    },
    topButton: { top: '-30px' }
  };

  const dialogStyles = {
    main: {
      width: '100%',
      maxWidth: 'none',
      minWidth: 'none',
      borderRadius: '20px',
      selectors: {
        '.ms-Dialog-content': {
          padding: '40px 48px'
        },
        '.ms-Dialog-inner': {
          width: '100%'
        }
      }
    }
  };

  const saveTrainingProgression = async (currentChapter: number, reminder: string) => {
    const progression = { currentChapter, reminder };
    const success = await _dataProvider.saveTrainingData(selectedButtonId!, progression);
    if (success) {
      setTrainingData(progression);
      setIsTrainingProgressDialogVisible(false);
    } else {
      alert(i18n.t('NetworkFailure'));
    }
  };

  const TrainingProgressDialog: React.FC = () => {
    const progression = trainingData;
    const [currentChapter, setCurrentChapter] = useState(progression.currentChapter ?? 1);
    const [reminder, setReminder] = useState(progression.reminder ?? '');
    return (
      <Dialog
        hidden={!isTrainingProgressDialogVisible}
        onDismiss={() => setIsTrainingProgressDialogVisible(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: i18n.t('TrainingProgression'),
          showCloseButton: true,
          styles: {
            title: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '30px'
            },
            content: {
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        }}
        modalProps={{
          isBlocking: false,
          styles: dialogStyles,
          containerClassName: 'dialog-group-container'
        }}>
        {i18n.t('CurrentChapter')}
        <SpinButton
          value={currentChapter}
          style={{
            width: '100%',
            marginTop: '5px',
            border: 'solid',
            boxSizing: 'border-box',
            padding: '3px 3px 3px 8px'
          }}
          min={1}
          max={31}
          id='100'
          onChange={(ev, data) => {
            console.log(data);
            const newValue = data.value ?? parseInt(data.displayValue || '0');
            if (newValue >= 1 && newValue <= 31) {
              setCurrentChapter(newValue);
            }
          }}
        />
        <br></br>
        <br></br>
        {i18n.t('Reminder')}
        <Input
          key='Reminder'
          defaultValue={reminder}
          style={{
            width: '100%',
            marginTop: '5px',
            border: 'solid',
            boxSizing: 'border-box',
            padding: '3px 3px 3px 8px'
          }}
          onChange={(ev, data) => setReminder(data.value)}
        />
        <DialogFooter>
          <DefaultButton
            onClick={() => saveTrainingProgression(currentChapter, reminder)}
            text={i18n.t('UpdateTrainingProgression')}
          />
        </DialogFooter>
      </Dialog>
    );
  };

  const GroupDialog: React.FC = () => {
    return (
      <Dialog
        hidden={!isGroupDialogVisible}
        onDismiss={() => setIsGroupDialogVisible(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: '请选择小组加入培训',
          showCloseButton: true,
          styles: dialogContentStyles
        }}
        modalProps={{
          isBlocking: false,
          styles: dialogStyles,
          containerClassName: 'dialog-group-container'
        }}>
        {groupList.map((group: IGroup) => {
          const isAdded = groupDetails.some((addedGroup) => addedGroup.groupId === group.groupId);
          return (
            <DefaultButton
              key={group.groupId}
              onClick={() => {
                !isAdded && handleAddGroup(group);
              }}
              text={group.name}
              className={`class-management-button group-button dialog-group-Button ${isAdded ? 'added-group' : ''}`}
              style={{
                backgroundColor: isAdded ? '#C1C1C1' : '',
                color: isAdded ? '#7E7E7E' : '',
                cursor: isAdded ? 'not-allowed' : 'pointer'
              }}
            />
          );
        })}
      </Dialog>
    );
  };

  const RemoveGroupConfirmationDialog: React.FC = () => {
    const handleConfirmRemoveGroup = () => {
      // 调用实际处理移除的函数
      if (groupToRemove) {
        handleRemoveGroup(groupToRemove.groupId);
      }
      setIsRemoveGroupDialogVisible(false); // 关闭对话框
    };

    const handleCancelRemoveGroup = () => {
      setIsRemoveGroupDialogVisible(false); // 仅关闭对话框
    };

    return (
      <Dialog
        hidden={!isRemoveGroupDialogVisible}
        onDismiss={handleCancelRemoveGroup}
        dialogContentProps={{
          type: DialogType.normal,
          title: '请确认',
          subText: `从培训中移除"${groupToRemove?.name}"。小组不会被删除。`,
          styles: dialogContentStyles,
          showCloseButton: true
        }}
        modalProps={modalProps}>
        <DialogFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: '100 px'
            }}>
            <DefaultButton onClick={handleCancelRemoveGroup} text='取消' className='unique-dialog-button' />
            <DefaultButton onClick={handleConfirmRemoveGroup} text='确认' className='unique-dialog-button' />
          </div>
        </DialogFooter>
      </Dialog>
    );
  };

  useEffect(() => {
    fetchTrainings();
    fetchCourses();
    setSelectedTrainingStatus(0);
    fetchUserGroups();
  }, []);

  useEffect(() => {
    if (selectedButtonId !== null) {
      _dataProvider.fetchTrainingStatus(selectedButtonId);
    }
  }, [selectedButtonId, _dataProvider]);

  const updateStudents = (newStudents: IStudent[]) => {
    setStudents(newStudents);
  };

  const exportToExcel = (students: IStudent[], groupId: number) => {
    const headerMap: { [key: string]: string } = {
      uniqueId: '鸽子号',
      realName: '真实姓名',
      trainingGroup: '小组',
      displayName: '显示名',
      phone: '电话',
      email: '电子邮箱',
      wechatId: '微信',
      education: '学历',
      provinceCity: '省市',
      yearsAsChristian: '信主年数',
      careExperience: '关怀经验',
      trainingExpectation: '培训期待'
    };

    console.log('export: ', students);
    const dataWithChineseHeaders = students.map((student) => {
      const mappedStudent: { [key: string]: string | number } = {};
      (Object.keys(student) as Array<keyof IStudent>).forEach((key) => {
        const chineseHeader = headerMap[key];
        if (chineseHeader) {
          mappedStudent[chineseHeader] = student[key];
        }
      });
      return mappedStudent;
    });

    const ws = XLSX.utils.json_to_sheet(dataWithChineseHeaders);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Students');

    XLSX.writeFile(wb, `students_group_${groupId}.xlsx`);
  };

  const showDeleteConfirmation = (trainingId: number) => {
    setTrainingToDelete(trainingId);
    setIsDeleteConfirmationVisible(true);

    setActiveGroup(null);
    setActiveCourseReport(null);
  };

  const DeleteConfirmationDialog = () => {
    const trainingName = buttons.find((button) => button.id === selectedButtonId)?.text || '该培训';
    return (
      <Dialog
        hidden={!isDeleteConfirmationVisible}
        onDismiss={() => setIsDeleteConfirmationVisible(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: '请确认',
          showCloseButton: true,
          subText: `删除培训 "${trainingName}"，与培训相关的所有信息将无法获得`,
          styles: {
            title: {
              textAlign: 'center',
              marginTop: '40px'
            },
            subText: {
              textAlign: 'center'
            }
          }
        }}
        modalProps={{
          isBlocking: false,
          styles: {
            main: {
              minWidth: 650,
              minHeight: 250,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '20px'
            }
          }
        }}>
        <DialogFooter
          styles={{
            actions: {
              display: 'flex',
              justifyContent: 'center'
            }
          }}>
          <DefaultButton onClick={() => setIsDeleteConfirmationVisible(false)} text='取消' />
          <DefaultButton
            onClick={() => {
              if (trainingToDelete !== null) {
                handleDeleteTraining(trainingToDelete);
                setIsDeleteConfirmationVisible(false);
              }
            }}
            text='确认'
          />
        </DialogFooter>
      </Dialog>
    );
  };

  if (isMobile) {
    return (
      <div className='mobile-message'>
        <h3>This functionality only works with big screen / window</h3>
      </div>
    );
  }

  return (
    <div className='container'>
      <div className='leftPanel' style={{ height: contentHeight }}>
        <SingleRoleUserMgmt contextId={'' + _dataProvider.getOrgId()} role='Admin' />
        <DefaultButton
          className='class-management-button add-training-button unique-add-button'
          style={{ marginTop: '10px' }}
          onClick={handleCreateNew}>
          +添加新培训
        </DefaultButton>
        <div>
          {buttons.map((button) => (
            <div key={button.id} className='button-container'>
              {button.isEditing ? (
                <>
                  <TextField
                    type='text'
                    value={button.text}
                    className='add-training-button'
                    onChange={(e, newValue) => handleNameChange(button.id, newValue || '')}
                  />
                  <DefaultButton onClick={() => handleFinishEditing(button.id)} className='save-rename-button'>
                    保存
                  </DefaultButton>
                </>
              ) : (
                <>
                  <DefaultButton
                    onClick={() => handleSelectButton(button.id)}
                    className={`class-management-button add-training-button ${
                      selectedButtonId === button.id ? 'button-selected' : ''
                    }`}>
                    {button.text}
                  </DefaultButton>
                  <Dropdown
                    selectedKey={button.selectedAction}
                    onChange={(event, option) => onDropdownChange(button.id, event, option)}
                    className='customDropdown'
                    options={dropdownOptions}
                    placeholder='选择操作'
                    styles={dropdownStyles}
                  />
                </>
              )}
            </div>
          ))}
        </div>
        <div className='delete-training-dialog'>
          <DeleteConfirmationDialog />
        </div>
      </div>
      {selectedButtonId !== null && (
        <div className='rightPanel' style={{ height: contentHeight }}>
          {activeGroup ? (
            <>
              <div className='header-with-back-button'>
                <DefaultButton
                  onClick={() => {
                    setActiveGroup(null);
                    //group size needs to be update
                    fetchTrainingDetail(selectedButtonId);
                  }}>
                  &lt; 返回
                </DefaultButton>
                <h2 style={{ textAlign: 'center' }}>{activeGroup}学员一览表</h2>
                <DefaultButton
                  text='导出'
                  onClick={() => {
                    exportToExcel(students, selectedGroupId ?? -1);
                  }}
                  className='export-button'
                />
              </div>
              {currentTrainingId !== null && (
                <StudentOverviewList
                  groupId={activeGroup === '全部学员' ? null : selectedGroupId}
                  trainingId={currentTrainingId}
                  updateStudents={updateStudents}
                />
              )}
            </>
          ) : activeCourseReport ? (
            <>
              <div className='header-with-back-button'>
                <DefaultButton
                  onClick={() => {
                    setActiveCourseReport(null);
                    setActiveCourseId(null);
                  }}>
                  &lt; 返回
                </DefaultButton>
                <h2>{activeCourseReport}</h2>
              </div>
              <div className='course-progress-report-container'>
                <CourseProgressReport />
              </div>
            </>
          ) : (
            <>
              <section className='session'>
                <h3>学习小组</h3>
                <Link
                  onClick={() => handleGroupClick(null)}
                  styles={{
                    root: {
                      margin: '10px',
                      fontSize: '16px',
                      textDecoration: 'underline'
                    }
                  }}>
                  <RemoveGroupConfirmationDialog />
                  <GroupDialog />
                  <div className='all-group-link-container'>全部学员 ({totalStudentsCount})</div>
                  {showAllStudents && currentTrainingId !== null && (
                    <StudentOverviewList
                      groupId={selectedGroupId}
                      trainingId={currentTrainingId}
                      updateStudents={updateStudents}
                    />
                  )}
                </Link>
                {groupDetails.map((group) => (
                  <div key={group.groupId} className='group-item'>
                    <div className='course-item-container'>
                      <DefaultButton className='group-button' onClick={() => handleGroupClick(group.groupId)}>
                        {`${group.name} (${group.memberCount || 0})`}
                      </DefaultButton>
                      {groupDetails.length > 1 && (
                        <DefaultButton
                          className='remove-group-button'
                          onClick={() => handleRemoveButtonClick(group)}
                          text='- 移除'
                        />
                      )}
                    </div>
                  </div>
                ))}

                <DefaultButton className='add-group-button class-management-button' onClick={handleOpenGroupDialog}>
                  +增加新小组
                </DefaultButton>
              </section>
              <section className='session'>
                <h3>学习课程</h3>
                <div className='courses-container'>
                  {addedCourses.map((course, index) => (
                    <div key={index} className='course-item-container'>
                      <div
                        className='FullscreenBookCoverDiv'
                        onMouseEnter={() => handleMouseEnter(course.ClassId)}
                        onMouseLeave={() => handleMouseLeave(course.ClassId)}>
                        {course.hasImageError ? (
                          <div className='course-name-placeholder'>{course.ClassNameChs}</div>
                        ) : (
                          <Image
                            src={`${HostOfficialServer}/lesson/${course.ClassId}/cover`}
                            alt={course.ClassNameChs}
                            className='FullscreenBookCover'
                            style={{ width: 140, height: 140 }}
                            onError={(e) => {
                              e.currentTarget.onerror = null;
                              setAddedCourses((prevCourses) =>
                                prevCourses.map((c, i) => (i === index ? { ...c, hasImageError: true } : c))
                              );
                            }}
                          />
                        )}
                        {course.isHovered && (
                          <div className='course-hover-buttons'>
                            {_dataProvider.moreUserDataForOrg() && (
                              <DefaultButton
                                className='detail-button'
                                onClick={() =>
                                  processClassAnswersExcel(_dataProvider.getOrgId(), currentTrainingId, course.ClassId)
                                }
                                text='详情'
                              />
                            )}
                            <DefaultButton
                              className='delete-button'
                              onClick={() => {
                                setCourseToDelete(course);
                                setIsDeleteDialogVisible(true);
                              }}
                              text='删除'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  <DefaultButton onClick={handleOpenAddCourseModal} className='add-course-button'>
                    +添加课程
                  </DefaultButton>
                </div>
                <CourseDialog
                  isOpen={isDialogVisible}
                  onClose={() => setIsDialogVisible(false)}
                  courses={courses}
                  onAddClass={handleAddClassToTraining}
                />
              </section>
              {_dataProvider.classProgressionControl() && (
                <section className='session'>
                  <h3>{i18n.t('TrainingProgression')}</h3>
                  <div style={{ marginLeft: '30px' }}>
                    {trainingData.currentChapter && (
                      <p>
                        {i18n.t('CurrentChapter')}: {trainingData.currentChapter}
                      </p>
                    )}
                    {trainingData.reminder && (
                      <p>
                        {i18n.t('Reminder')}: {trainingData.reminder}
                      </p>
                    )}
                  </div>
                  <DefaultButton
                    className='add-group-button class-management-button'
                    onClick={() => setIsTrainingProgressDialogVisible(true)}>
                    {i18n.t('UpdateTrainingProgression')}
                  </DefaultButton>
                  <TrainingProgressDialog />
                </section>
              )}
              {_dataProvider.moreUserDataForOrg() && (
                <section className='session'>
                  <h3>报名信息</h3>
                  <div className='registration-link-container'>
                    报名链接：
                    <Link href={registrationLink} target='_blank' className='custom-link'>
                      {registrationLink}
                    </Link>
                  </div>
                  <div className='qr-code-container'>
                    <QRCodeComponent url={registrationLink} />
                  </div>
                </section>
              )}
              <Dialog
                hidden={!isDeleteDialogVisible}
                onDismiss={() => setIsDeleteDialogVisible(false)}
                modalProps={{
                  isBlocking: false,
                  styles: {
                    main: {
                      minWidth: 650,
                      minHeight: 250,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '20px'
                    }
                  }
                }}
                dialogContentProps={{
                  title: '请确认',
                  subText: `删除课程${courseToDelete?.ClassNameChs}`,
                  showCloseButton: true,
                  styles: {
                    title: {
                      textAlign: 'center',
                      marginTop: '40px'
                    },
                    subText: {
                      textAlign: 'center'
                    }
                  }
                }}>
                <DialogFooter>
                  <DefaultButton onClick={() => setIsDeleteDialogVisible(false)} text='取消' />
                  <DefaultButton
                    onClick={() => {
                      if (typeof courseToDelete?.ClassId === 'number') {
                        handleRemoveClassFromTraining(courseToDelete.ClassId);
                        setIsDeleteDialogVisible(false);
                      } else {
                        console.error('Error: ClassId is undefined');
                      }
                    }}
                    text='确认'
                  />
                </DialogFooter>
              </Dialog>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default ClassManagement;
