import { IChapterContentAndAnswers, IBookContentSession } from '../dataProvider/IBookContent';
import { _dataProvider } from '../dataProvider/DataProvider';
import { reLoadFrame, _smallScreen } from '../SharedCommon/utils';
import { getAccessToken, HostOfficialServer } from '../SharedCommon/utils';

import React from 'react';
import i18n from '../i18n';
import { isUserSignedIn, phoneEmulatorTemplate } from '../SharedCommon/utils';

import preval from 'preval.macro';

//import "./phone.css"; -- the old phone simulator

const dateTimeStamp = preval`module.exports = Date.now();`;

interface IPhoneViewerProps {
  bookContent?: IChapterContentAndAnswers;
  sessionId: number;
  chapterIdx: number;
  templateUri: string;
  dataId: string;
  setSessionDate: any;
  setBible: any;
  setProgress: any;
  contentHeight: string;
  changeCount?: number;
  preview?: boolean;
  setChapter: any;
  setShowContent?: any;
}

//content.position?.day

class PhoneViewer extends React.Component<IPhoneViewerProps> {
  private saveCount: number;
  private lastSuccess: boolean;
  private questionCount: number;
  private timeoutId?: any;

  constructor(props: any) {
    super(props);
    this.saveCount = 0;
    this.lastSuccess = true;

    this.questionCount = 0;
    this.countQuestions(props);

    // eslint-disable-next-line
    window.onmessage = (e:any):void => {
      //console.log("onmessage", e);
      //this is when the user clicks inside the phone emulator between dates
      //this is no longer needed in the viewing app??
      //we need such a mechanism only to save data on the homework
      //console.log(e.data);
      if (typeof e.data !== 'string') {
        return;
      }
      if (!e.data.startsWith('{')) {
        return;
      }
      const data = JSON.parse(e.data);
      switch (data.command) {
        case 'sessionChange':
          //todo: there shoiuld be a session change command
          //so that the App can save the state later to restart the web page
          //at the last location
          //this is actually a cool improvement over the phone app
          //  could the phone app launched to the last state to start with???
          //  but maybe sessionIdx should not be a state, just a variable
          this.props.setSessionDate(data.day - 1);
          break;
        case 'openLink':
          this.openLink(data);
          break;
        case 'setAnswer':
          this.setAnswer(data);
          break;
        case 'goToBible':
          this.goToBible(data);
          break;
        case 'goToNextChapter':
          this.props.setChapter(this.props.chapterIdx + 1);
          break;
        case 'shareAnswer':
          this.shareAnswer(data);
          break;
        default:
          console.log('Warning: ' + data.command + ' is not handled!');
      }
    };
  }

  public componentDidMount(): void {
    console.log('PhoneView componentDidMount');
    this.saveEvery5Seconds(0);
  }

  public componentWillUnmount(): void {
    console.log('ContentEditor componentWillUnmount: ');
    clearTimeout(this.timeoutId);
  }

  countQuestions(ppp: IPhoneViewerProps) {
    this.questionCount = 0;
    if (!ppp.bookContent) {
      return;
    }

    ppp.bookContent?.lesson.sessions.forEach((item: IBookContentSession) => {
      item.content.forEach((subItem) => {
        if (['question', 'sliderChoice', 'singleChoice', 'multipleChoice'].indexOf(subItem.type) !== -1) {
          this.questionCount++;
        } else if (subItem.type === 'inlineQuestion') {
          // remove the possible duplicate answers
          const answers: { [key: string]: number } = {};
          ((subItem.value as string).match(/{[A-Za-z_0-9]*}/g) || []).forEach((it) => (answers[it as string] = 1));
          console.log('inline question count: ', answers, Object.keys(answers).length);
          this.questionCount += Object.keys(answers).length;
        }
      });
    });
  }

  close = () => {
    if (this.props.setShowContent && typeof this.props.setShowContent === 'function') {
      this.props.setShowContent(false);
    }
  };

  render(): React.ReactNode {
    console.log('PhoneViewer.render', dateTimeStamp);
    return (
      <div className='smartphone' style={{ height: this.props.contentHeight }}>
        <div id='idHeader' className='ColumnHead'>
          {this.props.preview ? i18n.t('ContentPreview') : i18n.t('Content')}
          {_smallScreen && (
            <button className='closeButton' style={{ right: '0px' }} onClick={this.close}>
              {i18n.t('Close')}
            </button>
          )}
        </div>
        <iframe
          title='Phone'
          id='idpreview'
          name='preview'
          src={this.props.templateUri + '?build=' + dateTimeStamp}
          style={{
            width: '100%',
            border: 'none',
            height: 'calc(100% - 32px)' //this.props.contentHeight,
          }}
          onLoad={this.initContent}></iframe>
      </div>
    );
  }

  /*
  UNSAFE_componentWillUpdate(): void {
    console.log('UNSAFE_componentWillUpdate', this.props.templateUri);
    reLoadFrame('idpreview', this.props.templateUri);
    //unclear if this will do anything right, since it's happening before the render() method.
    //and the change might be done on the old iframe??, but the next method
    //is never invoked, that gives hope that the iframe wasn't changed by render()
    //at all ==
  }
  */
  public shouldComponentUpdate(nextProp: IPhoneViewerProps) {
    console.log(
      'phoneview shouldComponentUpdate',
      this.props.dataId,
      nextProp.dataId,
      this.props.changeCount,
      nextProp.changeCount
    );
    //when navigating between dates, the day in App is notified/changed
    // and when rendering whole again, it will pass a new date prop
    // but i am already in the new day... || this.props.sessionId !== nextProp.sessionId)
    if (this.props.dataId !== nextProp.dataId) {
      this.countQuestions(nextProp);
      reLoadFrame('idpreview', this.props.templateUri + '?build=' + dateTimeStamp);
    } else if (this.props.changeCount !== nextProp.changeCount) {
      console.log('inject content in shouldComponentUpdate');
      this.injectContent(nextProp);
      //reLoadFrame("idpreview", this.props.templateUri);
    }
    return true;
  }

  initContent = (): void => {
    if (this.props.templateUri !== phoneEmulatorTemplate) {
      //the frame will load from the url directly, without data injection
      return;
    }
    // eslint-disable-next-line
    const frames:any = window.frames;
    const previewFrame = frames['preview'];

    //swap out a couple of functions
    previewFrame.oldSendCmd = previewFrame.sendCmd;
    previewFrame.oldAddTextNode = previewFrame.addTextNode;
    previewFrame.oldCreateTextNode = previewFrame.createTextNode;
    previewFrame.sendCmd = previewFrame.new_sendCmd;
    previewFrame.addTextNode = previewFrame.new_addTextNode;
    previewFrame.createTextNode = previewFrame.new_createTextNode;
    previewFrame.bContinue = previewFrame.new_bContinue;

    console.log('inject content in initContent');
    this.injectContent(this.props);
  };

  injectContent = (props: IPhoneViewerProps): void => {
    //alert works --- so it's here, we inject content, and call initcontent
    //will this be invoked during the re-rendering flow? -- probably not!!

    console.log('injectContent -- onload: ', props);

    //loadFrame('preview', this.props.bookContent, this.props.sessionId + 1, this.props.scroll2Bottom);

    props.bookContent!.position = {
      day: props.sessionId + 1,
      scrollTop: 0
    };

    // eslint-disable-next-line
    const frames:any = window.frames;
    const previewFrame = frames['preview'];
    previewFrame.content = props.bookContent;

    //strings to be localized, but abstracted out of template.html
    previewFrame.content.i18n = { NotAnswered: '未回答', Share: '分享答案', TotalShare: '總共{count}個分享' };

    // to support sharing answers
    previewFrame.content.canShare = new URLSearchParams(window.location.search).get('testshare') === '1';
    //previewFrame.content.canShare = true;

    previewFrame.content.contentUrl =
      HostOfficialServer + '/lesson/' + encodeURIComponent(props.bookContent?.lesson.lessonId as string) + '/content/';
    previewFrame.content.pageViewUrl =
      HostOfficialServer +
      '/lessonPageView/' +
      encodeURIComponent(props.bookContent?.lesson.lessonId as string) +
      '/' +
      this.props.chapterIdx;
    previewFrame.content.title = props.bookContent?.lesson.title;
    previewFrame.content.accessToken = getAccessToken();

    //so that content can decide to show differently in authoring tool
    previewFrame.content.preview = this.props.preview;

    //now, init the content into the phone emulator
    if (typeof previewFrame.initContent !== 'function') {
      console.log('why initContent is not a function, template not loaded yet');
      return;
    }
    previewFrame.initContent();

    return;
  };

  getAccessUrl(src: string) {
    if (src.toLowerCase().startsWith('http://') || src.toLowerCase().startsWith('https://')) {
      return src;
    }
    return `${this.props.bookContent?.contentUrl}${src}?token=${this.props.bookContent?.accessToken}`;
  }

  openLink(data: any) {
    const url = this.getAccessUrl(data.url);
    window.open(url);
  }

  setAnswer(data: any) {
    //save the data
    if (!this.props.bookContent?.answers) {
      // during the authoring time...
      return;
      //this.props.bookContent.answers = {};
      //this.props.bookContent.answers[data.id]=data.value;
    } else {
      this.props.bookContent!.answers[data.id] = data.value;
    }
    if (!this.props.preview) {
      this.saveCount++;
    }
  }

  async saveEvery5Seconds(saveCount: number) {
    console.log('saveEvery5Seconds: ', saveCount);
    if (this.props.preview) {
      return;
    }

    let newSaveCount = this.saveCount;
    if (this.saveCount > saveCount) {
      if (!isUserSignedIn()) {
        alert('登录失效，需要重新登录');
        return;
      }
      //todo: put the following into a every 10 second save method
      const body = this.props.bookContent?.answers;
      const result = await _dataProvider.saveUserData(this.props.dataId, body);
      if (!result) {
        //TODO: we don't want to annoy ever 5 second if there is no connection?
        if (this.lastSuccess) {
          alert('data save failed, check data connection');
        }
        this.lastSuccess = false;
        newSaveCount = saveCount;
      } else {
        this.lastSuccess = true;

        const count = Object.keys(this.props.bookContent?.answers).length;
        if (this.questionCount > 0) {
          const progress = ((count * 100) / this.questionCount).toFixed(0) + '%';
          this.props.setProgress(progress);
        }
      }
    }
    // start the next one
    this.timeoutId = setTimeout(
      (s) => {
        this.saveEvery5Seconds(s);
      },
      3000,
      newSaveCount
    );
  }

  goToBible(data: any) {
    /*
    const search = `${data.book} ${data.verse}`;
    const url = `https://www.biblegateway.com/passage/?search=${search}&version=cuvs`;
    
    //search traditional, remove the 's' from cuvs
    //https://www.biblegateway.com/passage/?search='創世記 1:1'&version=cuv
    
    //創世記 1-2  will show two chapters, so this is cool
    */

    //another choice is: --- but it list out based on book/chapter --- no specific verse sectioned out.

    //TODO: need to fix this in coordination with template.html ===
    this.props.setBible(data);

    //const search = `${data.book}/${parseInt(data.verse)}`;
    //const url = `http://www.chinesebibleonline.com/book/${search}`;
    //window.open(url);
  }

  async shareAnswer(data: any) {
    const answer = this.props.bookContent!.answers[data.questionId];
    if (!answer) {
      alert('你還沒有輸入任何答案');
      return;
    }

    const classId = parseInt(this.props.bookContent?.lesson.lessonId || '0');
    if (!classId) {
      alert('分享不成功，請稍後再試。');
      return;
    }

    const studyGroupId = await _dataProvider.postCreateDiscussion(classId, this.props.chapterIdx, data.questionId);

    if (studyGroupId === 0) {
      alert('分享不成功，請稍後再試。');
      return;
    }

    const result = _dataProvider.postGroupMessage(studyGroupId, answer);
    if (!result) {
      alert('網絡問題，請再試一次。');
    } else {
      alert('分享成功!\n' + answer);
    }
  }
}

export default PhoneViewer;

/*
          lessonJson.progress[item.Week] =
            ((item.Count * 100) / classInfo.sessions[item.Week].questionCount).toFixed(0) + '%';

    answerCount = this.this.props.bookContent.answers?.

    const progress = ((answerCount*100) / questionCount).toFixed(0) + '%';
    
    let count = Object.keys(this.this.props.bookContent.answers).length
  

function getSessionQuestionCount(file) {

  let questionCount = 0;
  
  this.props.bookContent.lesson.sessions.forEach((item) => {
    item.content.forEach((subItem) => {
      if (subItem.type === 'question') {
        questionCount++;
      }
    });
  });

}
*/
