import React from 'react';
import { Stack } from '@fluentui/react';
import Editor from '@monaco-editor/react';
import { IBookTable } from '../dataProvider/IBookTable';
import { IBookContent } from '../dataProvider/IBookContent';

interface IJsonEditorProps {
  jsonContent: any;
  receiveContent: (jsonContent: IBookTable | IBookContent, save: boolean) => void;
}

class JsonEditor extends React.Component<IJsonEditorProps> {
  // eslint-disable-next-line
  private editor: any;
  private jsonObj: any;

  constructor(props: IJsonEditorProps) {
    super(props);
    this.editor = null;
    this.jsonObj = this.props.jsonContent;
  }

  public shouldComponentUpdate(nextProps: IJsonEditorProps) {
    console.log(
      'JsonEditor shouldComponentUpdate: ',
      this.jsonObj,
      nextProps.jsonContent,
      this.jsonObj === nextProps.jsonContent
    );
    if (nextProps.jsonContent === this.jsonObj) {
      //this update sequence can be triggered by the eidtor itself, when user makes changes.
      //and if we change here, it will trigger change back to main and back here again
      //for infinite loop.  The only accepted change is when the user click on a different book
      //different lesson === otherwise, just return, assume, the change actually came
      //from JsonEditor itself.
      return true;
    }
    this.jsonObj = nextProps.jsonContent;
    console.log('set editor value');
    this.editor.setValue(JSON.stringify(this.jsonObj));
    setTimeout(() => {
      console.log('timeout to format monaco JsonEditor');
      this.editor?.getAction('editor.action.formatDocument').run();
    }, 300);
    return true;
  }

  onMount = (editor: any) => {
    if (editor) {
      this.editor = editor;
      setTimeout(function () {
        editor.getAction('editor.action.formatDocument').run();
      }, 300);
    }
  };

  onChange = () => {
    this.updateContent(false);
  };

  render = (): React.ReactNode => {
    return (
      <Stack style={{ float: 'right', border: '1px solid #eee', padding: 0 }}>
        <div style={{ padding: '2pt' }}>
          <button style={{ border: '1px solid', margin: '0 0 0 10px' }} onClick={this.format}>
            Format JSON
          </button>
          <button style={{ border: '1px solid', margin: '0 0 0 10px' }} onClick={this.validate}>
            Validate JSON
          </button>
          <button style={{ border: '1px solid', margin: '0 0 0 10px' }} onClick={this.save}>
            Save
          </button>
        </div>
        <div id='container' className='jsonEditor'>
          <Editor
            height='100%'
            width='100%'
            defaultLanguage='json'
            defaultValue={JSON.stringify(this.jsonObj)}
            onMount={this.onMount}
            onChange={this.onChange}
            options={{
              tabSize: 1,
              wordWrap: 'on',
              lineDecorationsWidth: 0,
              lineNumbersMinChars: 3,
              glyphMargin: false,
              minimap: {
                enabled: false
              },
              automaticLayout: true
            }}
          />
        </div>
      </Stack>
    );
  };

  format = (): void => {
    this.editor?.getAction('editor.action.formatDocument').run();
  };

  save = (): void => {
    this.updateContent(true);
  };

  validate = (): void => {
    const contentJson = this.editor.getValue();
    try {
      const content = JSON.parse(contentJson);
      if (content) {
        alert('success');
        return;
      }
    } catch (e) {
      console.log('JSon parser failure: ', e);

      if (e instanceof SyntaxError) {
        //if (e.lineNumber) // JSlint doesn't know lineNumber for SyntaxError
        //  alert(e);
        //else {
        const ss = '' + e;
        const idx = ss.indexOf('in JSON at position ');
        if (idx === -1) {
          // can't help more
          alert(e);
        } else {
          // construct the line# and column# to report error out
          const pos = parseInt(ss.substr(idx + 'in JSON at position '.length));
          const cj = contentJson.substr(0, pos);
          const lines = cj.split(/\r\n|\r|\n/);
          const msg = ss.substr(0, idx) + ' in JSON at ';
          alert(msg + 'line: ' + lines.length + '; Column: ' + lines[lines.length - 1].length);
        }
        //}
      } else {
        alert(e);
      }

      return;
      //  SyntaxError: Unexpected token { in JSON at position 88
      // str.split(/\r\n|\r|\n/).length;
      //var lines = $("#ptest").val().split("\n");
      //alert(lines.length);
    }
    alert('Bad error');
  };

  updateContent(save: boolean): void {
    const contentJson = this.editor.getValue();
    if (contentJson === JSON.stringify(this.jsonObj)) {
      return;
    }
    try {
      const contentObj = JSON.parse(contentJson);
      if (!contentObj) {
        alert('nothing to save, json might be wrongly formatted');
        return;
      }

      // don't change the lessonID --- as that woould end up editing a different book
      contentObj.lessonId = this.jsonObj.lessonId;
      contentObj.title = this.jsonObj.title;
      if (this.jsonObj.chapterId) {
        contentObj.chapterId = this.jsonObj.chapterId;
      }
      if (this.jsonObj.bookTitle) {
        contentObj.bookTitle = this.jsonObj.bookTitle;
      }

      this.jsonObj = contentObj;
      this.props.receiveContent(this.jsonObj, save);

      //TODO:  JSON lint
      //loadFrame('preview', content);
    } catch (e) {
      console.log('JSon parser failure: ', e);
      if (save) {
        alert('Json parser failure');
      }
      //  SyntaxError: Unexpected token { in JSON at position 88
      // str.split(/\r\n|\r|\n/).length;
      //var lines = $("#ptest").val().split("\n");
      //alert(lines.length);
    }
  }
}

export default JsonEditor;
