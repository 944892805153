import React from 'react';
import defaultAvatar from '../img/icon-DefaultProfilePicture.png';
import allUserAvatar from '../img/icon-All.png';
import { HostOfficialServer } from '../SharedCommon/utils';
import { IUser } from '../dataProvider/DataProvider';

import plusAvatar from '../img/icon-Plus.png';
import i18n from '../i18n';

const prebuiltAvatars = [plusAvatar, allUserAvatar];

export const userAvatarName = (user: IUser, showName = 1, showSetting = false, size = '60px', fontSize = 14) => {
  const renderAvatarImage2 = (user: IUser, avatarUrl: string, size: string) => {
    const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      e.currentTarget.onerror = null; // Prevent looping
      e.currentTarget.src = defaultAvatar;
      e.currentTarget.className = 'avatar-icon';
      e.currentTarget.alt = 'Default Avatar';
      e.currentTarget.style.width = size;
      e.currentTarget.style.height = size;
    };

    return (
      <div key={user.userId} className='avatar-container' style={{ width: size, height: size }}>
        <img src={avatarUrl} alt='' className='avatar-img' onError={handleError} />
      </div>
    );
  };

  let avatarUrl = `${HostOfficialServer}/user/avatar/${user.id || user.userId}`;
  if (user.userId < 0) {
    const idx = 0 - user.userId - 1;
    avatarUrl = prebuiltAvatars[idx];
  }
  const fullname = user.displayName ?? user.name;
  const name = showName == 1 ? fullname.slice(0, 4) : fullname + ' ' + (showName === 3 ? user.tag ?? '' : '');

  return (
    <>
      <div className='avatar-item'>
        {renderAvatarImage2(user, avatarUrl, size)}
        {!!showName && (
          <div className='avatar-name' style={{ fontSize: `${fontSize}px` }}>
            {name}
          </div>
        )}
      </div>
      {showSetting && (
        <>
          <div className='avatar-name'>
            {i18n.t('UserID')}: {user.loginId || user.uniqueId}
          </div>
        </>
      )}
    </>
  );
};
