import React from 'react';
import { useNavigate } from 'react-router-dom';
import SignInHeader from './SignInHeader';

const SignInHeaderWrapper = (props) => {
  const navigate = useNavigate();

  return <SignInHeader {...props} navigate={navigate} />;
};

export default SignInHeaderWrapper;
