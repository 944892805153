import React, { useEffect, useState } from 'react';
import { _dataProvider, IUser } from '../dataProvider/DataProvider';
import i18n from '../i18n';
import { userAvatarName } from '../SharedCommon/UserAvatarName';
import { SelectedUser } from '../SharedCommon/SelectedUser';
import { PickUser } from './PickUser';
import { getJwtObj } from '../SharedCommon/utils';

export interface ISingleRoleUserMgmtProps {
  contextId: string;
  role: string;
}

const titleStrings = ['authoring.Authors', 'org.Admins'];
const addButtonStrings = ['authoring.AddAuthor', 'org.AddAdmin'];
const roles = ['Author', 'Admin'];

export const SingleRoleUserMgmt: React.FC<ISingleRoleUserMgmtProps> = ({ contextId, role }) => {
  const [showRemoveAdmin, setShowRemoveAdmin] = useState<IUser | undefined>(undefined);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [usersInRole, setUsersInRole] = useState<IUser[]>([]);

  useEffect(() => {
    _dataProvider.getUsersInRole(contextId, role).then((users) => {
      setUsersInRole(users);
    });
  }, []);

  const removeAuthor = (order: number, user: IUser | undefined, action = false) => {
    console.log('removeAuthor: ', order, user, action);
    if (order === 0) {
      setShowRemoveAdmin(undefined);
    }
    if (!action) {
      if (order === 1) {
        return i18n.t('KickMember');
      }
    } else {
      if (order === 1) {
        _dataProvider.removeSingleRoleUser(role, contextId, user!.userId);
        setUsersInRole(usersInRole.filter((uu) => uu.userId !== user?.userId));
        setShowRemoveAdmin(undefined);
      }
    }
  };

  const addAuthor = (user: IUser | undefined) => {
    if (!user) {
      setShowAddAdmin(false);
      return;
    }
    _dataProvider.addSingleRoleUser(role, contextId, user.userId);
    const newu = usersInRole;
    newu.push(user);
    setUsersInRole(newu);
  };

  const idx = roles.indexOf(role);
  const plusButtonU = { userId: -1, displayName: i18n.t(addButtonStrings[idx]), loginId: '0', isGroupLeader: false };

  return (
    <div className='AuthorClassDivs'>
      <h3 className='AuthorClassHeadings'>{i18n.t(titleStrings[idx])}</h3>
      <div className='member-container' style={{ gap: '0px', padding: '2px' }}>
        {usersInRole.map((uu) => {
          return (
            <div key={uu.userId} className='avatar-wrapper' onClick={() => setShowRemoveAdmin(uu)}>
              {userAvatarName(uu, 1, false, '40px', 10)}
            </div>
          );
        })}
        <div className='avatar-wrapper' onClick={() => setShowAddAdmin(true)}>
          {userAvatarName(plusButtonU, 2, false, '40px', 10)}
        </div>
      </div>
      {showRemoveAdmin !== undefined && getJwtObj().userId !== showRemoveAdmin.userId && (
        <SelectedUser user={showRemoveAdmin} action={removeAuthor} />
      )}
      {showAddAdmin && <PickUser action={addAuthor} title={plusButtonU.displayName} />}
    </div>
  );
};
