import detector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationChs from './locales/chs/translation.json';
import translationCht from './locales/cht/translation.json';
import translationEng from './locales/eng/translation.json';

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    //'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path',
    detection: {
      order: ['querystring', 'subdomain', 'cookie'],
      lookupQuerystring: 'lang',
      lookupCookie: 'i18next',
      // cache user language
      caches: ['cookie'],
      // cache expiry and domain for set cookie
      cookieMinutes: 10080
      // cookieDomain: '.idigest.app'
    },
    resources: {
      eng: {
        translation: translationEng
      },
      cht: {
        translation: translationCht
      },
      chs: {
        translation: translationChs
      }
    },
    fallbackLng: 'chs',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;
