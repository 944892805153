import React from 'react';
import { _dataProvider } from '../dataProvider/DataProvider';
import { isUserSignedIn } from '../SharedCommon/utils';
import i18n from '../i18n';

import { exec, init } from 'pell';
import 'pell/dist/pell.css';

//import { Text } from "@fluentui/react";

//need to adjust the textarea to fill the rest of the vertical === see flex-grow
//https://stackoverflow.com/questions/41143210/css-element-height-minus-height-of-a-element-with-changing-height
// 1px = (100vw / [document.documentElement.clientWidth] px)

// eslint-disable-next-line
// declare const monaco: any;

interface IEditorProps {
  dataId: string;
  contentHeight: string;
  headClass?: string;
}

interface IEditorState {
  notesContent: string;
  contentHeight: string;
}

class Editor extends React.Component<IEditorProps, IEditorState> {
  private saveCount: number;
  private lastSuccess: boolean;
  private editor: any;
  private headClass: string;
  private topMargin: number;
  private topClass: string;
  private pellClass = 'pell';
  private timeoutId?: any;

  // eslint-disable-next-line
	constructor(props:IEditorProps) {
    super(props);

    this.saveCount = 0;
    this.lastSuccess = true;
    this.editor = null;
    this.headClass = this.props.headClass ?? 'ColumnHead';
    this.topMargin = this.props.headClass ? 10 : 0;
    this.topClass = this.props.headClass ? 'fellowshipNoteEditor' : 'jsonEditor';
    if (this.props.headClass) {
      this.pellClass = 'fellowshipNotePell pell';
    }

    this.state = {
      notesContent: '<Enter your notes here>',
      contentHeight: props.contentHeight
    };
  }

  render(): React.ReactNode {
    console.log('Editor render', this.props);
    /*
        <textarea id='idnotes' style={{width:'100%', height:this.props.contentHeight,border:'none',padding:'3px'}}
          value={this.state.notesContent}
          onChange={(e)=>{this.setState({notesContent:e.target.value}); this.saveCount++}}>
        </textarea>
        <h3>HTML Output:</h3>
        <div id="html-output">{this.state.notesContent}</div>
        Take notes
    */
    return (
      <div className={this.topClass} style={{ float: 'right' }}>
        <div id='idHeader' className={this.headClass}>
          {!this.props.headClass && i18n.t('YourNotes')}
          {!!this.props.headClass && (
            <button className='fellowshipAction' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              {i18n.t('YourNotes')}
            </button>
          )}
        </div>
        <div id='idnotes' className={this.pellClass} style={{ width: '100%', height: this.state.contentHeight }} />
      </div>
    );
  }

  public componentWillUnmount(): void {
    console.log('ContentEditor componentWillUnmount: ');
    clearTimeout(this.timeoutId);
  }

  public componentDidMount(): void {
    this.saveEvery5Seconds(0);
    console.log('editor componentDidMount');
    if (this.editor) {
      console.log('editor componentDidMount -- enter again -- skip');
      return;
    }
    this.editor = init({
      element: document.getElementById('idnotes')!,
      onChange: (notesContent) => {
        this.setState({ notesContent });
        this.saveCount++;
      },
      actions: [
        'bold',
        'underline',
        'italic',
        'olist',
        'ulist',
        'heading1',
        'heading2',
        'paragraph',
        {
          name: 'indent',
          icon: '>',
          title: 'indent',
          result: () => {
            exec('indent');
          }
        },
        {
          name: 'outdent',
          icon: '<',
          title: 'outdent',
          result: () => {
            exec('outdent');
          }
        },
        /*
        {
          name: "Question Box",
          icon: "Q",
          title: "Question Box",
          //          result: () => {exec('insertHTML', '<div style="width:100%"><p><textarea style="width:100%" rows="4"/></p></div>')}
          result: () => {
            exec(
              "insertHTML",
              '<div style="width:100%"><p>great block</p></div>'
            );
          },
        },
        */
        {
          name: 'Center',
          icon: 'C',
          title: 'Center',
          result: () => {
            exec('justifyCenter');
          }
        },
        /* /// make c for center -- same as development
        {
          name: "Clear formating",
          icon: "C",
          title: "Clear formating",
          result: () => {
            exec("removeFormat");
          },
        },
        */
        {
          name: 'Left',
          icon: 'L',
          title: 'Left',
          result: () => {
            exec('justifyLeft');
          }
        },
        {
          name: 'Red font',
          icon: '<font color="red">R</font>',
          title: 'Red font',
          result: () => {
            exec('foreColor', '#FF0000');
          }
        },
        {
          name: '紫色',
          icon: '<font color="#c16766">紫</font>',
          title: '紫色',
          result: () => {
            exec('foreColor', '#c16766'); //""#605c7f");
          }
        },
        {
          name: 'hiliteColor',
          icon: '<span style="background-color:yellow">Y</span>',
          title: 'hiliteColor',
          result: () => {
            exec('hiliteColor', '#FFFF00');
          }
        }
      ]
    });
    _dataProvider.getUserData(this.props.dataId).then((notesContent) => {
      let pellHeight =
        parseInt(this.props.contentHeight) - document!.getElementById('idHeader')!.clientHeight - this.topMargin;
      const contentHeight = `${pellHeight}px`;
      pellHeight -= this.editor.children[0]!.clientHeight;
      this.editor.content.style.height = `${pellHeight}px`;

      this.editor.content.innerHTML = notesContent?.replace(/\n/g, '<br>');
      this.setState({ notesContent, contentHeight });
    });
  }

  public shouldComponentUpdate(nextProps: IEditorProps) {
    console.log('editor shouldComponentUpdate');
    if (this.props.dataId !== nextProps.dataId) {
      //kick off async load of new uswer data.
      _dataProvider.getUserData(nextProps.dataId).then((notesContent) => {
        this.setState({ notesContent });
        this.editor.content.innerHTML = notesContent.replace(/\n/g, '<br>');
      });
    }
    if (this.props.contentHeight !== nextProps.contentHeight) {
      let pellHeight = parseInt(nextProps.contentHeight) - document!.getElementById('idHeader')!.clientHeight;
      const contentHeight = `${pellHeight}px`;
      pellHeight -= this.editor.children[0]!.clientHeight;
      this.editor.content.style.height = `${pellHeight}px`;
      this.setState({ contentHeight });
    }
    return true;
  }

  async saveEvery5Seconds(saveCount: number) {
    let newSaveCount = this.saveCount;
    if (this.saveCount > saveCount) {
      if (!isUserSignedIn()) {
        alert('登录失效，需要重新登录');
        return;
      }
      const body = {
        content: this.state.notesContent
      };
      const result = await _dataProvider.saveUserData(this.props.dataId, body);
      if (!result) {
        //TODO: we don't want to annoy ever 5 second if there is no connection?
        if (this.lastSuccess) {
          alert('data save failed, check data connection');
        }
        this.lastSuccess = false;
        newSaveCount = saveCount;
      } else {
        this.lastSuccess = true;
      }
    }
    // start the next one
    this.timeoutId = setTimeout(
      (s) => {
        this.saveEvery5Seconds(s);
      },
      3000,
      newSaveCount
    );
  }
}

export default Editor;
