import './App.css';
import Header from './components/Header';
//import StudyClasses from "./components/StudyClasses";
import DiscoverStudy from './components/DiscoverStudy';
import WritingBooks from './components/WritingBooks';
import { IAppSessionSettings } from './dataProvider/IAppSessionSettings';
import { _dataProvider } from './dataProvider/DataProvider';
import MgmtOrgGroupList from './components/MgmtOrgGroupList';
import GroupMgmt from './components/GroupMgmt';
import AppDownload from './components/AppDownload';
import AboutUs from './components/AboutUs';
import Share2 from './components/Share2';
import PubLesson from './components/PubLesson';
//import SignInHeader from "./SharedCommon/SignInHeader";
import SignInHeaderWrapper from './SharedCommon/SignInHeaderWrapper';
import ClassRegistration from './components/ClassRegistration';
import ClassManagement from './components/ClassManagement';
import DeleteUser from './components/DeleteUser';
import MySettings from './components/MySettings';

import { PromptPickGroup } from './components/Dialogues';
import { getOrgIdFromUrl } from './SharedCommon/OrgList';

import React from 'react';
import i18n from './i18n';

import { getJwtObj, HostApiLocal, HostOfficialServer, SERVER_URL } from './SharedCommon/utils';

import { HashRouter, Route, Routes } from 'react-router-dom';
import { SearchContext } from './ISearchContextType';
import { ShowSearchBarContext } from './ShowSearchBarContext';

const forPwa = window.location.hostname.startsWith('pwa') || window.location.pathname.startsWith('/pwa');

/*
build time:  add this to the phone's template.html url to prevent caching...
https://stackoverflow.com/questions/53028778/how-to-show-build-datetime-on-my-react-web-app-using-create-react-app

-- bring json editor, but not in production build ---
if (process.env.NODE_ENV !== "production")

if (process.env.REACT_APP_JSONEDIT === 'ttt')

build time variable:
https://create-react-app.dev/docs/adding-custom-environment-variables#adding-development-environment-variables-in-env
https://functional.works-hub.com/learn/create-react-app-run-time-vs-build-time-environment-variables-67f01
https://blog.kintohub.com/proper-environment-variables-in-reactjs-a2619a50c19e

*/

const HostOverIP = 'http://13.91.97.63';
const HostHttpServer = 'http://13.91.97.63/idigest';

interface IAppState {
  headButtonIdx: number;
  signInState: boolean;
  HeadClickCount: number;
  useEditor: number;
  pickGroup4Online: boolean;
  file: any;
  msg: string;
  deferredPrompt: any;
  installedEvent: any;
  searchQuery: any;
  showSearchBar: boolean;
  placeholder: string; // search bar placeholder for GroupMgmt.tsx
}

//const imagePrefix = "!image!";

class App extends React.Component<{}, IAppState> {
  private serverUrl: string;
  private loginUrl: string;
  private _isHttps: boolean;
  private settings: IAppSessionSettings;
  private _meetingWindow: any;
  private _meetingGroupId: number;
  private _orgId: number;

  constructor(props: {}) {
    super(props);

    this._orgId = getOrgIdFromUrl();
    this._meetingWindow = null;
    this._meetingGroupId = -1;

    this._isHttps = window.location.href.startsWith('https');
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('localapi') === '1') {
      this.serverUrl = HostApiLocal;
    } else {
      if (window.location.href.startsWith(HostOverIP)) {
        this.serverUrl = HostHttpServer;
      } else {
        //this.serverUrl = this.serverUrl + "/api";
        this.serverUrl = HostOfficialServer;
      }
    }
    this.loginUrl = this.serverUrl + '/user/login';

    let lastSettings = localStorage.getItem('iDigestSettings');
    console.log('lastSettings from storage = ', lastSettings, typeof lastSettings);
    if (!lastSettings || lastSettings.charAt(0) !== '{') {
      lastSettings = '{"headButtonIdx":1, "sessionIdx":0, "name":"", "chapterIdx":-1}';
    }
    this.settings = JSON.parse(lastSettings);

    const jwtObj = getJwtObj();
    console.log('signInState at start: ', jwtObj);

    //headButtonIdx will always start with "Discovor/studying classes", for which there
    //are too many books and chapters to choose from, and it's a help to the user.
    //other scenarios (org admin/author) has less users, and less data, less frequency
    this.state = {
      headButtonIdx: 1, //this.settings.headButtonIdx ? this.settings.headButtonIdx!: 0,
      HeadClickCount: 0,
      signInState: jwtObj.userId !== -1, //signInState?.accessToken ? true : false,
      useEditor: parseInt(urlParams.get('editor') || ''),
      pickGroup4Online: false,
      file: null,
      msg: '',
      deferredPrompt: null,
      installedEvent: null,
      searchQuery: '',
      showSearchBar: true,
      placeholder: ''
    };

    _dataProvider.init(this.serverUrl, this._orgId, SERVER_URL);

    if (forPwa) {
      if (urlParams.has('tk')) {
        localStorage.setItem('access_token', urlParams.get('tk') || '');

        //get rid of tk so that url with tk won't be in history
        const urlObj = new URL(window.location.href);
        urlParams.delete('tk');
        urlObj.search = urlParams.toString();
        const newUrl = urlObj.toString();
        window.location.replace(newUrl);
        return;
      }
      if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (event) => {
          console.log('message listener event:', event.data);
          this.setState({ file: event.data.file, msg: event.data.msg });
          /*
          if (event.data.action !== imagePrefix) return;
          const imageBlob = event.data.file;
          const img = document.getElementById("image");
          console.log("img?: ", img);
          // @ts-ignore
          img.src = URL.createObjectURL(imageBlob);
          ///_dataProvider.uploadFile2Chat(imageBlob);
          */
        });
      }
      window.addEventListener('beforeinstallprompt', async (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        const deferredPrompt = e;
        this.setState({ deferredPrompt });
        // Update UI notify the user they can install the PWA
        //showInstallPromotion();
        // Optionally, send analytics event that PWA install promo was shown.
        console.log("'beforeinstallprompt' event was fired.");
      });

      window.addEventListener('appinstalled', (e) => {
        // Hide the app-provided install promotion
        //hideInstallPromotion();
        // Clear the deferredPrompt so it can be garbage collected
        const deferredPrompt = null;
        const installedEvent = e;
        // Optionally, send analytics event to indicate successful install
        this.setState({ deferredPrompt, installedEvent });

        // shoould we just close it here?
        console.log('PWA was installed');
      });
    }

    console.log('the state.signInState, useEditor', this.state.signInState, this.state.useEditor);
  }

  updateSearchQuery = (newQuery: string) => {
    this.setState({ searchQuery: newQuery });
  };

  setSearchBarVisibility = (b: boolean) => {
    this.setState({ showSearchBar: b, searchQuery: '' });
  };

  setPlaceholder = (s: string) => {
    this.setState({ placeholder: s, searchQuery: '' });
  };

  public componentDidMount() {
    console.log('Mounted');
    if (forPwa) {
      document.body.style.minWidth = '0';
    }
  }

  private _signInOut = (signInState: boolean): void => {
    console.log('Sign-in state changed in App:', signInState);

    //wipe out settings
    if (!signInState) {
      localStorage.setItem('iDigestSettings', '');
      _dataProvider.reset();
    }

    this.setState({ signInState });
  };

  public render(): React.ReactNode {
    console.log('Rendering App, signInState:', this.state.signInState);
    if (window.location.href.indexOf('lesson') !== -1) {
      return (
        <HashRouter>
          <Routes>
            <Route path='/lesson/:id/:file' element={<PubLesson />}></Route>
          </Routes>
        </HashRouter>
      );
    }

    if (forPwa) {
      return this._installPwa();
    }
    return (
      <SearchContext.Provider
        value={{
          searchQuery: this.state.searchQuery,
          updateSearchQuery: this.updateSearchQuery
        }}>
        <ShowSearchBarContext.Provider
          value={{
            showSearchBar: this.state.showSearchBar,
            setVisible: this.setSearchBarVisibility,
            placeholder: this.state.placeholder,
            setPlaceholder: this.setPlaceholder
          }}>
          <HashRouter>
            <div id='idHeaderMenuBar' className='headerDiv'>
              <Header
                buttonIdx={this.state.headButtonIdx}
                signInOut={this._signInOut}
                setClickCount={this._setClickCount}
                loginUrl={this.loginUrl}
                signInState={this.state.signInState}
                preview={window.location.href.indexOf('preview') !== -1}
                getOnline={this._getOnline}
              />
            </div>
            <Routes>
              <Route
                path='/'
                element={
                  <DiscoverStudy
                    signInState={this.state.signInState}
                    HeadClickCount={this.state.HeadClickCount}
                    saveSettings={this._saveSettings}
                    settings={this.settings}
                  />
                }></Route>
              <Route path='/share' element={<Share2 file={this.state.file} msg={this.state.msg} />}></Route>
              <Route path='/AboutUs' element={<AboutUs />}></Route>
              <Route
                path='/group'
                element={
                  <GroupMgmt
                    signInState={this.state.signInState}
                    HeadClickCount={this.state.HeadClickCount}
                    pickgroup={this._pickGroup}
                  />
                }></Route>
              <Route
                path='/org'
                element={
                  <MgmtOrgGroupList
                    HeadClickCount={this.state.HeadClickCount}
                    signInState={this.state.signInState}
                    saveSettings={this._saveSettings}
                    settings={this.settings}
                    headerHeight={52}
                  />
                }></Route>
              <Route
                path='/develop'
                element={
                  <WritingBooks
                    HeadClickCount={this.state.HeadClickCount}
                    signInState={this.state.signInState}
                    saveSettings={this._saveSettings}
                    settings={this.settings}
                    useEditor={this.state.useEditor}
                    preview={window.location.href.indexOf('preview') !== -1}
                  />
                }></Route>
              <Route
                path='/appDownload'
                element={
                  <AppDownload
                    signInState={this.state.signInState}
                    HeadClickCount={this.state.HeadClickCount}
                    saveSettings={this._saveSettings}
                    settings={this.settings}
                  />
                }></Route>
              <Route
                path='/classregister/:trainingId/:joinToken'
                element={
                  <ClassRegistration
                    signInState={this.state.signInState}
                    contentHeight='100vh'
                    onSignInOut={this._signInOut}
                  />
                }
              />
              <Route path='/my-settings' element={<MySettings />} />
            </Routes>
            <Routes>
              <Route path='/classmanagement' element={<ClassManagement />} />
            </Routes>
            <Routes>
              <Route path='/userDeletion' element={<DeleteUser />} />
            </Routes>

            {this.state.pickGroup4Online && <PromptPickGroup pickGroup={this._pickGroup} />}
          </HashRouter>
        </ShowSearchBarContext.Provider>
      </SearchContext.Provider>
    );
  }

  private _setClickCount = (headButtonIdx: number) => {
    console.log('_setClickCount: ', this.state.searchQuery);
    this.settings.headButtonIdx = headButtonIdx;
    const HeadClickCount = this.state.HeadClickCount + 1;
    this.setState({ HeadClickCount, headButtonIdx, searchQuery: '' });
    //this._saveSettings();
  };

  private _saveSettings = (settings?: IAppSessionSettings) => {
    console.log('_saveSettings 1:', settings);
    if (settings) {
      this.settings = { ...this.settings, ...settings };
    }
    console.log('_saveSettings 2: ', this.settings);
    localStorage.setItem('iDigestSettings', JSON.stringify(this.settings));
  };

  private _getOnline = () => {
    this.setState({ pickGroup4Online: true });
  };

  private _pickGroup = (id: number) => {
    this.setState({ pickGroup4Online: false });
    if (!id) {
      return;
    } // dialogue is dismissed

    // open new browser version
    if (this._meetingWindow && this._meetingGroupId === id) {
      //launching into the same meeting
      if (!this._meetingWindow.closed) {
        this._meetingWindow.focus();
        return; // since the user should be already in the meeting
      }
      this._meetingGroupId = -1;
    }
    _dataProvider.getGroupMeetLink(id).then((result) => {
      if (!result) {
        alert('Network connection failed, try again');
        return;
      }

      //"You are in an existing meeting, do you want to exit and join a new meeting?"
      if (this._meetingWindow && !this._meetingWindow.closed) {
        const cont = confirm('你正在一个会议中，你要退出，加入一个新会议吗?');
        if (!cont) {
          this._meetingWindow.focus();
          return; //the user don't want to continue
        }
      }
      const params = [
        'height=' + screen.height,
        'width=' + screen.width,
        'fullscreen=yes', // only works in IE, but here for completeness
        'toolbar=0,location=0,menubar=0,status=0,location=0,titlebar=0,top=100,left=0'
      ].join(',');

      let url = result.url;
      if (i18n.language === 'eng') {
        url = url.replace('zhCN', 'en');
      }
      this._meetingWindow = window.open(url, 'idigestMeeting', params);
      this._meetingWindow.focus();
    });
    return;
  };

  /*
          这个PWA是一个“小鸽子”的辅助工具.
          如果你想便捷地将文件分享到“小鸽子”的团契聊天室，你可以安装这个工具。
          之后你就可以使用这个工具所提供的功能，
          而不用再对于这个工具做任何操作了。
          <br />
          我们暂时没有苹果手机/Mac的相应工具。
  */
  private _installPwa = () => {
    if (this.state.installedEvent) {
      return (
        <div style={{ margin: '30px', textAlign: 'center' }}>
          <p>安装完成, 你可以&quot;关闭&quot;这个页面, 开始使用向&quot;小鸽子&quot;分享的功能</p>
          <br></br>
          <br></br>
          <br></br>
          <button
            onClick={async () => {
              window.close();
            }}
            style={{ width: '8em', borderRadius: '25px' }}>
            关闭
          </button>
        </div>
      );
    }
    if (window.location.href.indexOf('/share') !== -1) {
      return (
        <div style={{ margin: '30px', textAlign: 'center' }}>
          <p>分享进行中...</p>
          {!this.state.signInState && (
            <SignInHeaderWrapper
              signInOut={this._signInOut}
              loginUrl={this.loginUrl}
              pwa={true}
              signInState={this.state.signInState}
            />
          )}
          {this.state.signInState && (
            <HashRouter>
              <Routes>
                <Route path='/share' element={<Share2 file={this.state.file} msg={this.state.msg} />}></Route>
              </Routes>
            </HashRouter>
          )}
        </div>
      );
    }
    return (
      <div style={{ margin: '30px', textAlign: 'center' }}>
        <p>下载完成, 请点击以下按钮, 然后按照提示完成安装</p>
        <br></br>
        <br></br>
        <br></br>
        <button
          onClick={async () => {
            if (!this.state.deferredPrompt) {
              //alert("Refresh the page and try again");
              return; // should there be an error?
            }
            this.state.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            const { outcome } = await this.state.deferredPrompt.userChoice;
            // Optionally, send analytics event with outcome of user choice
            console.log(`User response to the install prompt: ${outcome}`);
            //outcome is 'dismissed' or ' accepted'
            // but maybe deal with it at the installation event
            // We've used the prompt, and can't use it again, throw it away
            const deferredPrompt = null;
            this.setState({ deferredPrompt });
          }}
          style={{ width: '8em', borderRadius: '25px' }}>
          安装PWA
        </button>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  };
}

export default App;
