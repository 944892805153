/* eslint-disable */
// @ts-nocheck --- turn it back to .js file
import React from 'react';
import { isMobile } from 'react-device-detect';


export const legitHosts = ["idigest.app", "kcenter.top", "xiaogezi.site", 'xwa2.site', 'boteli.online'];
const whichHost = legitHosts.findIndex((hh)=>window.location.href.includes(hh));
const host = legitHosts[whichHost===-1 ? 0 : whichHost];

const HostURL = 'https://' + host;

export const SERVER_HOST = process.env.NODE_ENV === 'development' ? host : window.location.hostname; 
export const SERVER_URL = process.env.NODE_ENV === 'development' ? HostURL : 'https://' + window.location.hostname;

//local, <preview:todo>, and production API URL
export const HostOfficialServer = SERVER_URL + '/api'; //"https://s2.idigest.app";
export const HostApiLocal = 'http://localhost:5000';

const domainParts = window.location.hostname.split(".");
const baseDomain = domainParts.slice(-2);

export const pubContentUrl = HostOfficialServer; //"https://s2.idigest.app";

export const pwaUrl = window.location.href.startsWith("http://localhost")
  ? "http://penguin.linux.test/pwa/"
  : "https://pwa." + baseDomain[0] + "." + baseDomain[1];

export const phoneEmulatorTemplate = "./html/template.html";

import GroupImageLeader from "../img/icon-Group-leader.png";
import OrgImageAdmin from "../img/icon-Org-leader.png";
import OrgImageMember from "../img/icon-Org-member.png";
import GroupImageMember from "../img/icon-Group-member-regular.png";

// function to parse token
// in order to get the userid
function parseJwt(token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function getJwtObj() {
  const token = localStorage.getItem('access_token');
  if (!!token) {
    try {
      return parseJwt(token);
    } catch (error) {
      alert(error);
      // wrong Jwt, logout
      wipeSignInState();
    }
  }

  return {
    userId: -1,
    loginId: 'N/A'
  };
}

export function isUserSignedIn() {
  const token = localStorage.getItem('access_token');
  return !!token;
}

export function getToken() {
  const token = localStorage.getItem('access_token');
  return token;
}

export function getAuthStr() {
  const token = localStorage.getItem('access_token');
  return 'Bearer '.concat(token ? token : '');
}

export function getAccessToken() {
  const token = localStorage.getItem('access_token');
  return token;
}

export function logoutUser(logout) {
  if (logout) {
    setTimeout(() => document.location.reload(true), 3000);
  }
}

export function getSignInInfo() {
  if (!localStorage.getItem('access_token')) {
    return { name: 'Sign In', key: 'login' };
  } else {
    const userName = localStorage.getItem('displayName');
    return { name: userName + ' signed in', key: 'logout' };
  }
}

export function wipeSignInState() {
  localStorage.setItem('displayName', '');
  localStorage.setItem('churchName', '');
  localStorage.setItem('access_token', '');
  localStorage.setItem('isChurchAdmin', '');
  localStorage.setItem("SignInState", '');

  window.location.href = '/';
  return;
}
export function getSignOut() {
  const yes = prompt('You sure want to sign out? type in yes');
  if (yes === 'yes') {
    wipeSignInState();
    logoutUser(true);
  }
  return;
}

//exports.logoutUser = logoutUser;

export function loadFrame(frameName, bookContent, day, scroll2Bottom) {
  var scrollTop = scroll2Bottom ? -1 : 0;
  const content = {
    book: {
      title: bookContent.bookTitle //need to get book title
    },
    lesson: {
      title: bookContent.title,
      sessions: bookContent.sessions // the lesson title
      //background: this.props.bookContent.background || './html/background.jpg'
    },
    //group: this.group,
    //groups: this.props.groups,
    //answers: this.props.answers,
    /*images: {
      home: Asset.fromModule(require('../assets/images/home.png')).uri,
      group: Asset.fromModule(require('../assets/images/icon-Group.png')).uri,
      note: Asset.fromModule(require('../assets/images/note.png')).uri
    },*/
    //unreadChat: this.getUnreadChat(),
    position: { day,  scrollTop}
    //newGroupMessage
  };

  const previewFrame = window.frames[frameName];
  console.log(content);
  previewFrame.content = content;
  previewFrame.initContent();
}

export function reLoadFrame(id, uri) {
  if (document && document.getElementById(id)) document.getElementById(id).src = uri;
}

export const signInSignOut = (props) => {
  return (
    <div style={{height:'auto',marginLeft:'5px'}}>
      <p>Register in iDigest App</p>
      <label for="loginId">User ID</label>
      <input id="loginId"/>
      <label for="password">Password</label>
      <input type="password" id="password"></input>
      <button>Sign in</button>
    </div>
  );
}

export const groupIcon = (item) => {
  if (item.isOrgGroup) {
    return item.isGroupLeader ? OrgImageAdmin : OrgImageMember;
  } else if (item.isOneOnOneGroup) {
    //get the friend icon image
    return `${HostOfficialServer}/user/avatar/${item.friendUserId}`;
  } else {
    return item.isGroupLeader ? GroupImageLeader : GroupImageMember;
  }
};

const elem = document.getElementById('root');
let rootDivStyle = {};
if (elem) {
  const rootDivStyle = getComputedStyle(elem);
  console.log('computed styles: ', rootDivStyle.minWidth);
}
/*
let testSmallScreen = false;
if (typeof window.matchMedia === 'function') {
  // jest test time there is no matchMedia function!
  const x = window.matchMedia('(max-width: 640px)');
  testSmallScreen = x.matches;
}

export const _smallScreen = testSmallScreen;
*/

export const _smallScreen = rootDivStyle.minWidth === '360px' || isMobile;

