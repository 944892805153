import React from 'react';
import { _dataProvider } from '../dataProvider/DataProvider';
import i18n from '../i18n';

const BibleVersions: { [index: string]: any } = {
  简体中文: [
    {
      name: '和合本',
      id: 'cuvs',
      copyright: 'Publish domain'
    }
  ],
  繁體中文: [
    {
      name: '和合本',
      id: 'cuvt',
      copyright: 'Publish domain'
    }
  ],
  English: [
    {
      name: 'American Standard Version',
      id: 'asv',
      copyright: 'Publish domain'
    },
    { name: 'King James Version', id: 'kjv', copyright: 'Publish domain' },
    { name: 'Darby Translation', id: 'darby', copyright: 'Publish domain' },
    {
      name: "Young's Literal Translation",
      id: 'ylt',
      copyright: 'Publish domain'
    }
  ]
};

interface IBibleNavigationProps {
  bibleVersion1Language: string;
  bibleVersion1Name: string;
  bibleVersion2Language: string;
  bibleVersion2Name: string;
  bibleData: any;
  merge: boolean;
  setBibleCompareState: any;
  setBible: any;
  contentHeight: string;
  //bookName: string;
  //chapter: number;
  //beginVerse?: number;
  //endVerse?: number;
}

interface IBibleVerse {
  verse: string;
  text: string;
}

interface IBibleNavigationState {
  //parse the props.passage into the following
  book: string;
  verse: string;
  bookId: number;
  merge: boolean;
  passage?: IBibleVerse[];
}

class BibleNavigation extends React.Component<IBibleNavigationProps, IBibleNavigationState> {
  private version1?: any;
  private version2?: any;
  private bible1?: any;
  private bible2?: any;

  private chapterFrom: number;
  private verseFrom: number;
  private chapterTo: number;
  private verseTo: number;

  constructor(props: IBibleNavigationProps) {
    super(props);
    const lang1 = this.props.bibleVersion1Language === 'chs' ? '简体中文' : '繁體中文';
    for (const vvv of BibleVersions[lang1]) {
      if (vvv.name === this.props.bibleVersion1Name) {
        this.version1 = vvv;
        break;
      }
    }
    for (const vvv of BibleVersions[this.props.bibleVersion2Language]) {
      if (vvv.name === this.props.bibleVersion2Name) {
        this.version2 = vvv;
        break;
      }
    }

    this.chapterFrom = 0;
    this.verseFrom = 0;
    this.chapterTo = 0;
    this.verseTo = 0;

    //this needs work === parse this.props.passage into bookName + verses
    this.state = {
      merge: props.merge,
      book: props.bibleData.book,
      verse: props.bibleData.verse,
      bookId: props.bibleData.bookId
    };
  }

  loadPassage = async (state: IBibleNavigationState) => {
    if (!this.bible1) {
      this.bible1 = await _dataProvider.getBible(this.version1.id);
    }

    if (state.merge && !this.bible2) {
      this.bible2 = await _dataProvider.getBible(this.version2.id);
    }

    let passage: IBibleVerse[] = this.getPassage(this.bible1, state);
    if (state.merge) {
      const passage2 = this.getPassage(this.bible2, state);
      if (passage2) {
        const verses: IBibleVerse[] = [];
        // merge
        const length = passage.length > passage2.length ? passage.length : passage2.length;
        for (let i = 0; i < length; i++) {
          if (passage[i]) {
            verses.push(passage[i]);
          }
          if (passage2[i]) {
            verses.push(passage2[i]);
          }
        }
        passage = verses;
      }
    }

    this.setState({ passage });
  };

  getPassage = (bible: any, state: IBibleNavigationState): IBibleVerse[] => {
    if (!bible) {
      return [];
    }

    const { bookId, verse } = state;
    const result: IBibleVerse[] = [];

    const items = verse.split(/(:|-)/g);
    if (items.length === 1) {
      // parse chapter: 1
      this.chapterFrom = parseInt(items[0]);
      this.verseFrom = 1;
      this.chapterTo = this.chapterFrom;
      this.verseTo = 999;
    } else if (items.length === 3 && items[1] === '-') {
      // parse chapter: 1-2
      this.chapterFrom = parseInt(items[0]);
      this.verseFrom = 1;
      this.chapterTo = parseInt(items[2]);
      this.verseTo = 999;
    } else if (items.length === 3 && items[1] === ':') {
      // parse chapter: 1:33
      this.chapterFrom = parseInt(items[0]);
      this.verseFrom = parseInt(items[2]);
      this.chapterTo = this.chapterFrom;
      this.verseTo = this.verseFrom;
    } else if (items.length === 5 && items[1] === ':' && items[3] === '-') {
      // parse chapter: 1:1-3
      this.chapterFrom = parseInt(items[0]);
      this.verseFrom = parseInt(items[2]);
      this.chapterTo = this.chapterFrom;
      this.verseTo = parseInt(items[4]);
    } else if (items.length === 7 && items[1] === ':' && items[3] === '-' && items[5] === ':') {
      // parse chapter: 1:1-2:10
      this.chapterFrom = parseInt(items[0]);
      this.verseFrom = parseInt(items[2]);
      this.chapterTo = parseInt(items[4]);
      this.verseTo = parseInt(items[6]);
    } else {
      alert('Error format: ' + verse);
      return result;
    }

    let chapter = this.chapterFrom;
    let verseIdx = this.verseFrom;
    while (chapter * 1000 + verseIdx <= this.chapterTo * 1000 + this.verseTo) {
      const id = bookId * 1000000 + chapter * 1000 + verseIdx;
      // Chinese bible has some empty verse
      if (!bible[id] && !bible[id + 1]) {
        chapter++;
        verseIdx = 1;
      } else {
        const text = bible[id] ? bible[id] : '';
        result.push({
          verse: `${chapter}:${verseIdx}`,
          text: this.getVerseText(text)
        });
        verseIdx++;
      }
    }
    if (this.verseTo === 999) {
      this.verseTo = verseIdx - 1; // in case it was set to 999
    }
    return result;
  };

  getVerseText = (verseText: string) => {
    const AnnotationWords = ['the', 'in', 'of', 'on', 'and', 'an', 'to', 'a', 'as', 'for'];
    // Check to see if the first line is part of the bible
    const firstLinePos = verseText.indexOf('\n');
    if (firstLinePos !== -1) {
      const firstLine = verseText.substring(0, firstLinePos);
      let annotation = true;
      if (verseText.length > firstLinePos) {
        // We have more than one lines
        const words = firstLine.split(' ');
        // It has to be more than one words
        if (words.length > 1) {
          // Check each word starts with upper case
          for (const w in words) {
            if (AnnotationWords.indexOf(words[w]) === -1 && words[w][0] !== words[w][0].toUpperCase()) {
              // Not upper case, not an annotation
              annotation = false;
              break;
            }
          }

          // Use "()" for annotation if found
          if (annotation) {
            verseText = '[' + firstLine + '] ' + verseText.substring(firstLinePos + 1);
          }
        }
      }
    }

    return verseText;
  };

  public componentDidMount(): void {
    this.loadPassage(this.state);
  }

  public shouldComponentUpdate(nextProps: IBibleNavigationProps, nextState: IBibleNavigationState) {
    console.log('Bible Navigator shouldComponentUpdate:', nextProps, nextState);
    if (
      this.state.bookId !== nextState.bookId ||
      this.state.verse !== nextState.verse ||
      this.state.merge !== nextState.merge
    ) {
      this.loadPassage(nextState);
    }
    if (this.props.bibleData !== nextProps.bibleData) {
      this.setState({
        book: nextProps.bibleData.book,
        bookId: nextProps.bibleData.bookId,
        verse: nextProps.bibleData.verse
      });
    }
    return true;
  }

  heading = () => {
    //this is actually more complicated, as we can have 1:1-3:20
    //const verse = this.state.beginVerse ? `:${this.state.beginVerse}-${this.state.endVerse}` : '';
    return `${this.state.book} ${this.state.verse}`;
  };

  bibleVersionDisplay = () => {
    if (this.state.merge) {
      return `[${this.props.bibleVersion1Name} / ${this.props.bibleVersion2Name}]`;
    } else {
      return `[${this.props.bibleVersion1Name}]`;
    }
  };

  compare = () => {
    this.props.setBibleCompareState(true);
    this.setState({ merge: true });
    return;
  };

  onlyVersion1 = () => {
    this.props.setBibleCompareState(false);
    this.setState({ merge: false });
    return;
  };

  upMore = () => {
    if (this.verseFrom <= 1) {
      return;
    }

    this.verseFrom -= 1;
    const passage = this.state.passage;

    const id = this.state.bookId * 1000000 + this.chapterFrom * 1000 + this.verseFrom;
    const text = this.bible1[id] ? this.bible1[id] : '';
    passage?.unshift({
      verse: `${this.chapterFrom}:${this.verseFrom}`,
      text: this.getVerseText(text)
    });

    const verse = this.constructVerse();
    this.setState({ passage, verse });

    return;
  };

  downMore = () => {
    const passage = this.state.passage;
    const length = passage?.length;
    if (!length) {
      return;
    }
    if (passage![length - 1].text === '<<end of chapter>>') {
      return;
    }

    const id = this.state.bookId * 1000000 + this.chapterTo * 1000 + this.verseTo + 1;
    if (!this.bible1[id] && !this.bible1[id + 1]) {
      passage?.push({
        verse: '',
        text: '<<end of chapter>>'
      });
    } else {
      const text = this.bible1[id] ? this.bible1[id] : '';
      this.verseTo += 1;
      passage?.push({
        verse: `${this.chapterTo}:${this.verseTo}`,
        text: this.getVerseText(text)
      });
    }

    const verse = this.constructVerse();
    this.setState({ passage, verse });
    return;
  };

  constructVerse = () => {
    if (this.chapterTo === this.chapterFrom) {
      return `${this.chapterFrom}:${this.verseFrom}-${this.verseTo}`;
    } else {
      return `${this.chapterFrom}:${this.verseFrom}-${this.chapterTo}:${this.verseTo}`;
    }
  };

  close = () => {
    this.props.setBible(undefined);
  };

  webBible = () => {
    const search = `${this.state.book}/${parseInt(this.state.verse)}`;
    const url = `http://www.chinesebibleonline.com/book/${search}`;
    window.open(url);
  };

  render(): React.ReactNode {
    console.log('BibleNavigation.render()');
    return (
      <>
        <h3 className='ColumnHead'>{i18n.t('BibleNavigation')}</h3>
        <div className='ContentV' style={{ height: this.props.contentHeight }}>
          <div>
            <button
              style={{
                border: '1px solid',
                margin: '0 0 0 10px',
                backgroundColor: this.state.merge ? '#FFFF00' : 'transparent'
              }}
              onClick={this.compare}>
              {i18n.t('Bilingual')}
            </button>
            <button
              style={{
                border: '1px solid',
                margin: '0 0 0 10px',
                backgroundColor: !this.state.merge ? '#FFFF00' : 'transparent'
              }}
              onClick={this.onlyVersion1}>
              {this.props.bibleVersion1Name}
            </button>
            <button style={{ border: '1px solid', margin: '0 20px 0px 10px' }} onClick={this.webBible}>
              WebBible
            </button>
            <button style={{ border: '1px solid', margin: '0 0 0 10px' }} onClick={this.upMore}>
              &#x21E7;
            </button>
            <button style={{ border: '1px solid', margin: '0 0 0 10px' }} onClick={this.downMore}>
              &#x21E9;
            </button>
            <button className='closeButton' onClick={this.close}>
              {i18n.t('Close')}
            </button>
          </div>
          <h3 style={{ textAlign: 'center' }}>{this.heading()}</h3>
          <table>
            <thead></thead>
            <tbody>
              {this.state.passage &&
                this.state.passage.map((verse, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {verse.verse} {verse.text.replace(/\n/g, '<br>')}
                      </td>
                    </tr>
                  );
                })}
              {this.state.passage && (
                <tr key='bibleVersion'>
                  <td style={{ textAlign: 'right' }}>{this.bibleVersionDisplay()}</td>
                </tr>
              )}
              {!this.state.passage && (
                <tr key='loading'>
                  <td style={{ textAlign: 'right' }}>Loading...</td>
                </tr>
              )}
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </>
    );
  }
}

export default BibleNavigation;

/*
<p>I will display &spades;<p>
<p>I will display &#9824;<p>
<p>I will display &#x2660;<p>
&#x21E;
&#x29E;
&#x2AE;
&#x2BE;

U+21Ex
⇠
⇡
⇢
⇣
⇤
⇥
⇦
⇧
⇨
⇩
⇪
⇫
⇬
⇭
⇮
⇯

*/
