/* eslint no-use-before-define: ["error", { "variables": false }] */

import PropTypes from 'prop-types';
import React from 'react';

import { Text, Pressable, /*Clipboard,*/ Linking, StyleSheet, View } from 'react-native-web';

import MessageText from './MessageText';
import MessageImage from './MessageImage';
import MessageVideo from './MessageVideo';

import Time from './Time';
import Color from './Color';

import { isSameUser, isSameDay } from './utils';
import TouchableOpacity from './TouchableOpacity';

export default class Bubble extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      maxWidth: 'auto',
      onHover: false
    };
  }

  componentDidMount() {
    const enclosingDiv = document.getElementById('idEnclosingParent');
    if (!enclosingDiv) {
      return;
    }
    const width = enclosingDiv.offsetWidth - 68;
    this.setState({ maxWidth: width + 'px' });
  }

  onPress = () => {
    if (this.props.onPress) {
      this.props.onPress(this.props.currentMessage);
    } else if (this.props.currentMessage.attachment) {
      const url = this.props.currentMessage.attachment.url;
      if (url) {
        Linking.canOpenURL(url).then((supported) => {
          if (!supported) {
            // eslint-disable-next-line
            console.error('No handler for URL:', url);
          } else {
            Linking.openURL(url);
          }
        });
      }
    }
    this.setState({ onHover: false });
  };
  //GT: ActionSheet functionaliy, on PC/Web, is better to be based on
  // mouse over rather than LongPress...
  onHoverIn = () => {
    this.setState({ onHover: true });
  };
  onHoverOut = () => {
    this.setState({ onHover: false });
  };
  onLongPress = () => {
    if (this.props.onLongPress) {
      this.props.onLongPress(this.props.currentMessage);
    } else if (this.props.currentMessage.text) {
      this.setState({ onHover: true });

      /* GT -- the context doesn't work
      const options = ['Copy Text', 'Cancel'];
      const cancelButtonIndex = options.length - 1;
      this.context.actionSheet().showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              Clipboard.setString(this.props.currentMessage.text);
              break;
            default:
              break;
          }
        }
      );
      */
    }
  };

  handleBubbleToNext() {
    if (
      isSameUser(this.props.currentMessage, this.props.nextMessage) &&
      isSameDay(this.props.currentMessage, this.props.nextMessage)
    ) {
      return StyleSheet.flatten([
        styles[this.props.position].containerToNext,
        this.props.containerToNextStyle[this.props.position]
      ]);
    }
    return null;
  }

  handleBubbleToPrevious() {
    if (
      isSameUser(this.props.currentMessage, this.props.previousMessage) &&
      isSameDay(this.props.currentMessage, this.props.previousMessage)
    ) {
      return StyleSheet.flatten([
        styles[this.props.position].containerToPrevious,
        this.props.containerToPreviousStyle[this.props.position]
      ]);
    }
    return null;
  }

  renderMessageAction() {
    const { messageActionSheet } = this.props;
    if (!this.state.onHover || !messageActionSheet) {
      return null;
    }

    const actions = messageActionSheet(this.props.currentMessage);
    if (!actions || !Array.isArray(actions)) {
      return null;
    }

    return actions.map((action) => {
      return (
        <img
          style={{
            maxWidth: 17,
            maxHeight: 17,
            marginRight: 18
          }}
          key={action.label}
          title={action.label}
          src={action.icon}
          onClick={(e) => {
            e.stopPropagation();
            action.action(this.props.currentMessage);
            this.setState({ onHover: false });
          }}
        />
      );
    });
  }

  renderMessageText() {
    if (this.props.currentMessage.text) {
      //eslint-disable-next-line
      const { containerStyle, wrapperStyle, ...messageTextProps } = this.props;
      if (this.props.renderMessageText) {
        return this.props.renderMessageText(messageTextProps);
      }
      return <MessageText {...messageTextProps} />;
    }
    return null;
  }

  renderMessageImage() {
    if (this.props.currentMessage.image) {
      //eslint-disable-next-line
      const { containerStyle, wrapperStyle, ...messageImageProps } = this.props;
      if (this.props.renderMessageImage) {
        return this.props.renderMessageImage(messageImageProps);
      }
      return <MessageImage {...messageImageProps} />;
    }
    return null;
  }

  renderMessageVideo() {
    if (this.props.currentMessage.video) {
      //eslint-disable-next-line
      const { containerStyle, wrapperStyle, ...messageVideoProps } = this.props;
      if (this.props.renderMessageVideo) {
        return this.props.renderMessageVideo(messageVideoProps);
      }
      return <MessageVideo {...messageVideoProps} />;
    }
    return null;
  }

  renderTicks() {
    const { currentMessage } = this.props;
    if (this.props.renderTicks) {
      return this.props.renderTicks(currentMessage);
    }
    if (currentMessage.user.id !== this.props.user.id) {
      return;
    }
    if (currentMessage.sent || currentMessage.received || currentMessage.pending) {
      return (
        <View style={styles.tickView}>
          {currentMessage.sent && <Text style={[styles.tick, this.props.tickStyle]}>✓</Text>}
          {currentMessage.received && <Text style={[styles.tick, this.props.tickStyle]}>✓</Text>}
          {currentMessage.pending && <Text style={[styles.tick, this.props.tickStyle]}>🕓</Text>}
        </View>
      );
    }
    return null;
  }

  renderTime() {
    if (this.props.currentMessage.createdAt) {
      //eslint-disable-next-line
      const { containerStyle, wrapperStyle, ...timeProps } = this.props;
      if (this.props.renderTime) {
        return this.props.renderTime(timeProps);
      }
      return <Time {...timeProps} />;
    }
    return null;
  }

  renderUsername() {
    const { currentMessage } = this.props;
    if (this.props.renderUsernameOnMessage) {
      if (currentMessage.user.id === this.props.user.id) {
        return null;
      }
      return (
        <View style={styles.usernameView}>
          <Text style={[styles.username, this.props.usernameStyle]}>~ {currentMessage.user.name}</Text>
        </View>
      );
    }
    return null;
  }

  renderCustomView() {
    if (this.props.renderCustomView) {
      return this.props.renderCustomView(this.props);
    }
    return null;
  }

  render() {
    const quote = this.props.currentMessage.quote;
    const showQuote = typeof quote === 'string' && quote.length > 0;
    let bottomStyle = {};
    if (showQuote) {
      bottomStyle = {
        borderBottomLeftRadius: showQuote ? 0 : styles.borderBottomLeftRadius,
        borderBottomRightRadius: showQuote ? 0 : styles.borderBottomRightRadius
      };
    }
    return (
      <View style={[styles[this.props.position].container, this.props.containerStyle[this.props.position]]}>
        <Pressable
          onHoverIn={this.onHoverIn}
          onHoverOut={this.onHoverOut}
          style={[
            styles[this.props.position].wrapper,
            this.props.wrapperStyle[this.props.position],
            this.handleBubbleToNext(),
            this.handleBubbleToPrevious(),
            bottomStyle
          ]}>
          <TouchableOpacity
            style={{ maxWidth: this.state.maxWidth }}
            withoutFeedback
            onLongPress={this.onLongPress}
            onPress={this.onPress}
            accessibilityTraits='text'
            {...this.props.touchableProps}>
            <View>
              {this.renderCustomView()}
              {this.renderMessageImage()}
              {this.renderMessageVideo()}
              {this.renderMessageText()}
              <View style={[styles[this.props.position].bottom, this.props.bottomContainerStyle[this.props.position]]}>
                {this.renderUsername()}
                {this.renderTime()}
                {this.renderTicks()}
                {this.renderMessageAction()}
              </View>
            </View>
          </TouchableOpacity>
        </Pressable>
        <View>
          {showQuote ? (
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => {
                if (typeof this.props.onQuotePress === 'function') {
                  this.props.onQuotePress(this.props.currentMessage);
                }
              }}>
              <View
                style={[
                  styles[this.props.position].wrapper,
                  this.props.wrapperStyle[this.props.position],
                  this.handleBubbleToNext(),
                  this.handleBubbleToPrevious(),
                  {
                    marginTop: 2,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    backgroundColor: '#A0A0A0'
                  }
                ]}>
                <Text
                  style={{
                    padding: 5,
                    color: 'white',
                    maxWidth: this.state.maxWidth
                  }}
                  numberOfLines={2}
                  ellipsizeMode='tail'>
                  {quote.replace(/\n/g, ' ')}
                </Text>
              </View>
            </TouchableOpacity>
          ) : null}
        </View>
      </View>
    );
  }
}

const styles = {
  left: StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'flex-start'
    },
    wrapper: {
      borderRadius: 15,
      backgroundColor: Color.leftBubbleBackground,
      marginRight: 60,
      minHeight: 20,
      justifyContent: 'flex-end',
      whiteSpace: 'pre'
    },
    containerToNext: {
      borderBottomLeftRadius: 3
    },
    containerToPrevious: {
      borderTopLeftRadius: 3
    },
    bottom: {
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }
  }),
  right: StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'flex-end'
    },
    wrapper: {
      borderRadius: 15,
      backgroundColor: Color.defaultBlue,
      marginLeft: 60,
      minHeight: 20,
      justifyContent: 'flex-end',
      whiteSpace: 'pre'
    },
    containerToNext: {
      borderBottomRightRadius: 3
    },
    containerToPrevious: {
      borderTopRightRadius: 3
    },
    bottom: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }
  }),
  tick: {
    fontSize: 10,
    backgroundColor: Color.backgroundTransparent,
    color: Color.white
  },
  tickView: {
    flexDirection: 'row',
    marginRight: 10
  },
  username: {
    top: -3,
    left: 0,
    fontSize: 12,
    backgroundColor: 'transparent',
    color: '#aaa'
  },
  usernameView: {
    flexDirection: 'row',
    marginHorizontal: 10
  }
};

/*
Bubble.contextTypes = {
  actionSheet: PropTypes.func
};
*/

Bubble.defaultProps = {
  touchableProps: {},
  onLongPress: null,
  renderMessageImage: null,
  renderMessageVideo: null,
  renderMessageText: null,
  renderCustomView: null,
  renderUsername: null,
  renderTicks: null,
  renderTime: null,
  position: 'left',
  currentMessage: {
    text: null,
    createdAt: null,
    image: null
  },
  nextMessage: {},
  previousMessage: {},
  containerStyle: {},
  wrapperStyle: {},
  bottomContainerStyle: {},
  tickStyle: {},
  usernameStyle: {},
  containerToNextStyle: {},
  containerToPreviousStyle: {}
};

Bubble.propTypes = {
  user: PropTypes.object.isRequired,
  touchableProps: PropTypes.object,
  onLongPress: PropTypes.func,
  renderMessageImage: PropTypes.func,
  renderMessageVideo: PropTypes.func,
  renderMessageText: PropTypes.func,
  renderCustomView: PropTypes.func,
  renderUsernameOnMessage: PropTypes.bool,
  renderUsername: PropTypes.func,
  renderTime: PropTypes.func,
  renderTicks: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
  currentMessage: PropTypes.object,
  nextMessage: PropTypes.object,
  previousMessage: PropTypes.object
  //tickStyle: Text.propTypes.style,
  //usernameStyle: Text.propTypes.style,
  /*
  containerStyle: PropTypes.shape({
    left: ViewPropTypes.style,
    right: ViewPropTypes.style,
  }),
  wrapperStyle: PropTypes.shape({
    left: ViewPropTypes.style,
    right: ViewPropTypes.style,
  }),
  bottomContainerStyle: PropTypes.shape({
    left: ViewPropTypes.style,
    right: ViewPropTypes.style,
  }),
  containerToNextStyle: PropTypes.shape({
    left: ViewPropTypes.style,
    right: ViewPropTypes.style,
  }),
  containerToPreviousStyle: PropTypes.shape({
    left: ViewPropTypes.style,
    right: ViewPropTypes.style,
  }),
  */
};
