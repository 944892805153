import { IAppSessionSettings } from '../dataProvider/IAppSessionSettings';
import Footer from '../components/Footer';

import { _dataProvider } from '../dataProvider/DataProvider';

import OrgGroupList from './OrgGroupList';
import OrgDetail from './OrgDetail';
import GroupsDetail from './GroupsDetail';

import React from 'react';
import './Org.css';

interface IMgmtOrgGroupListProps {
  saveSettings: any;
  headerHeight: number;
  signInState: boolean;
  settings: IAppSessionSettings;
  HeadClickCount: number;
}

interface IMgmtOrgGroupListState {
  orgList: any[];
  groupList: any[];
  orgIdx?: number;
  groupIdx?: number;
  loading: boolean;
}

class MgmtOrgGroupList extends React.Component<IMgmtOrgGroupListProps, IMgmtOrgGroupListState> {
  constructor(props: IMgmtOrgGroupListProps) {
    super(props);

    this.state = {
      orgList: [],
      groupList: [],
      orgIdx: undefined,
      groupIdx: undefined,
      loading: true
    };
  }

  public shouldComponentUpdate(nextProps: IMgmtOrgGroupListProps) {
    if (this.props.signInState !== nextProps.signInState) {
      if (!nextProps.signInState) {
        this.setState({
          orgList: [],
          groupList: [],
          orgIdx: undefined,
          groupIdx: undefined,
          loading: true
        });
      } else {
        this.load();
      }
    } else if (this.props.HeadClickCount !== nextProps.HeadClickCount) {
      this.setState(
        {
          orgList: [],
          groupList: [],
          orgIdx: undefined,
          groupIdx: undefined,
          loading: true
        },
        () => {
          this.load();
        }
      );
    }

    return true;
  }

  async load() {
    _dataProvider.getManagedOrgsAndGroups().then((data: any) => {
      if (data === null) {
        alert('Server error, check network connection, and try again');
        data = { organizations: [], groups: [] };
      }
      const { organizations, groups } = data;
      this.setState({
        orgList: organizations,
        groupList: groups,
        loading: false
      });
    });
  }

  public async componentDidMount(): Promise<void> {
    this.load();
  }

  private _setOrg = (orgIdx: number): void => {
    console.log('pick org: ', orgIdx);
    this.setState({ orgIdx });
  };

  private _setGroup = (groupIdx: number): void => {
    this.setState({ groupIdx });
  };

  render(): React.ReactNode {
    if (!this.props.signInState) {
      return (
        <div style={{ height: '100%', display: 'flex' }}>
          <p style={{ marginLeft: '15px', marginTop: '5px' }}>
            Please sign in before you can view your Organizations and Groups
          </p>
        </div>
      );
    }
    if (this.state.loading) {
      return <h1>Loading...</h1>;
    } else {
      return (
        <>
          {this.state.orgIdx !== undefined && (
            <OrgDetail
              org={this.state.orgList[this.state.orgIdx]}
              leaderGroup={this.state.groupList}
              headerHeight={this.props.headerHeight}
            />
          )}
          {this.state.groupIdx !== undefined && (
            <GroupsDetail
              groupList={this.state.groupList}
              specificgroup={this.state.groupIdx}
              headerHeight={this.props.headerHeight}
            />
          )}
          {this.state.orgIdx === undefined && this.state.groupIdx === undefined && (
            <OrgGroupList
              orgList={this.state.orgList}
              groupList={this.state.groupList}
              setOrg={this._setOrg}
              setGroup={this._setGroup}
            />
          )}
          <Footer />
        </>
      );
    }
  }
}

export default MgmtOrgGroupList;
