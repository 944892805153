import * as React from 'react';
import {
  DetailsList,
  IColumn,
  DetailsListLayoutMode,
  IDetailsRowProps,
  DetailsRow
} from '@fluentui/react/lib/DetailsList';
import './StudentOverviewList.css';
import { IStudent } from '../dataProvider/IClassManagement';
import { _dataProvider } from '../dataProvider/DataProvider';
import i18n from '../i18n';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { SearchBox } from '@fluentui/react';
import { getOrgLogo } from '../SharedCommon/OrgList';

interface IStudentOverviewListProps {
  groupId: number | null;
  trainingId: number;
  updateStudents: (newStudents: IStudent[]) => void;
}

interface IGroup {
  groupId: number;
  name: string;
  subTitle: string;
  createdTime: string;
  lastModifiedTime: string;
  lastMessageTime: string | null;
  status: number;
  disappearingMessages: number;
  isGroupLeader?: number;
  StudyGroupName?: string;
  memberCount?: number;
}

const StudentOverviewList: React.FC<IStudentOverviewListProps> = ({ groupId, trainingId, updateStudents }) => {
  // this represents the students that you want to show on the table
  const [students, setStudents] = React.useState<IStudent[]>([]);
  // also need to keep track of all of the students that we want to show
  const [allShowStudents, setAllShowStudents] = React.useState<IStudent[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  // for showing the groups when group change box is needed
  // group name to their id
  type groupMappingsType = { [key: string]: number };
  const [groupMappings, setGroupMappings] = React.useState<Map<string, number>>(new Map());
  // we meed a mapping between student and the groups that they are in and also admin status
  const [studentGroupMap, setStudentGroupMap] = React.useState<{
    [studentId: number]: { userName: string; groupId: number; groupName: string; isAdmin: boolean }[];
  }>({});
  // need to count the amount of admins within groups
  const [groupAdminCountMap, setGroupAdminCountMap] = React.useState<{ [groupName: string]: number }>({});
  // need the mapping between students and log in id
  // Define a type for studentLoginMap object
  const [studentLoginMap] = React.useState<{ [studentId: number]: number }>({});

  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    setShowModal(true);
  };

  // set up the mappings needed for changing groups
  const mapUser = (user: any) => {
    return {
      ...user,
      key: user.key ?? user.userId,
      realName: user.realName || '',
      trainingGroup: user.trainingGroup || '',
      displayName: user.displayName || '',
      uniqueId: user.uniqueId || '',
      gender: user.gender || '',
      phone: user.phone || '',
      email: user.email || '',
      wechatId: user.wechatId || '',
      education: user.education || '',
      provinceCity: user.provinceCity || '',
      yearsAsChristian: user.yearsAsChristian || '',
      careExperience: user.careExperience || '',
      trainingExpectation: user.trainingExpectation || ''
    };
  };

  const processTrainingDetailResponse = async (trainingDetailResponse: any) => {
    const { groups } = trainingDetailResponse.data;
    // Remap the group IDs because we need them later
    const groupsInfoPromises = groups.map(async (group: IGroup) => {
      const groupInfo = await _dataProvider.getGroupInfo(group.groupId);
      return { ...groupInfo, groupId: group.groupId }; // Include groupId in the returned data
    });
    const groupsInfo = await Promise.all(groupsInfoPromises);
    console.log('take a look at info:', groupsInfo);
    // Set the group mapping state
    // between groups to their groupids
    const mappings = new Map<string, number>();
    groupsInfo.forEach((group) => {
      mappings.set(group.name, group.groupId);
    });
    console.log('Here is the group mappings: ', mappings);
    setGroupMappings(mappings);
    // Setup the student to their groups mappings
    // This will tell us what group the student is in and the admin status
    const newStudentGroupMap: {
      [studentId: number]: {
        userName: string;
        groupId: number;
        groupName: string;
        isAdmin: boolean;
        loginId: string;
      }[];
    } = {};
    groupsInfo.forEach((group) => {
      group.users.forEach((user: any) => {
        const isAdmin = user.isGroupLeader === 1; // Convert isAdmin flag to boolean
        if (!newStudentGroupMap[user.userId]) {
          newStudentGroupMap[user.userId] = [];
        }
        studentLoginMap[user.userId] = user.loginId;
        newStudentGroupMap[user.userId].push({
          userName: user.name,
          groupId: group.groupId, // Include groupId in the mapping
          groupName: group.name,
          isAdmin: isAdmin,
          loginId: user.loginId
        });
      });
    });
    setStudentGroupMap(newStudentGroupMap);
    console.log('Map of studentids to their login ids', studentLoginMap);
    console.log('Map of students to their courses: ', newStudentGroupMap);
    console.log('Student group map state:', studentGroupMap);
    // Set up the counts of admins within groups
    const newGroupAdminCountMap: { [groupName: string]: number } = {};
    groupsInfo.forEach((group) => {
      const adminsCount = group.users.filter((user: any) => user.isGroupLeader === 1).length;
      newGroupAdminCountMap[group.name] = adminsCount;
    });

    setGroupAdminCountMap(newGroupAdminCountMap);
    console.log('Groups admin counts: ', newGroupAdminCountMap);
    let studentList: any[] = [];
    if (!_dataProvider.moreUserDataForOrg()) {
      //the org doesn't have more user data
      if (groupId) {
        const groupInfo = groupsInfo.find((gp) => gp.groupId === groupId);
        studentList = groupInfo.users.map((uu: any) => {
          return {
            ...mapUser(uu),
            trainingGroup: groupInfo.name,
            displayName: uu.name,
            uniqueId: uu.loginId
          };
        });
      } else {
        // concat all the users
        groupsInfo.forEach((groupInfo) => {
          studentList = studentList.concat(
            groupInfo.users.map((uu: any) => {
              return {
                ...mapUser(uu),
                trainingGroup: groupInfo.name,
                displayName: uu.name,
                uniqueId: uu.loginId
              };
            })
          );
        });
        // need to dedup since same user might be in multiple groups
        studentList = studentList.sort((au, bu) => au.userId - bu.userId);
        studentList = studentList.filter((uu, idx, ary) => !idx || uu.userId != ary[idx - 1].userId);
        studentList = studentList.sort((au, bu) => au.trainingGroup.localeCompare(bu.trainingGroup));
      }
    } else {
      if (groupId) {
        console.log('selected groupid', groupId);
        _dataProvider.fetchStudentsForGroup(groupId, trainingId).then((response) => {
          //console.log('gotten response:', response);
          studentList = response.map((user: any) => ({
            key: user.key,
            realName: user.realName || '',
            trainingGroup: user.trainingGroup || '',
            displayName: user.displayName || '',
            uniqueId: user.uniqueId || '',
            gender: user.gender || '',
            phone: user.phone || '',
            email: user.email || '',
            wechatId: user.wechatId || '',
            education: user.education || '',
            provinceCity: user.provinceCity || '',
            yearsAsChristian: user.yearsAsChristian || '',
            careExperience: user.careExperience || '',
            trainingExpectation: user.trainingExpectation || ''
          }));
        });
      } else {
        console.log('studentGroupMap Test:', studentGroupMap);
        if ('data' in trainingDetailResponse) {
          const users = await _dataProvider.getTrainingAllUsers(trainingId);
          studentList = users.map((user) => {
            const userGroupInfo = newStudentGroupMap[user.key];
            console.log('info obtained,', userGroupInfo);
            if (userGroupInfo) {
              return {
                ...user,
                trainingGroup: userGroupInfo[0].groupName,
                displayName: userGroupInfo[0].userName,
                uniqueId: userGroupInfo[0].loginId
              };
            }
            return user;
          });
        }
      }
    }
    setAllShowStudents(studentList);
    setStudents(studentList);
    updateStudents(studentList);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      // we need information for all of the groups regardless
      const trainingDetailResponse = await _dataProvider.fetchTrainingDetail(trainingId);
      if ('data' in trainingDetailResponse) {
        processTrainingDetailResponse(trainingDetailResponse);
      }
    };
    fetchData();
  }, [groupId, trainingId]);

  // keeping track of which row is being hovered
  const [hoveredRowId, setHoveredRowId] = React.useState<number | null>(null);
  // tracking of which person is being selected
  const [selectedStudent, setSelectedStudent] = React.useState<IStudent | null>(null);
  // Define state for isHovered
  //const [isHovered, setIsHovered] = React.useState(false);

  // Define handleMouseEnter and handleMouseLeave functions
  const handleMouseEnter = (rowId: number) => {
    setHoveredRowId(rowId);
  };

  const handleMouseLeave = () => {
    setHoveredRowId(null);
  };

  const genericColumns: IColumn[] = [
    {
      key: 'column1',
      name: i18n.t('studentOverViewList.RealName'),
      fieldName: 'realName',
      minWidth: 32,
      maxWidth: 32,
      isResizable: true
    },
    {
      key: 'column1.1',
      name: i18n.t('studentOverViewList.UniqueId'),
      fieldName: 'uniqueId',
      minWidth: 32,
      maxWidth: 32,
      isResizable: true
    },
    {
      key: 'column2',
      name: i18n.t('studentOverViewList.TrainingGroup'),
      fieldName: 'trainingGroup',
      minWidth: 64,
      maxWidth: 64,
      isResizable: true
    },
    {
      key: 'column3',
      name: i18n.t('studentOverViewList.DisplayName'),
      fieldName: 'displayName',
      minWidth: 48,
      maxWidth: 48,
      isResizable: true
    },
    {
      key: 'column4',
      name: i18n.t('studentOverViewList.Gender'),
      fieldName: 'gender',
      minWidth: 32,
      maxWidth: 32,
      isResizable: true
    },
    {
      key: 'column5',
      name: i18n.t('studentOverViewList.Phone'),
      fieldName: 'phone',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true
    },
    {
      key: 'column6',
      name: i18n.t('studentOverViewList.Email'),
      fieldName: 'email',
      minWidth: 116,
      maxWidth: 116,
      isResizable: true
    }
  ];

  const extraColumns: IColumn[] = [
    {
      key: 'column7',
      name: i18n.t('studentOverViewList.WechatId'),
      fieldName: 'wechatId',
      minWidth: 78,
      maxWidth: 78,
      isResizable: true
    },
    {
      key: 'column8',
      name: i18n.t('studentOverViewList.Education'),
      fieldName: 'education',
      minWidth: 56,
      maxWidth: 56,
      isResizable: true
    },
    {
      key: 'column9',
      name: i18n.t('studentOverViewList.ProvinceCity'),
      fieldName: 'provinceCity',
      minWidth: 64,
      maxWidth: 164,
      isResizable: true
    },
    {
      key: 'column10',
      name: i18n.t('studentOverViewList.YearsAsChristian'),
      fieldName: 'yearsAsChristian',
      minWidth: 32,
      maxWidth: 32,
      isResizable: true
    },
    {
      key: 'column11',
      name: i18n.t('studentOverViewList.CareExperience'),
      fieldName: 'careExperience',
      minWidth: 136,
      maxWidth: 136,
      isResizable: true,
      onRender: (item: IStudent) => (
        <div className='multiline-column' title={item.careExperience}>
          {item.careExperience}
        </div>
      )
    },
    {
      key: 'column12',
      name: i18n.t('studentOverViewList.TrainingExpectations'),
      fieldName: 'trainingExpectations',
      minWidth: 144,
      maxWidth: 144,
      isResizable: true,
      onRender: (item: IStudent) => (
        <div className='multiline-column' title={item.trainingExpectation}>
          {item.trainingExpectation}
        </div>
      )
    }
  ];
  const columns = _dataProvider.moreUserDataForOrg() ? genericColumns.concat(extraColumns) : genericColumns;

  const generateClassChangeBox = () => {
    if (!selectedStudent) {
      return null; // If selectedStudent is null, don't render anything
    }
    const boxContent = Array.from(groupMappings.keys()).map((groupName) => {
      const groupsForStudent = studentGroupMap[selectedStudent!.key] || []; // Get groups for the selected student
      //console.log('here are the selected students groups:', groupsForStudent);
      const isInGroup = groupsForStudent.some((group) => group.groupName === groupName); // Check if selected student is in this group

      return (
        <div key={groupName} style={{ margin: '5px 0', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          {isInGroup ? (
            // Show text if the selected student is in the group
            <span>{groupName}</span>
          ) : (
            // Show button to join if the selected student is not in the group
            <DefaultButton onClick={() => handleJoinGroup(groupName)}>
              {i18n.t('Join Group')} {groupName}
            </DefaultButton>
          )}
        </div>
      );
    });

    const IDigestLogo = getOrgLogo(_dataProvider.getOrgId());
    return (
      showModal && (
        <Dialog
          hidden={false}
          onDismiss={handleClose}
          dialogContentProps={{
            showCloseButton: true
          }}
          modalProps={{
            isBlocking: false,
            styles: {}
          }}>
          <img alt='iDigestApp' className='centeriDigestLogo' src={IDigestLogo} />
          <br />
          {i18n.t('Group Names')}
          {boxContent}
        </Dialog>
      )
    );
  };

  // whether or not the student is the only admin of a group
  // check if student can leave either the group chosen or the first group that he/she is in
  const studentCanLeave = (studentId: number): boolean => {
    // Get the groups that the student is in
    const studentGroups = studentGroupMap[studentId] || [];
    if (groupId) {
      // look for the group
      // Check if any group has <= 1 admin
      for (const group of studentGroups) {
        const adminsCount = groupAdminCountMap[group.groupName] || 0;
        // check if the student is an admin of that group
        // and that group only has 1 admin
        if (adminsCount <= 1 && group.isAdmin && group.groupId == groupId) {
          return false;
        }
      }
    } else {
      const group = studentGroups[0];
      const adminsCount = groupAdminCountMap[group.groupName] || 0;
      if (adminsCount <= 1 && group.isAdmin) {
        return false;
      }
    }
    // If all groups have more than 1 admin, return true
    console.log('student can leave');
    return true;
  };

  // reduce the admin counts when student is removed from groups
  // also send post request to backend to remove the student from the group
  const decrementAdminCount = async (studentId: number): Promise<void> => {
    const studentGroups = studentGroupMap[studentId] || [];
    // find the group id that we need to remove
    const grouptoremove = groupId ? groupId : studentGroups[0].groupId;
    const apiCalls = [];
    if (grouptoremove) {
      apiCalls.push(_dataProvider.deleteFromGroup(grouptoremove, studentId));
    }
    try {
      const responses = await Promise.all(apiCalls);
      // Check if all API calls were successful
      const allSuccessful = responses.every((response) => response);
      if (allSuccessful) {
        setGroupAdminCountMap((prevAdminCountMap) => {
          const updatedAdminCountMap = { ...prevAdminCountMap };
          studentGroups.forEach((group: any) => {
            if (group.isAdmin && group.groupId == grouptoremove) {
              updatedAdminCountMap[group.groupName] = (updatedAdminCountMap[group.groupName] || 0) - 1;
            }
          });
          console.log('old admin count:', prevAdminCountMap);
          console.log('new admin count:', updatedAdminCountMap);
          return updatedAdminCountMap;
        });
      } else {
        alert('Failed to remove student from some groups.');
      }
    } catch (error) {
      console.error('Error processing API calls:', error);
      alert('Failed to remove student from some groups.');
    }
  };

  // add student to the group while remove the student from other groups that he is in
  // additionally, update the admins count
  const addGroupAndRemoveOthers = async (groupNameToAdd: string): Promise<void> => {
    if (selectedStudent != null) {
      const studentGroups = studentGroupMap[selectedStudent?.key] || [];
      const grouptoremove = groupId ? groupId : studentGroups[0].groupId;
      try {
        // First, make the API call to add the user to the group
        const response = await _dataProvider.addUserToGroup(
          groupMappings.get(groupNameToAdd) ?? 0,
          studentLoginMap[selectedStudent.key].toString()
        );
        // Check if the API call was successful
        if (response) {
          // Update admin counts
          // decrement admin count will also remove studentsfrom their previous groups
          await decrementAdminCount(selectedStudent.key);
          // Update local state (studentGroupMap) only if the API call and admin count update were successful
          setStudentGroupMap((prevMap) => {
            const newMap = { ...prevMap };
            const groupIdToAdd = groupMappings.get(groupNameToAdd);
            if (groupIdToAdd !== undefined) {
              const existingGroups = newMap[selectedStudent.key] || [];
              const updatedGroups = existingGroups.filter((group) => group.groupId !== grouptoremove);
              const newGroup = {
                userName: prevMap[selectedStudent.key][0].userName,
                groupId: groupIdToAdd,
                groupName: groupNameToAdd,
                isAdmin: false
              };
              updatedGroups.push(newGroup);
              newMap[selectedStudent.key] = updatedGroups;
              console.log('old student group map:', prevMap);
              console.log('new student group map:', newMap);
            } else {
              console.error(`Group ID not found for group name: ${groupNameToAdd}`);
            }
            return newMap;
          });
          console.log(`Added student ${selectedStudent.key} to group ${groupNameToAdd}`);
          // third, update setstudent
          updateStudentTrainingGroup(selectedStudent.key, groupNameToAdd);
        } else {
          alert('failed to change class');
          console.error(
            `Failed to add student ${selectedStudent.key} to group ${groupNameToAdd}, the login is ${
              studentLoginMap[selectedStudent.key]
            }
          `
          );
        }
      } catch (error) {
        console.error('Error adding student to group:', error);
      }
    }
  };

  // update students to be shown on the table
  const updateStudentTrainingGroup = (key: number, groupName: string): void => {
    setAllShowStudents((prevStudents) => {
      const updatedStudents = [...prevStudents];
      //console.log('students set:', updatedStudents);
      const studentIndex = updatedStudents.findIndex((student) => student.key === key);
      console.log('indexFound:', studentIndex);

      if (studentIndex !== -1) {
        if (groupId) {
          console.log('removing student as shown');
          updatedStudents.splice(studentIndex, 1);
        } else {
          updatedStudents[studentIndex] = {
            ...updatedStudents[studentIndex],
            trainingGroup: groupName
          };
        }
      } else {
        console.error(`Student with key ${key} not found.`);
      }
      // update the students that we show based off of query
      const filteredStudents = updatedStudents.filter(
        (student) =>
          student.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          student.realName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          student.uniqueId.toLowerCase().includes(searchQuery.toLowerCase()) ||
          student.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
      console.log('query:', searchQuery);
      setStudents(filteredStudents);
      return updatedStudents;
    });
  };

  const handleJoinGroup = (groupName: string) => {
    // Check if selectedStudent exists and has a key before calling studentCanLeave
    if (selectedStudent && selectedStudent.key !== undefined && studentCanLeave(selectedStudent.key)) {
      console.log('the students login id is: ', studentLoginMap[selectedStudent.key]);
      // can leave
      alert(`
      ${i18n.t('JoiningGroup.Student')} ${selectedStudent?.realName} ${i18n.t(
        'JoiningGroup.JoiningGroup'
      )} ${groupName}`);
      // first, add the new group to the student's group mappings
      // second, remove the old groups of the student while managing admins count
      addGroupAndRemoveOthers(groupName);
      // fourth, close the box
      handleClose();
    } else {
      // Handle the case where selectedStudent or its key is undefined
      alert(`${selectedStudent?.realName} ${i18n.t('Cannot Join')} ${groupName}, ${i18n.t('Admin Issues')}`);
    }
  };

  const onRenderItemColumn = (item?: IStudent, index?: number, column?: IColumn) => {
    if (!column || !item) {
      return null;
    }

    // for testing only
    React.useEffect(() => {
      console.log('Selected student:', selectedStudent);
    }, [selectedStudent]);

    // for setting the student when selected change group
    const changeGroupButtonClick = () => {
      // sometimes, backend grabs nothing for the student id, we need to crawl it
      const itemDisplayName = item.displayName;
      const matchedStudentId = Object.keys(studentGroupMap).find(
        (studentId) => studentGroupMap[parseInt(studentId, 10)][0]?.userName === itemDisplayName
      );
      if (matchedStudentId !== undefined) {
        const updatedItem = { ...item, key: parseInt(matchedStudentId, 10) };
        setSelectedStudent(updatedItem);
        handleShow();
      } else {
        console.error(`No student found with displayName ${itemDisplayName}`);
      }
    };
    const fieldContent = item[column.fieldName as keyof IStudent] as string;
    // Custom rendering for the trainingGroup column
    if (column.key === 'column2') {
      return (
        <div className={`cell-content ${column.fieldName}`} title={fieldContent}>
          <div onMouseEnter={() => handleMouseEnter(index!)} onMouseLeave={handleMouseLeave}>
            {hoveredRowId === index ? (
              <DefaultButton onClick={changeGroupButtonClick} className='changeGroupHoverButton'>
                {i18n.t('ChangeGroup')}
              </DefaultButton>
            ) : (
              <span>{fieldContent}</span>
            )}
          </div>
        </div>
      );
    }
    // Default rendering for other columns
    return (
      <div className={`cell-content ${column.fieldName}`} title={fieldContent}>
        {fieldContent}
      </div>
    );
  };

  const onRenderRow = (props?: IDetailsRowProps): JSX.Element | null => {
    if (!props) {
      return null;
    }

    // This allowed different background color for each row
    const customStyles = {
      root: {
        backgroundColor: props.itemIndex % 2 === 0 ? 'rgb(243, 242, 241);' : 'rgb(218 216 216)'
      }
    };

    return <DetailsRow {...props} styles={customStyles} />;
  };

  const [searchQuery, setSearchQuery] = React.useState('');

  const searchBoxChange = (value: string) => {
    const trimmedValue = value.trim().toLowerCase(); // Trim the whitespace from the search query
    setSearchQuery(trimmedValue);

    const filteredStudents = allShowStudents.filter(
      (student) =>
        student.displayName.toLowerCase().includes(trimmedValue) ||
        student.realName.toLowerCase().includes(trimmedValue) ||
        student.uniqueId.toLowerCase().includes(trimmedValue) ||
        student.email.toLowerCase().includes(trimmedValue)
    );

    setStudents(filteredStudents);
  };

  const searchBoxCleared = () => {
    setSearchQuery('');
    setStudents(allShowStudents);
  };

  return (
    <div className='studentOverviewList'>
      <SearchBox
        id='searchInput'
        placeholder={i18n.t('SearchStudents')}
        onEscape={() => searchBoxChange('')}
        onClear={searchBoxCleared}
        onChange={(ev, value) => searchBoxChange(value || '')}
        onSearch={() => searchBoxChange(searchQuery)}
        showIcon
      />
      <DetailsList
        items={students}
        columns={columns}
        setKey='set'
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={0}
        ariaLabelForSelectionColumn='Toggle selection'
        ariaLabelForSelectAllCheckbox='Toggle selection for all items'
        onRenderItemColumn={onRenderItemColumn}
        onRenderRow={onRenderRow}
      />
      {generateClassChangeBox()}
    </div>
  );
};

export default StudentOverviewList;
