import React from 'react';

import './Org.css';
//import { IAppSessionSettings } from "../dataProvider/IAppSessionSettings";

import { DefaultButton } from '@fluentui/react/lib/Button';
import { SearchBox, ISearchBoxStyles } from '@fluentui/react/lib/SearchBox';

import { _dataProvider } from '../dataProvider/DataProvider';
import OrgLeader from '../img/icon-Org-leader.png';
import groupLeader from '../img/icon-Group-leader.png';
import groupMember from '../img/icon-Group-member-regular.png';
import checkMark from '../img/icon-checkmark.png';
import _ from 'lodash';

const colorArray = ['yellow', 'green', 'blue', 'red'];
const textColorArray = ['black', 'white', 'white', 'white'];

// @ts-ignore
// eslint-disable-next-line
Date.prototype.addDays = function(days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

// @ts-ignore
const past9W = new Date().addDays(-63);

interface IOrgDetailProps {
  org: any;
  leaderGroup: any;
  headerHeight: number;
}

interface IOrgDetailState {
  groups: any;
  users: any;
  specificgroup: string;
  previousId: any;
  loading: boolean;
  tabIndex: any;
  currentOrg: any;
  memberGroups: any[];
  memberUsers: any[];
  _inDanger: number;
  _welcomeNew: number;
  weekly: boolean;
  activeOnly: boolean;
  contentHeight: string;
}

class OrgDetail extends React.Component<IOrgDetailProps, IOrgDetailState> {
  private _sortedAllDates: any;
  private _userAttendanceByDate: any;
  private _allDatesD: any;
  private _allDatesW: any;
  private _rowsD: any;
  private _rowsW: any;
  private _activeGroups: any;

  constructor(props: IOrgDetailProps) {
    super(props);
    this._userAttendanceByDate = undefined; //: any;
    this._allDatesD = undefined; //:any;
    this._allDatesW = undefined; //:any;
    this._rowsD = undefined; //:any;
    this._rowsW = undefined; //:any;
    this._activeGroups = {};

    this.state = {
      groups: [],
      users: [],
      specificgroup: '',
      previousId: null,
      loading: true,
      tabIndex: 0,
      currentOrg: undefined,
      memberGroups: [],
      memberUsers: [],
      _inDanger: 0,
      _welcomeNew: 0,
      weekly: true,
      activeOnly: false,
      contentHeight: `${window.innerHeight - 52}px`
    };
  }

  updateDimensions = () => {
    if (!document || !document.getElementById('idHeaderMenuBar')) {
      return;
    }
    const contentHeight = `${window.innerHeight - document.getElementById('idHeaderMenuBar')!.offsetHeight - 10}px`;
    this.setState({ contentHeight });
  };

  /**
   * Gather data from DataProvider.tsx and set states to be used to display
   * @returns void
   */
  async load() {
    let { groups, users } = await _dataProvider.getAttendanceData(this.props.org.Id);
    const memberGroups = await _dataProvider.getOrgGroupList(this.props.org.Id);
    memberGroups.forEach((item: any) => {
      if (this.props.leaderGroup.find((lItem: any) => lItem.Id === item.GroupId) !== -1) {
        item.isLeader = true;
      }
    });

    if (groups === undefined) {
      groups = [];
    }
    if (users === undefined) {
      users = [];
    }
    this.setState({
      groups,
      users,
      memberGroups,
      loading: false,
      currentOrg: this.props.org.Name
    });
    this.changeButtonColor('groupssummary');

    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  public async componentDidMount(): Promise<void> {
    this.load();
  }

  public getDateString(date: string) {
    const d = new Date(date);
    return `${d.getFullYear()}/${('0' + (d.getMonth() + 1)).slice(-2)}/${('0' + d.getDate()).slice(-2)}`;
  }

  public getDateString2(d: Date) {
    return `${d.getFullYear()}/${('0' + (d.getMonth() + 1)).slice(-2)}/${('0' + d.getDate()).slice(-2)}`;
  }

  public getGroupAttendanceCount(group: any[], date: string) {
    const index = group.findIndex((item) => this.getDateString(item.date) === this.getDateString(date));
    if (index === -1) {
      return '';
    }

    return group[index].users.length;
  }

  /*
  compute weekly summary and daily summary
*/
  private computeSummary(weekly: boolean) {
    const { groups } = this.state;

    if (!this._allDatesD) {
      this._allDatesD = {};
      this._rowsD = [];

      // below gets the daily
      Object.keys(groups).forEach((name) => {
        groups[name].forEach((item: { date: string }) => {
          this._allDatesD[this.getDateString(item.date)] = 0;
        });
      });

      this._sortedAllDates = Object.keys(this._allDatesD).sort().reverse();

      Object.keys(groups)
        .sort()
        .forEach((name) => {
          const data: any[] = [];
          this._sortedAllDates.forEach((date: string) => {
            const count = this.getGroupAttendanceCount(groups[name], date);
            if (typeof count === 'number') {
              this._allDatesD[date] += count;
              const thisD = new Date(date);
              if (thisD > past9W) {
                this._activeGroups[name] = true;
              }
            }
            data.push(count);
          });
          this._rowsD.push({ name, data });
        });
    }

    //compute weekly
    if (weekly && !this._allDatesW) {
      this._allDatesW = {};
      this._rowsW = [];

      // need to find the first Sunday from this._sortedallDates[0], and going backwards until no more
      // and that would construct the _allDatesW ---
      const sunD = new Date(this._sortedAllDates[0]);

      //this gets the first Sunday
      sunD.setDate(sunD.getDate() - sunD.getDay());

      let startD = sunD;
      let sunDString = this.getDateString2(startD);
      this._allDatesW[sunDString] = 0;

      this._rowsD.forEach((rowD: any, rowsDIdx: number) => {
        const data: any = [];
        let count = 0;

        this._sortedAllDates.forEach((date: string, index: number) => {
          const tD = new Date(date);
          /// if a new week needs to be started, and address week gaps in the record
          if (tD.getTime() < startD.getTime()) {
            while (tD.getTime() < startD.getTime()) {
              startD.setDate(startD.getDate() - 7);
            }
            if (rowsDIdx === 0) {
              sunDString = this.getDateString2(startD);
              this._allDatesW[sunDString] = 0;
            }
            data.push(count ? count : '');

            //reset count
            count = 0;
          }
          if (rowsDIdx === 0) {
            this._allDatesW[sunDString] += this._allDatesD[date];
          }

          if (typeof rowD['data'][index] === 'number') {
            count += rowD['data'][index];
          }
        });
        data.push(count ? count : '');

        this._rowsW.push({ name: rowD['name'], data });

        startD = new Date(this._sortedAllDates[0]);
        startD.setDate(startD.getDate() - startD.getDay());
        //sunD; //reset the data from beginning
        //this._rowsW.push({rowD[name], data});
      });
    }
  }

  public renderByGroupsSummary() {
    this.computeSummary(this.state.weekly);

    const allDates = this.state.weekly ? this._allDatesW : this._allDatesD;
    const rows = this.state.weekly ? this._rowsW : this._rowsD;

    // Do the week days need sorting -- the object properties were added sequentially already, we'll see
    const sortedAllDates = this.state.weekly ? Object.keys(this._allDatesW) : this._sortedAllDates;
    return (
      <div className='responsiveDiv' style={{ height: this.state.contentHeight }}>
        <table id='attendance'>
          <thead>
            <tr>
              <th>
                <b>
                  <SearchBoxGroups></SearchBoxGroups>
                </b>
              </th>
              {sortedAllDates.map((date: string, index: number) => (
                <th key={index++}>{date}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: '10px' }}>
              <th style={{ color: 'black' }}>
                <input
                  style={{ marginLeft: '15px' }}
                  type='checkbox'
                  value='Active'
                  id='Active'
                  checked={this.state.activeOnly}
                  onChange={() => this.setState({ activeOnly: !this.state.activeOnly })}
                  name='Weekly'
                />
                <label htmlFor='Active'>Active</label>
                <input
                  style={{ marginLeft: '15px' }}
                  type='checkbox'
                  value='Weekly'
                  id='Weekly'
                  checked={this.state.weekly}
                  onChange={() => this.setState({ weekly: !this.state.weekly })}
                  name='Weekly'
                />
                <label htmlFor='Weekly'>Weekly</label>
              </th>
              {sortedAllDates.map((date: string, index: number) => {
                return (
                  <th key={index++} style={{ backgroundColor: '#f2f2f2', color: 'black' }}>
                    <b style={{ fontWeight: 'bolder' }}>{allDates[date]}</b>
                  </th>
                );
              })}
            </tr>
            {rows
              .filter((rw: any) => this._activeGroups[rw.name])
              .map((row: any, index: number) => (
                <tr key={index++}>
                  <td>{row.name}</td>
                  {row.data.map((count: any) => {
                    return <td key={index++}>{count}</td>;
                  })}
                </tr>
              ))}
            {this.state.activeOnly && rows.filter((rw: any) => this._activeGroups[rw.name]).length === 0 && (
              <tr style={{ verticalAlign: 'top' }}>
                <td> No active groups </td>
              </tr>
            )}
            {!this.state.activeOnly &&
              rows
                .filter((rw: any) => !this._activeGroups[rw.name])
                .map((row: any, index: number) => (
                  <tr key={index++}>
                    <td>{row.name}</td>
                    {row.data.map((count: any) => {
                      return <td key={index++}>{count}</td>;
                    })}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    );
  }

  public renderByGroupsDetails() {
    const { groups, users } = this.state;

    this.computeUserState();

    let index = 0;
    const name = this.state.specificgroup;
    // get all users from all dates - axis Y
    const allUsers: { [index: string]: any } = {};
    if (!groups[name]) {
      return <div />;
    }

    groups[name].forEach((item: { allUsers: any[] }) => {
      item.allUsers.forEach((userId) => {
        allUsers[userId] = {};
      });
    });

    const uList = Object.keys(allUsers);

    uList.sort((u1: string, u2: string) => {
      //the users array might not have all users in allUsers==
      if (!users[u1]) {
        return -1;
      }
      if (!users[u2]) {
        return 1;
      }
      return users[u1].color - users[u2].color;
    });

    const groupData = _.cloneDeep(groups[name]).reverse();

    return (
      <div key={index++} className='responsiveDiv' style={{ height: this.state.contentHeight }}>
        <table id='attendance'>
          <thead>
            <tr>
              <th>
                <b>
                  <SearchBoxGroups></SearchBoxGroups>
                </b>
              </th>

              {groupData.map((item: any) => (
                <th key={index++}>{item.date}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              {groupData.map((item: { users: any[] }) => (
                <td key={index++}>
                  <b>
                    {
                      item.users.length
                      //  / groupData.length) * 100
                    }
                  </b>
                </td>
              ))}
            </tr>
            {uList.map((user) => {
              if (!users[user]) {
                return;
              } // skip deleted users??
              return (
                <tr key={index++}>
                  <td
                    style={{
                      backgroundColor: colorArray[users[user].color],
                      color: textColorArray[users[user].color]
                    }}>
                    {/* #{userIndex++} */}
                    {users[user].name}
                  </td>
                  {groupData.map((item: { users: number[] }) => {
                    return (
                      <td key={index++}>
                        {item.users.indexOf(parseInt(user)) === -1 ? (
                          ''
                        ) : (
                          <img alt='check mark' src={checkMark} style={{ width: '10px', height: '10px' }}></img>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {/* <tr>
                    <th>
                      <b>Total</b>
                    </th>
                    {groupData.map((item: { users: string | any[] }) => (
                      <th key={index++}>
                        <b>{item.users.length}</b>
                      </th>
                    ))}
                  </tr> */}
          </tbody>
        </table>
      </div>
    );
  }

  public computeUserState() {
    const { groups, users } = this.state;

    // get all dates and userAttendanceByDate[userId][date]
    if (this._userAttendanceByDate) {
      // computed once already
      return;
    }

    this._userAttendanceByDate = {};
    // get all dates and userAttendanceByDate[userId][date]
    Object.keys(users).forEach((userId) => {
      this._userAttendanceByDate[userId] = {};
    });
    Object.keys(groups).forEach((name) => {
      groups[name].forEach((item: { date: string; users: any[] }) => {
        item.users.forEach((userId: string | number) => {
          // it's possible a user might be out of the group ---
          if (this._userAttendanceByDate[userId]) {
            this._userAttendanceByDate[userId][this.getDateString(item.date)] = 1;
            this._userAttendanceByDate[userId].groupName = name;
          }
        });
      });
    });

    /* compute the user state --- in danger, need welcome, etc.
    /// algorithm ===
    /// Count3 === the total count of attendance of last 3 record
    /// Count5 === the total count of attendance of last 5 record
    /// those who need welcome / strengthening:  recent_3 > 0, recent_3 === tot
    ///     this would indicate this is a brand new user, joined in last 3 weeks
    /// those who are in danger of losing:  recent_3===0, but recent_5 > 0.
    /// those who are definitely lost: recent_5 === 0
    */
    // get most recent date of record

    const latestD = new Date(this._sortedAllDates[0]);
    // @ts-ignore
    const past3W = latestD.addDays(-21);
    // @ts-ignore
    const past5W = latestD.addDays(-35);

    //compute 3 weeks back
    //compute 5 weeks back
    Object.keys(users).forEach((userId) => {
      this._sortedAllDates.forEach((date: string) => {
        if (this._userAttendanceByDate[userId][date]) {
          /*
          if (index < 3) users[userId].count3 = (users[userId].count3 || 0) + 1;
          if (index < 5) users[userId].count5 = (users[userId].count5 || 0) + 1;
          */
          const thisD = new Date(date);
          if (thisD > past3W) {
            users[userId].count3 = (users[userId].count3 || 0) + 1;
          }
          if (thisD > past5W) {
            users[userId].count5 = (users[userId].count5 || 0) + 1;
          }
          users[userId].countTot = (users[userId].countTot || 0) + 1;
        }
      });
    });

    let { _inDanger, _welcomeNew } = this.state;
    _inDanger += 1;
    _welcomeNew += 1;

    Object.keys(users).forEach((userId) => {
      if (!users[userId].count5) {
        users[userId].color = 3;
      }
      //'lost';
      else if (!users[userId].count3) {
        users[userId].color = 0;
        _inDanger += 1;
        //'danger';
      } else if (users[userId].count3 === users[userId].countTot) {
        users[userId].color = 1;
        _welcomeNew += 1;
        //'welcome';
      } else {
        users[userId].color = 2;
      } //'regular';
    });
    this.setState({ _inDanger, _welcomeNew });
  }

  public renderByPersonDetails(tabIndex = 2) {
    const { users } = this.state;

    this.computeUserState();

    let index = 0;

    let listUser = Object.keys(users);
    if (tabIndex === 3) {
      listUser = listUser.filter((userId) => users[userId].color === 1);
    } else if (tabIndex === 4) {
      listUser = listUser.filter((userId) => users[userId].color === 0);
    }

    return (
      <div className='responsiveDiv' style={{ height: this.state.contentHeight }}>
        <table id='attendance'>
          <thead>
            <tr>
              <th>
                <b>
                  <SearchBoxGroups></SearchBoxGroups>
                </b>
              </th>
              {this._sortedAllDates.map((date: string) => (
                <th key={index++}>{date}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {listUser.map((userId) => (
              <tr key={index++}>
                <td
                  style={{
                    backgroundColor: colorArray[users[userId].color],
                    color: textColorArray[users[userId].color]
                  }}>
                  {/* #{index}  */}
                  {users[userId].name}
                </td>
                {this._sortedAllDates.map((date: string) => {
                  return (
                    <td key={index++}>
                      {!this._userAttendanceByDate[userId][date] ? (
                        ''
                      ) : (
                        <img alt='check mark' src={checkMark} style={{ width: '10px', height: '10px' }}></img>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  public exportMembers(tabIndex = 2) {
    const { users } = this.state;

    this.computeUserState();

    let listUser = Object.keys(users);
    console.log('export: ', listUser, users);
    if (tabIndex === 3) {
      listUser = listUser.filter((userId) => users[userId].color === 1);
    } else if (tabIndex === 4) {
      listUser = listUser.filter((userId) => users[userId].color === 0);
    }

    /*
    const data = '"Column One","Column Two","Column Three"';

    const rows = [
        ["name1", "city1", "some other info"],
        ["name2", "city2", "more info"]
    ];

    let csvContent = "data:text/csv;charset=utf-8," 
        + rows.map(e => e.join(",")).join("\n");
        */

    const userArray = listUser.map((userId) => [
      users[userId].name,
      users[userId].uniqueId ?? '',
      users[userId].loginId,
      this._userAttendanceByDate[userId].groupName,
      this._sortedAllDates.find((date: string) => !!this._userAttendanceByDate[userId][date])
    ]);
    const csvContent = 'Name, AcctID, Email, Group, Last Attendance\n' + userArray.map((e) => e.join(',')).join('\n');
    return csvContent;
  }

  /**
   * Highlight the button user clicks on
   * @param id is the id of each individual button passed in
   */
  public changeButtonColor(id: string) {
    if (this.state.previousId != null) {
      const myElement: HTMLElement = document.getElementById(this.state.previousId)!;
      myElement.style.backgroundColor = '#E8E8E8';
      myElement.style.color = 'black';
    }
    this.setState({ previousId: id });
    const newElement: HTMLElement = document.getElementById(id)!;

    //this seems can get null --- prevent exception for now...
    if (!newElement) {
      return;
    }
    newElement.style.backgroundColor = '#7E7E7E';
    newElement.style.color = 'white';
  }

  //Triggers a download of the given file
  public downloadFile(fileName: string, urlData: string) {
    const aLink = document.createElement('a');
    aLink.download = fileName;
    aLink.href = urlData;

    const event = new MouseEvent('click');
    aLink.dispatchEvent(event);
  }

  render(): React.ReactNode {
    const { tabIndex, loading } = this.state;
    if (loading) {
      return <h1>Loading...</h1>;
    }
    /*
     */
    return (
      <div style={{ marginTop: '10px' }}>
        <div className='table' style={{ height: this.state.contentHeight }}>
          <DefaultButton
            id='groupssummary'
            styles={{
              flexContainer: {
                justifyContent: 'left',
                marginLeft: 25
              }
            }}
            style={{
              textAlign: 'left',
              width: 160,
              border: 'none',
              backgroundColor: '#E8E8E8'
            }}
            onClick={() => {
              this.setState({ tabIndex: 0 });
              this.changeButtonColor('groupssummary');
            }}>
            <img id='OrgImage' alt='OrgLeader' src={OrgLeader}></img>
            {this.state.currentOrg.slice(0, 8)}
          </DefaultButton>
          <DefaultButton
            style={{ padding: 0, marginLeft: '6px' }}
            onClick={() => {
              //alert("ok");
              //const data = '"Column One","Column Two","Column Three"';
              this.downloadFile(
                'AllMembers.csv',
                'data:text/csv;charset=UTF-8,' + encodeURIComponent(this.exportMembers())
              );
            }}>
            Export All
          </DefaultButton>

          <ul style={{ padding: 0, marginLeft: '25px' }}>
            <DefaultButton
              styles={{
                flexContainer: {
                  justifyContent: 'left',
                  marginLeft: 25
                }
              }}
              style={{
                backgroundColor: '#E8E8E8',
                width: 225,
                border: 'none'
              }}
              id='persondetails'
              onClick={() => {
                this.setState({ tabIndex: 2 });
                this.changeButtonColor('persondetails');
              }}>
              <img id='GroupImage' src={OrgLeader} alt='Group Symbol'></img>
              <span style={{ position: 'absolute', right: 5 }}>{this.props.org.Size}</span>
              <span>All Members</span>
            </DefaultButton>
          </ul>
          <ul style={{ padding: 0, marginLeft: '25px' }}>
            <DefaultButton
              styles={{
                flexContainer: {
                  justifyContent: 'left',
                  marginLeft: 25
                }
              }}
              style={{
                backgroundColor: '#E8E8E8',
                width: 225,
                border: 'none',
                textAlign: 'left'
              }}
              id='welcomenew'
              onClick={() => {
                this.setState({ tabIndex: 3 });
                this.changeButtonColor('welcomenew');
              }}>
              <img id='GroupImage' src={OrgLeader} alt='Group Symbol'></img>
              <span style={{ position: 'absolute', right: 5 }}>
                {!this.state._welcomeNew ? '' : this.state._welcomeNew - 1}
              </span>
              <span>Welcome the new</span>
            </DefaultButton>
          </ul>
          <ul style={{ padding: 0, marginLeft: '25px' }}>
            <DefaultButton
              styles={{
                flexContainer: {
                  justifyContent: 'left',
                  marginLeft: 25
                }
              }}
              style={{
                backgroundColor: '#E8E8E8',
                width: 225,
                border: 'none',
                textAlign: 'left'
              }}
              id='strengthenweary'
              onClick={() => {
                this.setState({ tabIndex: 4 });
                this.changeButtonColor('strengthenweary');
              }}>
              <img id='GroupImage' src={OrgLeader} alt='Group Symbol'></img>
              <span style={{ position: 'absolute', right: 5 }}>
                {!this.state._inDanger ? '' : this.state._inDanger - 1}
              </span>
              <span>Strengthen the weary</span>
            </DefaultButton>
          </ul>
          {this.state.memberGroups
            .filter((mg) => mg.isLeader)
            .map((item, index) => (
              <ul key={index} style={{ padding: 0, marginLeft: '25px' }}>
                <DefaultButton
                  styles={{
                    flexContainer: {
                      justifyContent: 'left',
                      marginLeft: 25
                    }
                  }}
                  style={{
                    backgroundColor: '#E8E8E8',
                    width: 225,
                    border: 'none',
                    textAlign: 'left'
                  }}
                  id={item.GroupId}
                  onClick={() => {
                    this.setState({
                      tabIndex: 1,
                      specificgroup: item.GroupName
                    });
                    this.changeButtonColor(item.GroupId);
                  }}>
                  <img id='GroupImage' src={groupLeader} alt='Group Symbol'></img>
                  <span style={{ position: 'absolute', right: 5 }}>{item.Size || ' '}</span>
                  <span>{item.GroupName}</span>
                </DefaultButton>
              </ul>
            ))}
          {this.state.memberGroups
            .filter((mg) => !mg.isLeader)
            .map((item, index) => (
              <ul key={index} style={{ padding: 0, marginLeft: '25px' }}>
                <DefaultButton
                  styles={{
                    flexContainer: {
                      justifyContent: 'left',
                      marginLeft: 25
                    }
                  }}
                  style={{
                    backgroundColor: '#E8E8E8',
                    width: 225,
                    border: 'none',
                    textAlign: 'left'
                  }}
                  id={item.GroupId}
                  onClick={() => {
                    this.setState({
                      tabIndex: 1,
                      specificgroup: item.GroupName
                    });
                    this.changeButtonColor(item.GroupId);
                  }}>
                  <img id='GroupImage' src={groupMember} alt='Group Symbol'></img>
                  <span style={{ position: 'absolute', right: 5 }}>{item.Size || ' '}</span>
                  <span>{item.GroupName}</span>
                </DefaultButton>
              </ul>
            ))}
        </div>

        {tabIndex === 0 && <>{this.renderByGroupsSummary()}</>}
        {tabIndex === 1 && <>{this.renderByGroupsDetails()}</>}
        {tabIndex === 2 && <>{this.renderByPersonDetails()}</>}
        {tabIndex === 3 && <>{this.renderByPersonDetails(3)}</>}
        {tabIndex === 4 && <>{this.renderByPersonDetails(4)}</>}
      </div>
    );
  }
}

const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: { width: 180, height: 20 }
};

/**
 * For search bar use
 */
function filterFunction() {
  let filter, table, tr, td, i, txtValue;
  const input = document.getElementById('input');
  if (input !== null) {
    filter = (input as HTMLInputElement).value.toUpperCase();
    table = document.getElementById('attendance');
    if (table !== null) {
      tr = table.getElementsByTagName('tr');
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName('td')[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = '';
          } else {
            tr[i].style.display = 'none';
          }
        }
      }
    }
  }
}

export const SearchBoxGroups = () => (
  <SearchBox
    id='input'
    styles={searchBoxStyles}
    placeholder='Search'
    onEscape={() => filterFunction()}
    onClear={() => filterFunction()}
    onChange={() => filterFunction()}
    onSearch={() => filterFunction()}
  />
);

export default OrgDetail;
