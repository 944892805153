import React, { useState } from 'react';
import { DefaultButton, Dropdown, IDropdownOption } from '@fluentui/react';
import { Translation } from 'react-i18next';
import { _dataProvider } from '../dataProvider/DataProvider';
import { Checkbox } from '@fluentui/react';
import './Dialog.css';
import i18n from '../i18n';

interface ISignUpProps {
  close: () => void;
  signInOut: (isSignedIn: boolean, state?: any) => void;
}

const SignUp = (signupProps: ISignUpProps) => {
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleDisplayNameChange = (event: any) => {
    setDisplayName(event.target.value);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const handleAcceptedTerms = (event: any) => {
    setAcceptedTerms(event.target.checked);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (acceptedTerms) {
      try {
        await _dataProvider.signUp(displayName, email, password);
        const response = await _dataProvider.signIn(email, password);

        if (response.success) {
          const signInState = {
            loginId: email,
            ...response.data
          };

          signupProps.signInOut(true, { signInState: signInState, error: undefined }); // Update parent component state and close the modal
          signupProps.close(); // Close the sign-up form
        } else {
          console.error(response.error);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      alert('Please accept the terms and conditions.');
    }
  };

  const handleLanguageChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      i18n.changeLanguage(option.key as string);
    }
  };

  return (
    <Translation>
      {(t) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center', width: '300px' }}>
            <h2 className={'dialog-header'}>{t('CreateNewAccount')}</h2>
            <h5 className={'dialog-label'}>{t('userName')}</h5>
            <input
              name='displayName'
              placeholder={t('example')}
              value={displayName}
              onChange={handleDisplayNameChange}
              className={'dialog-box'}
            />
            <h5 className={'dialog-label'}>{t('Email')}</h5>
            <input
              name='email'
              type='email'
              placeholder={'12345@gmail.com'}
              value={email}
              onChange={handleEmailChange}
              className={'dialog-box'}
            />
            <h5 className={'dialog-label'}>{t('pwd')}</h5>
            <input
              name='password'
              type='password'
              value={password}
              onChange={handlePasswordChange}
              className={'dialog-box'}
            />
            <div style={{ margin: '20px 0', display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  name='agreeToTerms'
                  checked={acceptedTerms}
                  onChange={handleAcceptedTerms}
                  id='agreeToTerms'
                />
                <label htmlFor='agreeToTerms' style={{ marginLeft: '8px' }} className='tos-links'>
                  {t('IAgreeToThe')}
                  <a href='/#/AboutUs' target='_blank'>
                    {t('TermsOfService')}
                  </a>
                  &
                  <a href='/privacy-policy' target='_blank'>
                    {t('PrivacyPolicy')}
                  </a>
                </label>
              </div>
            </div>
            <DefaultButton
              type='submit'
              onClick={handleSubmit}
              primary
              styles={{
                root: {
                  backgroundColor: 'black',
                  color: 'white'
                }
              }}>
              {t('submit')}
            </DefaultButton>
            <h5 className={'dialog-label'}>{'Change language/更改语言'}</h5>
            <Dropdown
              className='dialog-dropdown'
              selectedKey={i18n.language}
              onChange={handleLanguageChange}
              options={[
                { key: 'eng', text: 'English' },
                { key: 'chs', text: '简体中文' },
                { key: 'cht', text: '繁體中文' }
              ]}
            />
          </div>
        </div>
      )}
    </Translation>
  );
};

export default SignUp;
