import React, { useEffect, useState } from 'react';
import { _dataProvider, IUser } from '../dataProvider/DataProvider';
import { Dialog } from '@fluentui/react/lib/Dialog';
import ChecklistContainer from './CheckListContainer';
import { getOrgLogo } from '../SharedCommon/OrgList';

export interface IPickUserProps {
  action: any;
  title: string;
}

export const PickUser: React.FC<IPickUserProps> = ({ action, title }) => {
  const [groupMembers, setGroupMembers] = useState<string[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    _dataProvider.getUserContacts().then((contacts) => {
      setUsers(contacts);
    });
  }, []);

  const handleSubmit = async () => {
    try {
      console.log('PickUser: ', groupMembers);
      groupMembers.forEach((mem) => {
        const user = users.find((uu) => uu.loginId === mem);
        if (user) {
          action(user);
        }
      });
      action(undefined);
    } catch (error) {
      alert('Failed');
    }
  };

  const IDigestLogo = getOrgLogo(_dataProvider.getOrgId());
  return (
    <Dialog
      hidden={false}
      onDismiss={() => {
        action(undefined);
      }}
      dialogContentProps={{
        showCloseButton: true
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { borderRadius: '20px' } }
      }}>
      <div style={{ textAlign: 'center' }}>
        <img alt='iDigestApp' className='centeriDigestLogo' src={IDigestLogo} />
        <br />
        <h2>{title}</h2>
        <div className='input-container'></div>
        <ChecklistContainer
          otherUsers={users}
          selectedUsers={groupMembers}
          setSelectedUsers={setGroupMembers}
          handleUpdate={handleSubmit}
          addMemberButtonText='PickUserAdd'
          pickSingleUser={true}
        />
      </div>
    </Dialog>
  );
};
