import React, { useState } from 'react';
import './ClassRegistration.css';
import { _dataProvider } from '../dataProvider/DataProvider';

interface ILoginProps {
  onSwitchToRegister: () => void;

  onLoginSuccess: () => void;
}

const Login: React.FC<ILoginProps> = ({
  /*   onSwitchToRegister,
  serverUrl, */
  onLoginSuccess
}) => {
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [, setError] = useState('');
  const [, setIsLoggedIn] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'loginId') {
      setLoginId(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleLogin = async () => {
    try {
      const result = await _dataProvider.signIn(loginId, password);
      if (result.success) {
        console.log('登录成功', result.data);
        onLoginSuccess();
        setIsLoggedIn(true);
        setLoginFailed(false);
      } else {
        if (result.error === 'UserNotFound') {
          setError(`小鸽子用户${loginId}已经报名这次培训。`);
        } else {
          setError(result.error);
        }
        setIsLoggedIn(false);
        setLoginFailed(true);
      }
    } catch (error) {
      setError(
        '登录失败，请输入正确的小鸽子用户ID（电子邮箱）和密码。如果你还不是小鸽子用户，请先在 https://idigest.app/install 安装手机app，注册用户。'
      );
      setIsLoggedIn(false);
      setLoginFailed(true);
    }
  };

  return (
    <div className='page-container'>
      <div className='form-container'>
        <div className='course-title'>小鸽子登录</div>
        <div className='registration-title'>
          如果你是新用户，请在移动端首先安装小鸽子手机app注册用户，之后再回到此处用小鸽子 ID和密码登录
        </div>
        <div className='input-container'>
          <label htmlFor='loginId' className='input-label'>
            小鴿子用戶名（電子郵箱）
          </label>
          <input
            id='loginId'
            type='email'
            name='loginId'
            placeholder='请输入电子邮箱'
            value={loginId}
            onChange={handleInputChange}
          />
        </div>
        <div className='input-container'>
          <label htmlFor='password' className='input-label'>
            密码
          </label>
          <p className='password-reset-instruction'>如果忘记密码，请在小鸽子App重新设置</p>
          <input
            id='password'
            type='password'
            name='password'
            placeholder='请输入密码'
            value={password}
            onChange={handleInputChange}
          />
        </div>
        <div className='login-buttons'>
          <button type='button' className='form-button' onClick={handleLogin}>
            登录
          </button>
        </div>
        {loginFailed && (
          <div className='login-error-message'>
            登录失败，请输入正确的小鸽子用户ID（电子邮箱）和密码。如果你还不是小鸽子用户，请先在
            https://idigest.app/install 安装手机app，注册用户
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
