import React, { useState, useEffect, useContext } from 'react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import './Org.css';
import DefaultProfilePicture from '../img/icon-DefaultProfilePicture.png';

import { _dataProvider, IUser } from '../dataProvider/DataProvider';
import { groupIcon, HostOfficialServer, _smallScreen } from '../SharedCommon/utils';
import { useTranslation } from 'react-i18next';
import { IPromptPickFilesInGroup } from './Dialogues';
import { SearchContext } from '../ISearchContextType';
import AddNewGroupWindow from './AddNewGroupWindow';
import AddNewContactWindow from './AddNewContactWindow';
import { ShowSearchBarContext } from '../ShowSearchBarContext';
import GroupMemberTool from './GroupMemberTool';
import { WebChat } from './WebChat';
import Editor from './editor';
import { RedDot } from '../SharedCommon/RedDot';
import { UserProfileDialog } from '../SharedCommon/UserProfile';

interface IGroupMgmtProps {
  signInState: boolean;
  pickgroup: any;
  HeadClickCount: number;
}

export interface IContactItem {
  displayName: string;
  loginId: string;
  userId: number;
  name: string;
}

const GroupMgmt = (props: IGroupMgmtProps) => {
  const [visitedGroups, setVisitedGroups] = useState<number[]>([]);
  const { searchQuery } = useContext(SearchContext);
  const { setPlaceholder } = useContext(ShowSearchBarContext);
  const [showFriend, setShowFriend] = useState<IUser>();

  // State for group list, loading status, selected group index, content height, and search query
  const [groupList, setGroupList] = useState<any[]>([]);
  const [contactList, setContactList] = useState<IContactItem[]>([]);
  const [pickFile, setPickFile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [idx, setGroup] = useState(-1); // group id
  const [currentContactId, setCurrentContactId] = useState(-1);
  const [contentHeight, setContentHeight] = useState(`${window.innerHeight - 52}px`);
  const [activeTab, setActiveTab] = useState('Chat');
  const { t } = useTranslation();
  const [showAddNewGroup, setShowAddNewGroup] = useState(false); // State to control the overlay
  const [showMemberManage, setMemberManage] = useState(false);
  const tabStyle = (isActive: boolean) => ({
    backgroundColor: isActive ? 'black' : 'white',
    color: isActive ? 'white' : 'black',
    cursor: isActive ? 'default' : 'pointer',
    marginRight: '5px',
    marginLeft: '5px',
    width: '10em',
    borderRadius: '25px',
    padding: '0.3em'
  });
  const currentUser = _dataProvider.getMyProfile();

  // Function to update content height based on window dimensions
  const updateDimensions = () => {
    if (!document || !document.getElementById('idHeaderMenuBar')) {
      return;
    }
    setContentHeight(`${window.innerHeight - document.getElementById('idHeaderMenuBar')!.offsetHeight - 10}px`);
  };

  // Function to load user groups and set up event listener for window resize
  const load = () => {
    Promise.all([_dataProvider.getUserGroups(), _dataProvider.getUserContacts()])
      .then(([groups, contacts]) => {
        setGroupList(groups ?? []);
        setContactList(contacts ? contacts.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName)) : []);
        setVisitedGroups([]);
      })
      .finally(() => {
        setLoading(false);
      });

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
  };

  // Call load function once when component mounts
  useEffect(load, []);

  useEffect(() => {
    //whenever header clicks, clear the state
    setGroup(-1);
    load();
  }, [props.HeadClickCount]);

  useEffect(() => {
    if (activeTab === 'Chat') {
      setPlaceholder('SearchTeams');
    } else {
      setPlaceholder('SearchContacts');
    }
  }, [activeTab, setPlaceholder]);

  // Function to add new contact to the contact list
  const addNewGroup = () => {
    setGroup(-1);
    setVisitedGroups([]);
    load();
  };

  // Function to check for URL flag
  const isContactTabEnabled = () => {
    return true;
    //const urlParams = new URLSearchParams(window.location.search);
    //return urlParams.get('enableContactTab') === '1';
  };

  if (!props.signInState) {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <h3 style={{ marginLeft: '15px', margin: '20px' }}>{t('pleaseSignin')}</h3>
      </div>
    );
  }

  const pickFile2Download = async (groupId: number, fileId: number): Promise<void> => {
    const remoteFile = _dataProvider.getFileUrlInChat(groupId, fileId);
    console.log('download: ', remoteFile);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = remoteFile;
    document.body.appendChild(iframe);

    //most of the time, picking something also dismiss the dialogue
    //but in this scenario, the user tends to download multiple files...
    //so keep the dialogue visible, and change the title somewhat, from
    //pick, to click to download the files you desire.
    //setPickFile(false);
  };

  const pickFile2Upload = () => {
    const inputF = document.createElement('input');
    inputF.setAttribute('type', 'file');
    inputF.setAttribute('accept', '*.*');
    inputF.setAttribute('multiple', 'multiple');

    inputF.onchange = async (e) => {
      const tg: any = e?.target;
      const fileList = Array.from(tg?.files);
      if (!fileList || fileList.length === 0) {
        return;
      }
      let success = true;
      for (const file of fileList) {
        const result = await _dataProvider.uploadFileWithChatMessage(file, (groupList[idx] as any).groupId);
        if (!result) {
          success = false;
        }
      }
      if (success) {
        alert(t('GroupScreen.UploadSuccess'));
      } else {
        alert('Network connection failed, try again');
      }
    };
    inputF.click();
    return true;
  };

  const groupDisplayName = (group: any) => {
    return group.name + ' ' + (group.isOneOnOneGroup ? _dataProvider.getMyTagForUser(group.friendUserId) : '');
  };

  const launchOneOnOneWithFriend = (friendId: number, introduction?: string, loginId = '') => {
    setCurrentContactId(friendId);
    const gIdx = groupList.findIndex((it) => {
      return it.isOneOnOneGroup && it.friendUserId === friendId;
    });
    if (gIdx >= 0) {
      setGroup(gIdx);
      if (introduction) {
        //send the message, the webchat will either get it at load, or receive the notification.
        _dataProvider.postGroupMessage(groupList[gIdx].groupId, introduction);
      }
      return;
    }
    _dataProvider.createOneOnOneGroup(friendId).then((newGroup) => {
      if (!newGroup) {
        console.log('1:1 group creation failed');
        return;
      }
      if (introduction) {
        //send the message, the webchat will either get it at load, or receive the notification.
        _dataProvider.postGroupMessage(newGroup.studyGroupId, introduction);
      }
      if (loginId) {
        const uIdx = contactList.findIndex((it) => it.userId === friendId);
        if (uIdx === -1) {
          //insert into contact list at top, render function does group
          const newContactList = [
            {
              name: `${newGroup.studyGroupName} (${loginId})`,
              displayName: newGroup.studyGroupName,
              userId: friendId,
              loginId: loginId //perhaps we should do a fetch
            },
            ...contactList
          ];
          setContactList(newContactList as IContactItem[]);
        }
      }
      const newGroupList = [
        {
          name: newGroup.studyGroupName,
          groupId: newGroup.studyGroupId,
          isOneOnOneGroup: true,
          friendUserId: friendId
        },
        ...groupList
      ];
      setGroupList(newGroupList);
      setGroup(0);
    });
  };

  // Function to display groups based on search query
  const displayGroups = () => {
    // Tab buttons above the list
    const tabButtons = (
      <div className='tabContainer' style={{ width: '90%', paddingRight: '5%' }}>
        <button
          onClick={() => {
            setGroup(-1); // close the chat window
            setActiveTab('Contact');
            setPlaceholder('SearchContacts');
          }}
          style={{ ...tabStyle(activeTab === 'Contact'), visibility: isContactTabEnabled() ? 'visible' : 'hidden' }}>
          {t('Contact')}
        </button>
        <button
          onClick={() => {
            setGroup(-1); // close the chat window
            setActiveTab('Chat');
            setPlaceholder('SearchTeams');
          }}
          style={tabStyle(activeTab === 'Chat')}>
          {t('Chat')}
        </button>
        <button onClick={() => setShowAddNewGroup(true)} className='plusButton'>
          +
        </button>
      </div>
    );

    // First, filter the contactList based on the search query
    const filteredContacts = contactList.filter((contact) => {
      return !searchQuery || (contact.name || contact.displayName).toLowerCase().includes(searchQuery.toLowerCase());
    });

    // Finally, group the sorted and filtered contacts by the first letter of displayName
    const groupedContacts = filteredContacts.reduce((groups, contact) => {
      const letter = contact.displayName[0].toUpperCase();
      if (!groups[letter]) {
        groups[letter] = [];
      }
      groups[letter].push(contact);
      return groups;
    }, {} as Record<string, IContactItem[]>);

    //menuProps={this.menuProps(idx)}

    const theGroup = groupList[idx];
    return (
      <div style={{ width: '100%', display: 'flex', marginTop: '10px' }}>
        {showAddNewGroup &&
          (activeTab === 'Chat' ? (
            <AddNewGroupWindow
              onClose={() => setShowAddNewGroup(false)}
              onAddGroup={addNewGroup}
              users={contactList.filter((user) => user.loginId !== currentUser.uniqueId)}
            />
          ) : (
            <AddNewContactWindow
              onClose={() => setShowAddNewGroup(false)}
              launchOneOnOneWithFriend={launchOneOnOneWithFriend}
            />
          ))}
        {/* Displaying groups */}
        <div className='TableOfContent fellowshipGroup' style={{ height: contentHeight }}>
          {tabButtons}
          {activeTab === 'Chat' ? (
            <div>
              {!groupList && <p>Server error, check network connection, and try again</p>}
              {loading && <p>{t('loading')}</p>}
              {!loading && groupList.length === 0 && <p> {t('NoGroup')}</p>}
              {groupList
                .filter((item) => {
                  return (
                    !searchQuery ||
                    groupDisplayName(item)
                      .toLowerCase()
                      .includes(searchQuery?.toLowerCase() ?? '')
                  );
                })
                .map((item, index) => (
                  <ul key={index} className='ulGroups'>
                    <DefaultButton
                      className='buttonFellowship'
                      style={{
                        backgroundColor: item === groupList[idx] ? 'lightgrey' : 'white'
                      }}
                      styles={{
                        flexContainer: {
                          justifyContent: 'left',
                          marginLeft: 25
                        }
                      }}
                      onClick={() => {
                        const idx = groupList.findIndex((gg) => gg === item);
                        setGroup(idx);
                        setVisitedGroups([item.groupId, ...visitedGroups]);
                        console.log(`Group set to ${groupList[idx]}`);
                      }}>
                      <img
                        id='OrgImage'
                        src={groupIcon(item)}
                        alt='Group Symbol'
                        className='groupSymbol'
                        onError={(e) => {
                          console.log('image error event: ', e);
                          (e.target as HTMLImageElement).src = DefaultProfilePicture;
                        }}
                      />
                      <div style={{ textAlign: 'left' }}>
                        {groupDisplayName(item)}
                        {item.newMentioned && visitedGroups.indexOf(item.groupId) === -1 && (
                          <p style={{ margin: 0, color: 'red' }}> {item.newMentioned && t('Mentioned')} </p>
                        )}
                      </div>
                      {item.newChatMessage && visitedGroups.indexOf(item.groupId) === -1 && <RedDot />}
                    </DefaultButton>
                  </ul>
                ))}
            </div>
          ) : (
            <div>
              {Object.keys(groupedContacts).map((letter) => (
                <div key={letter}>
                  {groupedContacts[letter]
                    .filter((user) => user.loginId !== currentUser.uniqueId)
                    .map((contact, index) => (
                      <ul key={index} className='ulGroups'>
                        <DefaultButton
                          className='buttonFellowship'
                          style={{
                            width: '85%',
                            backgroundColor: currentContactId === contact.userId ? 'lightgrey' : 'white'
                          }}
                          styles={{
                            flexContainer: {
                              justifyContent: 'left',
                              marginLeft: 25
                            }
                          }}
                          onClick={() => {
                            launchOneOnOneWithFriend(contact.userId);
                          }}>
                          <img
                            id='OrgImage'
                            src={`${HostOfficialServer}/user/avatar/${contact.userId}`}
                            alt='Group Symbol'
                            className='groupSymbol'
                            onError={(e) => {
                              console.log('image error event: ', e);
                              (e.target as HTMLImageElement).src = DefaultProfilePicture;
                            }}
                          />
                          {contact.name || contact.displayName}
                        </DefaultButton>
                        <DefaultButton
                          title='More'
                          ariaLabel='More'
                          key={1000 + index}
                          style={{
                            float: 'right',
                            border: '1px solid black',
                            width: '30px',
                            minWidth: '30px',
                            borderRadius: '50%',
                            padding: '20px'
                          }}
                          menuIconProps={{ iconName: 'More' }}
                          onClick={() => {
                            const user = contact as IUser;
                            user.tag = _dataProvider.getMyTagForUser(user.userId);
                            user.block = _dataProvider.getMyBlockForUser(user.userId);
                            console.log('contact dialog: ', user);
                            setShowFriend(user);
                          }}
                        />
                      </ul>
                    ))}
                </div>
              ))}
              {showFriend && <UserProfileDialog user={showFriend} dismiss={setShowFriend} />}
            </div>
          )}
        </div>
        {!_smallScreen && idx < 0 && groupList.length > 0 && (
          <p style={{ marginLeft: '20px', width: '240px' }}>{t('ClickLeftGroup')}</p>
        )}
        {/* Display additional content when a group is selected */}
        {idx >= 0 && (
          <div id='idEnclosingParent' className='BookContent' style={{ height: contentHeight, border: 'none' }}>
            <div id='idGroupActionButton' className='fellowshipActionDiv'>
              <button className='fellowshipAction' onClick={pickFile2Upload}>
                {t('uploadDocument')}
              </button>
              <button className='fellowshipAction' onClick={() => setPickFile(true)}>
                {t('downloadDocument')}
              </button>
              <button className='fellowshipAction' onClick={() => props.pickgroup((groupList[idx] as any).groupId)}>
                {t('JoinGroupCall')}
              </button>
              {!theGroup.isOrgGroup && !theGroup.isOneOnOneGroup && (
                <button
                  className='fellowshipAction'
                  onClick={() => setMemberManage(true)} // Open modal for the selected group
                >
                  {t('ManageMembers')}
                </button>
              )}
            </div>
            {showMemberManage && (
              <GroupMemberTool
                groupId={theGroup.groupId}
                isGroupLeader={theGroup.isGroupLeader}
                show={showMemberManage}
                onClose={() => setMemberManage(false)}
                contactList={contactList}
              />
            )}
            <WebChat height={contentHeight} groupId={theGroup.groupId} hadNewMessage={groupList[idx].newChatMessage} />
          </div>
        )}
        {!_smallScreen && idx >= 0 && (
          <Editor dataId='notes,0' contentHeight={contentHeight} headClass='fellowshipActionDiv' />
        )}
        {pickFile && idx >= 0 && (
          <IPromptPickFilesInGroup
            groupId={(groupList[idx] as any).groupId}
            processFile={pickFile2Download}
            onDismiss={() => {
              setPickFile(false);
            }}
          />
        )}
      </div>
    );
  };

  return displayGroups();
};

export default GroupMgmt;
