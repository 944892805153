import { _dataProvider } from '../dataProvider/DataProvider';
import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { DefaultButton } from '@fluentui/react/lib/Button';
import i18n from '../i18n';

interface IPasswordResetProps {
  close: () => void;
}

const NewPassword = (newPasswordProps: IPasswordResetProps) => {
  const [newPass, setNewPass] = useState('');
  const [confPass, setConfPass] = useState('');

  const handleNewPassChange = (event: any) => {
    setNewPass(event.target.value);
  };

  const handleConfPassChange = (event: any) => {
    setConfPass(event.target.value);
  };

  const handleSubmit = async () => {
    if (newPass === confPass) {
      try {
        const response = await _dataProvider.newPassword(newPass);
        if (response) {
          alert(i18n.t('PasswordSetSuccessfully'));
          newPasswordProps.close();
        } else {
          alert(i18n.t('PasswordUpdateError'));
        }
      } catch (error) {
        console.log('PasswordUpdateError', error);
        alert(i18n.t('PasswordUpdateError'));
      }
    } else {
      alert(i18n.t('PasswordsMustMatch'));
    }
  };

  return (
    <Translation>
      {(t) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <div style={{ width: '300px' }}>
            <h2 className={'dialog-header'}>{t('SetaNewPassword')}</h2>
            <h5 className={'dialog-label'}>{t('NewPassword')}</h5>
            <input
              name='newPass'
              type='password'
              placeholder={'**********'}
              value={newPass}
              onChange={handleNewPassChange}
              className={'dialog-box'}
            />
            <h5 className={'dialog-label'}>{t('ConfirmPassword')}</h5>
            <input
              name='confPass'
              type='password'
              placeholder={'**********'}
              value={confPass}
              onChange={handleConfPassChange}
              className={'dialog-box'}
            />
            <br />
            <br />
            <DefaultButton
              type='submit'
              onClick={handleSubmit}
              primary
              styles={{
                root: {
                  backgroundColor: 'black',
                  color: 'white'
                }
              }}>
              {t('submit')}
            </DefaultButton>
          </div>
        </div>
      )}
    </Translation>
  );
};

export default NewPassword;
