import { IBook, IBookTable } from '../dataProvider/IBookTable';
import { IBookContent } from '../dataProvider/IBookContent';
import { IAppSessionSettings } from '../dataProvider/IAppSessionSettings';
import { getJwtObj } from '../SharedCommon/utils';

import { _dataProvider } from '../dataProvider/DataProvider';
import { HostOfficialServer } from '../SharedCommon/utils';

import OwnerBooks from './OwnerBooks';
import WritingTableOfContent from './WritingTableOfContent';

import PhoneViewer from './PhoneViewer';
import ContentEditor from './ContentEditor';
//import LessonContentPreviewer from './LessonContentPreviewer';

import BibleNavigation from './BibleNavigation';

import JsonEditor from './JsonEditor';

import React from 'react';

import { phoneEmulatorTemplate } from '../SharedCommon/utils';
import i18n from '../i18n';

export interface IWritingBooksProps {
  saveSettings: any;
  signInState: boolean;
  settings: IAppSessionSettings;

  HeadClickCount: number;
  useEditor: number;
  preview: boolean;
}

interface IWritingBooksState {
  books: IBook[];
  bookTable?: IBookTable;
  bookContent?: IBookContent;
  bookNotes?: string;

  templateUri: string;
  bookIdx: number;
  chapterIdx: number;
  bibleData?: any;
  contentHeight: string;
  changeCount: number;
  authors: { userId: number; displayName: string }[];
}

interface IOrganization {
  id: number;
  name: string;
}

class WritingBooks extends React.Component<IWritingBooksProps, IWritingBooksState> {
  private sessionIdx: number;
  private merge: boolean;

  constructor(props: IWritingBooksProps) {
    super(props);

    this.merge = false;
    this.sessionIdx = 0; // sessionIdx;
    //this is taken out of state, because its change doesn't need to redraw from the whole WritingBooks level
    //when the user clicks between days of the same chapter, that's handled within template.html
    //but we save this value, so that we can restore between browser sessions.

    //todo: lang probably should be a settings --- but that will require a settings page later...
    this.state = {
      books: [],
      templateUri: phoneEmulatorTemplate,

      //todo: the folowing should be coming out of last session/settings
      contentHeight: `${window.innerHeight - 52}px`,

      bookIdx: -1, //bookIdx,  //number;
      chapterIdx: -1, //chapterIdx //: number;
      changeCount: 0,
      bibleData: undefined,
      authors: []
    };
  }

  private updateDimensions = () => {
    //this.setState({ width: window.innerWidth, height: window.innerHeight });
    console.log('WritingBooks updateDimensions: ', window.innerHeight);
    //this.setState({ contentHeight: `calc(100vh - ${this.props.headerHeight}px)`});
    this.setState({
      contentHeight: `${window.innerHeight - document!.getElementById('idHeaderMenuBar')!.clientHeight}px`
    });
  };

  public componentDidMount() {
    console.log('WritingBooks componentDidMount');
    if (_dataProvider.getOrgId()) {
      this.checkUserRole();
    }
    this.setState({
      contentHeight: `${window.innerHeight - document!.getElementById('idHeaderMenuBar')!.clientHeight}px`
    });
    window.addEventListener('resize', this.updateDimensions);
    this.load();
  }
  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public shouldComponentUpdate(nextProps: IWritingBooksProps) {
    console.log('WritingBooks shouldComponentUpdate');
    if (this.props.signInState !== nextProps.signInState) {
      if (!nextProps.signInState) {
        // signout
        const books: any = [];
        const bookTable = undefined;
        const bookContent = undefined;
        const bookIdx = -1; //bookIdx,  //number;
        const chapterIdx = -1; //chapterIdx //: number;
        this.sessionIdx = 0; // sessionIdx;
        const bibleData = undefined;
        this.setState({
          books,
          bookTable,
          bookContent,
          bookIdx,
          chapterIdx,
          bibleData
        });
      } else {
        // sign in state
        this.load(); // reload
      }
    } else if (this.props.HeadClickCount !== nextProps.HeadClickCount) {
      /// the top header is clicked, clear state
      const bookIdx = -1;
      const chapterIdx = -1;
      const bookTable = undefined;
      const bookContent = undefined;
      const books: IBook[] = [];
      this.sessionIdx = 0; // sessionIdx;
      const bibleData = undefined;
      this.setState({
        books,
        bookIdx,
        chapterIdx,
        bookTable,
        bookContent,
        bibleData
      });
      this.load(); // always reload
    }

    return true;
  }

  checkUserRole = async () => {
    const signInState = localStorage.getItem('SignInState');
    let role = 0;

    if (signInState) {
      const signInStateObj = JSON.parse(signInState);
      const targetOrg = signInStateObj.organizations?.find((org: IOrganization) => org.id === _dataProvider.getOrgId());

      role = targetOrg?.role ?? 0;
    }

    if (role !== 1 && role !== 2) {
      window.location.href = '/';
    }
  };

  load = () => {
    //const books: IBook[] =
    _dataProvider.getOwnerBooks().then((books: IBook[]) => {
      console.log('owner boooks: ', books);

      const currentOrgId = _dataProvider.getOrgId();
      const filteredBooks = books.filter((book) => book.orgId === currentOrgId);
      console.log('filtered books: ', filteredBooks);
      this.setState({ books: filteredBooks });

      /* don't use settings that's for studying classes....
      const {sessionIdx, merge} = this.props.settings;
      if (sessionIdx)
        this.sessionIdx = sessionIdx!;
      if (merge)
        this.merge = merge!;
      */

      //before the user sign in, we had an empty books
      if (books.length === 0) {
        return;
      }

      //TODO:  don't auto jump to the specific book for now so that Jeff
      //  can see the flow of just plain books
      //if (bookIdx && bookIdx>=0)
      //  this._setBook(bookIdx, chapterIdx);
    });
  };

  public componentDidUpdate(): void {
    console.log('WriteingBooks componentDidUpdate');
  }

  private _setBible = (bibleData: any): void => {
    this.setState({ bibleData });
  };

  private _setSessionDate = (sessionIdx: number): void => {
    this.sessionIdx = sessionIdx;
  };

  private _setBook = (bookIdx: number, chapterIdx = -1): void => {
    _dataProvider.getContent(this.state.books[bookIdx].name, 'free.json').then((bookTable) => {
      console.log('.getBookTable response: ', bookTable);
      if (bookTable.title === '550') {
        alert('retrieving book content failed, try again');
        return;
      }
      bookTable.title = this.state.books[bookIdx].classNameChs;
      const bookContent = undefined; // wipe out the book content value
      this.sessionIdx = 0; // reset to session 0...
      this.setState({
        bookIdx,
        chapterIdx,
        bookTable: bookTable as IBookTable,
        bookContent
      });

      if (chapterIdx >= 0) {
        this._setChapter(chapterIdx);
      }
    });
  };

  private rememberBookTime(name: string) {
    const bookAccessTime = JSON.parse(localStorage.getItem('classOrder') || '{}');
    bookAccessTime[name] = Date.now();
    localStorage.setItem('classOrder', JSON.stringify(bookAccessTime));
  }

  private _AddBook = (bookName: string) => {
    _dataProvider.createBook(bookName).then((newbook: IBook) => {
      if (newbook.name === '__fakeId') {
        alert('book creation failed, try again');
        return;
      }
      const { books } = this.state;
      const bookIdx = books.push(newbook) - 1;

      const bookTable = _dataProvider.getEmptyBook(newbook.name);
      bookTable.title = books[bookIdx].classNameChs;
      bookTable.sessions[0].title = i18n.t('authoring.Chapter1');

      const lastState = JSON.parse(localStorage.getItem('SignInState') || '{}');
      const authors = [{ userId: getJwtObj().userId, displayName: lastState.displayName.slice(0, 6) }];

      const bookContent = undefined; // wipe out the book content value
      this.sessionIdx = 0; // reset to session 0...
      this.setState({ bookIdx, chapterIdx: -1, bookTable, bookContent, authors });

      this.rememberBookTime(newbook.name);
    });

    return;
  };

  private _deleteBook = (index: number) => {
    const result = window.confirm('delete book?');
    if (result) {
      const { books } = this.state;
      _dataProvider.deleteBook(books[index].name).then((rrr) => {
        if (!rrr) {
          alert('delete failed, check connection, and try again!');
        } else {
          books.splice(index, 1);
          this.setState({ books });
        }
      });
    }
  };

  private currentAllowDownload(): number {
    const book = this.state.books[this.state.bookIdx];
    return book.allowDownload ?? 0;
  }

  private currentBookStateKey(): string {
    const book = this.state.books[this.state.bookIdx];
    if (book.status === 0) {
      return 'A';
    } else if (book.status === 1) {
      if (book.classType === 1) {
        return 'C';
      } else if (book.classType === 2) {
        return 'B';
      } else if (book.classType === 0) {
        return 'D';
      }
    }
    console.error('The book is in illegal state: ', book);
    return 'A';
  }

  private _updateBookState = (key: string, allowDownload: number): boolean => {
    const { books } = this.state;
    let status = 1;
    let classType = 1;
    if (key === 'A') {
      status = 0;
    }
    if (key === 'D') {
      classType = 0;
    }
    if (key === 'B') {
      classType = 2;
    }
    console.log('_updateBookState: ', key, status, classType, books[this.state.bookIdx].name);
    _dataProvider.updateBook(books[this.state.bookIdx].name, status, classType, allowDownload).then((result) => {
      if (!result) {
        alert('Book state update failed, check connection, and try again!');
        return false;
      } else {
        books[this.state.bookIdx].status = status;
        books[this.state.bookIdx].classType = classType;
        books[this.state.bookIdx].allowDownload = allowDownload;
        this.setState({ books });
      }
    });
    return true;
  };

  private _duplicateChapter = (chapterIdx: number, idx: number, chapterId: string, chapterName: string) => {
    if (this.state.chapterIdx !== idx) {
      return;
    }

    const { bookContent } = this.state;
    bookContent!.title = chapterName;

    _dataProvider.saveContent(bookContent!, chapterId).then((success) => {
      if (!success) {
        alert('Duplicate failed, try again! you may need to refresh page');
      }
      bookContent!.chapterId = chapterId;
      this.setState({ bookContent, chapterIdx });
      return;
    });
  };

  private _setChapter = (chapterIdx: number, reload = true): void => {
    if (!reload) {
      this.setState({ chapterIdx });
      return;
    }
    let sessions = this.state.bookTable?.sessions;
    if (Array.isArray(this.state.bookTable?.content)) {
      sessions = this.state.bookTable?.content;
    }

    console.log(
      '_setChapter: ',
      chapterIdx,
      //sessions![chapterIdx].lesson,
      //this.state.bookTable,
      sessions
    );

    // reset --- there could be a data corruption somewhere, but don't bomb
    if (!sessions || sessions?.length <= chapterIdx) {
      chapterIdx = 0;
    }

    console.log(chapterIdx, sessions![chapterIdx].lesson, this.state.bookTable, sessions);

    const chapterFile = sessions![chapterIdx].lesson || '';
    if (chapterFile === '') {
      console.error('Data error, session has no chapter filename: ', chapterIdx, sessions);
      return;
    }

    const isChapterLessonWebUrl = !chapterFile.toLowerCase().endsWith('.json');
    if (isChapterLessonWebUrl) {
      this.setState({ chapterIdx, templateUri: chapterFile });
      return;
    }

    _dataProvider.getContent(this.state.bookTable?.lessonId || '', chapterFile).then((bk) => {
      // special case when chapter first added, no .json file to the chapter yet
      if (!bk) {
        /// something bad happened, but don't bomb
        return;
      }
      const bookContent = bk as IBookContent;
      if (bookContent.title === '404') {
        //the chapter is not yet written
        bookContent.title = sessions![chapterIdx].title || '';
      }

      this.sessionIdx = 0; //reset sessionID between chapters
      bookContent.bookTitle = this.state.bookTable?.title;
      this.setState({
        bookContent,
        chapterIdx,
        templateUri: phoneEmulatorTemplate
      });
    });
  };

  public render(): React.ReactNode {
    const dataId = `${this.state.books[this.state.bookIdx]?.classId},${this.state.chapterIdx}`;
    const answerDataId = `answer,${dataId}`;
    //const notesDataId = `notes,${dataId}`;
    //const notesDataId = `notes,${this.state.books[this.state.bookIdx]?.classId}`;

    if (!this.props.signInState) {
      return (
        <div style={{ height: '100%', display: 'flex' }}>
          <h3 style={{ marginLeft: '15px', margin: '20px' }}>{i18n.t('pleaseSignin')}</h3>
        </div>
      );
    }

    if (this.state.bookIdx === -1) {
      return (
        <OwnerBooks
          books={this.state.books}
          setBook={this._setBook}
          addBook={this._AddBook}
          deleteBook={this._deleteBook}
        />
      );
    }

    return (
      <div style={{ height: this.state.contentHeight, display: 'flex' }}>
        {this.state.bibleData && (
          <div className='TableOfContent BibleNavigation'>
            <BibleNavigation
              bibleData={this.state.bibleData}
              // these bible versions need to come out of settings
              // for now default -- TODO
              bibleVersion1Language='简体中文'
              bibleVersion1Name='和合本'
              bibleVersion2Language='English'
              bibleVersion2Name='American Standard Version'
              merge={this.merge}
              setBible={this._setBible}
              setBibleCompareState={this._setBibleCompareState}
              contentHeight={this.state.contentHeight}
            />
          </div>
        )}
        {!this.state.bibleData && this.state.bookTable && this.state.bookIdx >= 0 && (
          <div className='TableOfContent' style={{ height: this.state.contentHeight }}>
            <WritingTableOfContent
              bookTable={this.state.bookTable}
              image={`${HostOfficialServer}/lesson/${this.state.books[this.state.bookIdx].name}/cover`}
              chapterIdx={this.state.chapterIdx}
              setChapter={this._setChapter}
              bookStateKey={this.currentBookStateKey()}
              updateBookState={this._updateBookState}
              duplicateChapter={this._duplicateChapter}
              contentHeight={this.state.contentHeight}
              allowDownload={this.currentAllowDownload()}
              preview={this.props.preview}
            />
          </div>
        )}
        {this.state.bookTable &&
          this.state.bookIdx >= 0 &&
          (this.state.bookContent || this.state.templateUri !== phoneEmulatorTemplate) && (
            <div className='BookContent' style={{ height: this.state.contentHeight }}>
              <PhoneViewer
                bookContent={this.state.bookContent ? { lesson: this.state.bookContent, answers: [] } : undefined}
                sessionId={this.sessionIdx}
                chapterIdx={this.state.chapterIdx}
                dataId={answerDataId}
                templateUri={this.state.templateUri}
                setSessionDate={this._setSessionDate}
                setBible={this._setBible}
                setProgress={this._setProgress}
                contentHeight={this.state.contentHeight}
                changeCount={this.state.changeCount}
                preview={true}
                setChapter={this._setChapter}
              />
            </div>
          )}
        {!this.props.useEditor &&
          this.state.templateUri === phoneEmulatorTemplate &&
          this.state.bookTable &&
          this.state.bookIdx >= 0 &&
          this.state.bookContent && (
            <div
              className='ContentEditor'
              style={{
                height: this.state.contentHeight,
                float: 'right'
              }}>
              <ContentEditor
                bookContent={this.state.bookContent}
                sessionId={this.sessionIdx}
                setChangeCount={this._setChangeCount}
                setChapterTitle={this._setChapterTitle}
                contentHeight={this.state.contentHeight}
              />
            </div>
          )}
        {this.state.templateUri === phoneEmulatorTemplate &&
          this.props.useEditor === 1 &&
          this.state.bookTable &&
          this.state.bookIdx >= 0 &&
          this.state.bookContent && (
            <JsonEditor jsonContent={this.state.bookContent} receiveContent={this._receiveBookContent} />
          )}
        {this.props.useEditor === 2 &&
          this.state.bookTable &&
          this.state.bookIdx >= 0 &&
          (this.state.bookContent || this.state.templateUri !== phoneEmulatorTemplate) && (
            <JsonEditor jsonContent={this.state.bookTable} receiveContent={this._receiveTableContent} />
          )}
      </div>
    );
  }

  private _receiveTableContent = (jsonContent: IBookTable | IBookContent, save: boolean): void => {
    console.log('_receiveTableContent', this);
    const bookTable = jsonContent as IBookTable;

    if (save) {
      _dataProvider.saveContent(bookTable, 'free.json').then((success) => {
        if (!success) {
          alert('save failed, try again! you may need to refresh page');
        } else {
          alert('save success');
        }
        return; // the state should be set already below.
      });
    }

    const changeCount = this.state.changeCount + 1;
    console.log('new table of content: ', bookTable);
    this.setState({ chapterIdx: -1, bookTable, changeCount });
  };

  // eslint-disable-next-line
  private _receiveBookContent = (
    jsonContent: IBookTable | IBookContent,
    save: boolean
  ): void => {
    console.log('_receiveBookContent', this);
    const bookContent = jsonContent as IBookContent;
    if (save) {
      _dataProvider.saveContent(bookContent, this.state.bookContent!.chapterId!).then((success) => {
        if (!success) {
          alert('save failed, try again! you may need to refresh page');
        } else {
          alert('save success');
        }
        return; // the state should be set already below.
      });
    }
    const changeCount = this.state.changeCount + 1;
    this.setState({ bookContent, changeCount });
  };

  private _setChangeCount = (changeCount: number) => {
    this.setState({ changeCount });
  };

  private _setChapterTitle = (title: string) => {
    const bookTable = this.state.bookTable;
    let sessions = bookTable?.sessions;
    if (Array.isArray(bookTable?.content)) {
      sessions = bookTable?.content;
    }

    sessions![this.state.chapterIdx].title = title;

    //don't refresh the phoneview with title change, the title change will be refreshed
    //  as part of general content update every 5 seconds.
    //const changeCount = 1 + this.state.changeCount;

    //const bookContent = this.state.bookContent;
    //bookContent!.title = title;
    //this.setState({ bookTable, changeCount, bookContent });
    this.setState({ bookTable });
  };

  private _setBibleCompareState = (merge: boolean) => {
    this.merge = merge;
  };

  private _setProgress = (/*progress: string*/) => {};
}

export default WritingBooks;
