import React from 'react';

interface ISearchContextType {
  searchQuery: string;
  updateSearchQuery: (newQuery: string) => void;
}

// This is the search context to allow DiscoverBook to observer the Header's search box without prop drilling.
export const SearchContext = React.createContext<ISearchContextType>({
  searchQuery: '',
  updateSearchQuery: () => {}
});
