import { _dataProvider } from '../dataProvider/DataProvider';
import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import i18n from '../i18n';
import { DefaultButton } from '@fluentui/react/lib/Button';

interface IPasswordResetProps {
  close: () => void;
}

const PasswordReset = (passwordResetProps: IPasswordResetProps) => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await _dataProvider.resetPassword(email);
      if (response) {
        alert(i18n.t('PleaseCheckYourInbox'));
        passwordResetProps.close();
      } else {
        alert(i18n.t('PasswordResetError'));
      }
    } catch (error) {
      console.log('Password reset error', error);
      alert(i18n.t('PasswordResetError'));
    }
  };

  return (
    <Translation>
      {(t) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <div style={{ width: '300px' }}>
            <h2 className={'dialog-header'}>{t('PasswordReset')}</h2>
            <h5 className={'dialog-label'}>{t('Email')}</h5>
            <input
              name='email'
              type='email'
              placeholder={'12345@gmail.com'}
              value={email}
              onChange={handleEmailChange}
              className={'dialog-box'}
            />
            <br />
            <br />
            <DefaultButton
              type='submit'
              onClick={handleSubmit}
              primary
              styles={{
                root: {
                  backgroundColor: 'black',
                  color: 'white'
                }
              }}>
              {t('submit')}
            </DefaultButton>
          </div>
        </div>
      )}
    </Translation>
  );
};

export default PasswordReset;
