import React, { useState, useEffect, useRef } from 'react';
import { Text, DefaultButton } from '@fluentui/react';
import { _dataProvider } from '../dataProvider/DataProvider';
import i18n from '../i18n';
import Footer from '../components/Footer';

//联系我们
//版权声明
//隐私政策
/*
    const body = { message: this.message };
    const result = await callWebServiceAsync(`${Models.HostHttpServer}feedback`, 'POST', body);
*/

const AboutUs = () => {
  const [feedback, setFeedback] = useState('');

  const feedbackRef = useRef<HTMLDivElement>(null);
  const aboutusRef = useRef<HTMLDivElement>(null);
  const termsRef = useRef<HTMLDivElement>(null);

  const showSection = (sss: string) => {
    const refs = [feedbackRef, aboutusRef, termsRef];
    const toS = ['#fb', '#au', '#tm'];
    const idx = toS.indexOf(sss);
    if (idx !== -1) {
      refs[idx]!.current!.scrollIntoView();
    }
  };

  useEffect(() => {
    const targetS = window.location.href.slice(-3);
    showSection(targetS);
  });

  return (
    <>
      <div className='aboutUs'>
        <div ref={feedbackRef} id='feedback' key='feedback' className='bookCategoryHeader'>
          <label style={{ color: 'black' }}>
            <button className='bookCategoryMark' />
            {i18n.t('UserFeedback')}
          </label>
          <Text style={{ lineHeight: '3em' }}>
            <br />
            {i18n.t('PleaseConnectWithUs')}
          </Text>
          <br />
          <textarea
            style={{ width: '70%', resize: 'vertical' }}
            rows={4}
            value={feedback}
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
          />
          <br />
          <DefaultButton
            style={{
              borderRadius: '25px',
              height: '30px'
            }}
            onClick={() => {
              _dataProvider.postFeedback(feedback);
              alert(i18n.t('ThankYou'));
            }}>
            {i18n.t('SendFeedback')}
          </DefaultButton>
        </div>
        <br />
        <div ref={aboutusRef} id='aboutus' key='aboutus' className='bookCategoryHeader'>
          <label style={{ color: 'black' }}>
            <button className='bookCategoryMark' />
            {i18n.t('AboutUs')}
          </label>
          <br />
          <br />
          <div style={{ fontWeight: 'normal' }}>
            Gracetech services is a non-profit tech services company, founded in Dec, 2018 in response to the technical
            needs of non-profit organizations. While commercial businesses have benefited greatly from technological
            advances to be ever effective in reaching their customers, many non-profit organizations have lacked the
            expertise and technical know-how to increase their effectiveness in ministering the needs of their community
            via technology. A few of us in the high tech industry decided to establish Gracetech as a non-profit company
            to provide for these technical needs of such non-profit organizations all over the world. Our goal is to
            offer tech service to non-profit organizations based on the available volunteer hours and funds.{' '}
            <a
              href='https://www.paypal.com/paypalme/gracetech'
              style={{
                textDecoration: 'underline',
                fontWeight: 'bolder',
                margin: '0'
              }}>
              Donations
            </a>{' '}
            you give will help us to hire part time and/or full time staff to increase the amount of help we can offer
            to other non-profit organizations. Our work can range from technical consultation, design, and prototype, to
            full productization, all depending on the available volunteer hours and funds. Gracetech will also develop
            generic products and services with the goal of helping people getting more out of their lives. If you are a
            non-profit organization and you need tech service, you may reach us via{' '}
            <a
              href='email:mikeguo@gtinternational.org'
              style={{
                textDecoration: 'underline',
                fontWeight: 'bolder',
                margin: '0'
              }}>
              email
            </a>
            . Blessings to you and your non-profit ministry.
          </div>
        </div>
        <br />
        <div ref={termsRef} id='terms' key='terms' className='bookCategoryHeader'>
          <label style={{ color: 'black' }}>
            <button className='bookCategoryMark' />
            {i18n.t('Terms')}
          </label>
          <br />
          <br />
          <iframe
            title={'terms'}
            src={'https://gracetechstorage.blob.core.windows.net/idigest/tou.html'}
            style={{
              border: 'none',
              height: '80em',
              width: '100%',
              overflowY: 'auto',
              backgroundColor: 'lightgrey'
            }}></iframe>
        </div>
      </div>
      <Footer localScroll={showSection} />
    </>
  );
};

export default AboutUs;
