import * as React from 'react';
import { Text } from '@fluentui/react';

export const RedDot = (props) => {
  const { left, right, top, count } = props;

  if (count !== undefined) {
    if (count === 0) {
      return null;
    }

    return (
      <div
        style={{
          position: 'absolute',
          right: typeof right === 'number' ? right : 3,
          top: typeof top === 'number' ? top : 1,
          backgroundColor: 'red',
          borderWidth: 1,
          borderColor: 'white',
          borderRadius: 24,
          width: 24,
          height: 24,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Text
          style={{
            color: 'white',
            fontSize: 12
          }}>
          {count > 99 ? 99 : count}
        </Text>
      </div>
    );
  }

  return (
    <div
      style={{
        /*position: 'absolute',*/
        top: typeof top === 'number' ? top : 15,
        left,
        right,
        backgroundColor: 'red',
        borderWidth: 1,
        borderColor: 'white',
        borderRadius: 12,
        width: 12,
        height: 12,
        marginLeft: 'auto'
      }}
    />
  );
};
