import { IBook, IBookTable } from './IBookTable';
import { IBookContent, IChapterContentAndAnswers, ILessonPreview } from './IBookContent';
import { ICourse, IGroupUser, IStudent, ITrainingDetails } from './IClassManagement';
import { IChatMessage } from './IChatData';
import { getOrgServer, getOrgLogo, getOrgClassProgressionControl } from '../SharedCommon/OrgList';
import { getAuthStr, getJwtObj, getToken, isUserSignedIn, wipeSignInState } from '../SharedCommon/utils';
import BookTable from '../sample/empty-free.json';
import axios from 'axios';
import i18n from '../i18n';
import createAxiosInstance from './customAxios';
import plusAvatar from '../img/icon-Plus.png';
import allUserAvatar from '../img/icon-All.png';

export interface IUser {
  userId: number;
  displayName: string;
  name?: string;
  loginId: string;
  uniqueId?: string;
  isGroupLeader: boolean;
  tag?: string;
  block?: boolean;
  id?: number;
  accepted?: boolean;
}

export interface IMessage {
  id: number;
  text: string;
  createdAt: Date;
  user: {
    id: number;
    name: string;
    avatar: string;
  };
  image?: string;
}

export class DataProvider {
  private serverUrl = '';
  private bibleUrl = '';
  private refresh = true;
  private classes: any[] = [];
  private orgId?: number;
  private orgServerUrl?: string;
  private axiosInstance?: any; // = createAxiosInstance(this.lang);
  private myProfile?: any;
  private myClasses: number[] = [];
  private myTagsForUsers = {} as any;
  private myBlocks = {} as any;
  private displayNameChangeListeners: Array<() => void> = [];

  public init(apiurl: string, orgId?: number, serverurl = '') {
    this.myProfile = undefined;
    this.serverUrl = apiurl;
    this.orgId = orgId;
    this.orgServerUrl = getOrgServer(orgId ?? 0);
    this.refresh = true;
    this.classes = []; //: any;
    this.bibleUrl = serverurl + '/bible/';
    this.axiosInstance = createAxiosInstance(() => i18n.language);
  }

  constructor(url: string, orgId?: number) {
    this.init(url, orgId);
  }

  public getMyProfile() {
    console.log('profile: ', this.myProfile);
    if (this.myProfile === undefined) {
      //there is a case that the user isn't going through login, but load from token from storage
      const storedProfile = localStorage.getItem('myProfile');
      if (storedProfile) {
        return JSON.parse(storedProfile);
        //do not set this.myProfile here.  It should only be set
        //it got a fresh load from the server API
      }
    }
    return this.myProfile ?? { displayName: '', uniqueId: '' };
    //localStorage.getItem('myProfile', JSON.stringify(this.myProfile));
  }

  public reset() {
    this.classes = []; //: any;
    this.myProfile = undefined;
    //this.groups = null; //: any; // = groups;
  }

  public getOrgId(): number {
    return this.orgId ? this.orgId : 0;
  }
  public getLogo() {
    return getOrgLogo(this.getOrgId());
  }
  public classProgressionControl(): boolean {
    return getOrgClassProgressionControl(this.getOrgId());
  }
  public moreUserDataForOrg(): boolean {
    return !!this.orgServerUrl;
  }

  public addDisplayNameChangeListener(listener: () => void) {
    this.displayNameChangeListeners.push(listener);
  }

  public removeDisplayNameChangeListener(listener: () => void) {
    this.displayNameChangeListeners = this.displayNameChangeListeners.filter((l) => l !== listener);
  }

  private notifyDisplayNameChange() {
    this.displayNameChangeListeners.forEach((listener) => listener());
  }

  public async getDisplayName(): Promise<string> {
    try {
      const response = await this.axiosInstance.get(`${this.serverUrl}/user/profile`);

      if (response.status === 200 && response.data && response.data.displayName) {
        return response.data.displayName; // Return the displayName from the response
      } else {
        throw new Error('Failed to fetch display name');
      }
    } catch (error) {
      console.error('Error fetching display name:', error);
      throw error;
    }
  }

  public getAvatarUrl(userId: number): string {
    const prebuiltAvatars = [plusAvatar, allUserAvatar];
    if (userId < 0) {
      const idx = 0 - userId - 1;
      return prebuiltAvatars[idx];
    }
    return `${this.serverUrl}/user/avatar/${userId}`;
  }

  public async updateUserDisplayName(displayName: string): Promise<boolean> {
    // Changed return type to boolean for success check
    try {
      const response = await this.axiosInstance.put(`${this.serverUrl}/user/profile`, { displayName });

      if (response.status === 200) {
        if (this.myProfile) {
          this.myProfile.displayName = displayName;
          this.notifyDisplayNameChange(); // Notify listeners after successful display name update
        }
        return true; // Return true to indicate success
      } else {
        throw new Error('Failed to update user display name');
      }
    } catch (error) {
      console.error('Update display name error:', error);
      return false; // Return false to indicate failure
    }
  }

  public async uploadAvatar(file: File): Promise<string> {
    try {
      const formData = new FormData();
      formData.append('userAvatar', file);

      const response = await this.axiosInstance.put(`${this.serverUrl}/user/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 201) {
        return response.data.avatarUrl;
      }
      throw new Error('Failed to upload avatar');
    } catch (error) {
      console.error('Upload avatar error:', error);
      throw error;
    }
  }

  public async getBible(bible: string): Promise<any> {
    try {
      const remoteUri = this.bibleUrl + bible + '.json';

      const cache = 'caches' in window ? await window.caches.open('bible') : undefined;
      if (cache) {
        const cachedResponse = await cache.match(remoteUri);
        if (cachedResponse) {
          const bibleJson = await cachedResponse.json();
          return bibleJson;
        }
      }

      const response = await fetch(remoteUri);
      if (response.status === 200) {
        if (cache) {
          cache.put(remoteUri, response.clone());
        }
        const bibleJson = await response.json();
        return bibleJson;
      }
    } catch (error) {
      alert(error);
    }

    return undefined;
  }

  public async refreshAccessTokenIfNeededAsync(): Promise<boolean> {
    const jwt = getJwtObj();
    if (!jwt || typeof jwt.exp !== 'number') {
      return false;
    }

    //const expDate = new Date(jwt.exp * 1000);
    const daysDiff = Math.floor((jwt.exp * 1000 - Date.now()) / 1000 / 60 / 60 / 24);

    // refresh token when it's going to expire in 30 days
    console.log('Refresh?: ', daysDiff);
    if (daysDiff >= 30) {
      return false;
    }

    try {
      const response = await this.axiosInstance.post(
        this.serverUrl + '/user/refreshAccessToken', //RefreshLoginUrl,
        {}
      );
      if (response.status === 200) {
        //redo this later -- but current code depend on this
        localStorage.setItem('access_token', response.data.accessToken);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  public async getBooks2(refresh: boolean): Promise<IBook[]> {
    try {
      if (!refresh) {
        if (!this.refresh && this.classes && this.myProfile) {
          console.log('getBooks2:return cached classes');
          return this.classes;
        }
      }

      let response;
      if (!getToken()) {
        response = await axios.get(`${this.serverUrl}/home`);
        if (response.status === 200) {
          if (!this.orgId) {
            return response.data.books;
          }
          const books = response.data.books.filter((book: { orgId: number }) => book.orgId === this.orgId);
          this.classes = books;
          console.log('getBooks2 home: ', books);
          return books;
        }
      } else {
        if (this.myProfile == undefined) {
          // call this expensive version only once
          response = await this.axiosInstance.get(`${this.serverUrl}/user/getUpdate`);
          if (response.status === 200) {
            this.myClasses = response.data.myClasses ?? [];
            this.myProfile = response.data.user ?? {};
            localStorage.setItem('myProfile', JSON.stringify(this.myProfile));
          }
        } else {
          response = await this.axiosInstance.get(`${this.serverUrl}/user/classes`);
        }
        if (response.status === 200) {
          let classes = response.data.classes as IBook[];
          classes = classes.filter((cls) => cls.status !== -1);

          if (this.orgId) {
            classes = classes.filter((cls) => cls.orgId === this.orgId);
          }

          classes.forEach((it) => {
            it.isMyClass = this.myClasses.indexOf(it.classId) !== -1;
          });

          this.refresh = false;
          this.classes = classes;
          console.log('getBooks2: ', classes);
          return classes;
        }
      }
    } catch (error) {
      console.log(error);
    }

    //if (process.env.REACT_APP_testlocal !== '1' && !window.location.href.startsWith('http://localhost'))
    if (!window.location.href.startsWith('http://localhost')) {
      return [];
    }

    // retain the testing sample data --- while in development.
    return [
      {
        image: 'http://13.91.97.63/idigest/lesson/parenting/cover',
        name: 'resourceID1',
        classId: 1,
        classNameChs: '子女心，父母情',
        classNameCht: '子女心，父母情',
        classNameEng: '子女心，父母情'
      },
      {
        image: 'https://idigest.gtinternational.org/api/lesson/GodsLove/cover',
        name: 'resourceID2',
        classId: 2,
        classNameChs: 'Gods Love',
        classNameCht: 'Gods Love',
        classNameEng: 'Gods Love'
      },
      {
        image: 'http://13.91.97.63/idigest/lesson/FollowMe/cover',
        name: 'resourceID3',
        classId: 3,
        classNameChs: 'Follow me',
        classNameCht: 'Follow me',
        classNameEng: 'Follow me'
      }
    ];
  }

  public async getBookTable(lessonId: string): Promise<IBookTable> {
    const url = `${this.serverUrl}/lesson/${encodeURIComponent(lessonId)}`;
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        response.data.lessonId = lessonId; //this looks evil, but, works!
        return response.data;
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        BookTable.sessions[0].title = i18n.t('authoring.Chapter1');
        return {
          title: i18n.t('authoring.NoName'), //"我的新课程1",
          lessonId,
          ...JSON.parse(JSON.stringify(BookTable)),
          progress: []
        };
        //return { title: '404', lessonId, description: '', sessions: [], progress: [] };
        // an empty object for non-existent free.json
        // -- this is okay state when book is first created.
        //the recipient should deal with it.
      }
    }

    //BookTable.lessonId = "1";
    //title 550 --- bad failure!!!
    return {
      title: '550',
      lessonId,
      ...JSON.parse(JSON.stringify(BookTable)),
      progress: []
    };
  }

  public async getChapterContentAndAnsers(
    lessonId: string,
    chapterId: string,
    chapterIndex: number
  ): Promise<IChapterContentAndAnswers> {
    //try to refresh token with each chapter, assuming the user
    //only use the tool once a week to study a chapter, this would be
    //weekly checking to see if there needs to be refreshed.
    //checking more often is cheap...
    this.refreshAccessTokenIfNeededAsync();

    const groupId = 0;

    //the groupID isn't getting used in the service API.
    //the access check is based on *all* the groups a user belongs to, and that
    //is checked against DB directly. so: TODO: what is the gorup ID for??
    //this.sessionIndex is the week, it's the index into the tableofcontent as each chapter is organized as if it's weekly
    //this.lessonFile is the json file name for that chapter ===
    const emptyLesson = {
      title: '',
      version: 0,
      sessions: [{ title: '', content: [] }],
      lessonId,
      chapterId
    };

    const url = `${this.serverUrl}/lesson/${encodeURIComponent(
      lessonId
    )}/${groupId}/${chapterId}?index=${chapterIndex}`;
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        console.log(response.data);
        response.data.lesson.lessonId = lessonId; //this looks evil, but, works!
        return response.data;
      }
      emptyLesson.title = '$Error, Try again:' + response.status;
    } catch (error) {
      console.log(error);
      emptyLesson.title = '$Error, Try again:' + error.response?.status;
      if (error.response?.status === 404) {
        emptyLesson.title = '404';
        return { lesson: emptyLesson };
        // an empty object for non-existent free.json
        // -- this is okay state when a chapter is first created
      }
    }

    //ChapterContentAndAnswers.lesson.contentUrl =`${this.serverUrl}/lesson/${encodeURIComponent(lessonId)}/content/`;
    //ChapterContentAndAnswers.lesson.accessToken = getToken();
    return { lesson: emptyLesson };
    //return ChapterContentAndAnswers;
  }

  public async saveUserData(dataId: string, body: any): Promise<boolean> {
    //  const result = await callWebServiceAsync(`${Models.HostHttpServer}user/data/answer,${dataId}`, 'PUT', body);
    //async saveAsync(dataId, questionId, answerText) {
    //const body = this.answers;
    //body[questionId] = answerText;
    console.log('saveUserData: ', body);
    const url = `${this.serverUrl}/user/data/${dataId}`;
    try {
      const response = await this.axiosInstance.put(url, body);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 200) {
        return true;
      }
    }

    return false;
  }

  public async getUserGroups(): Promise<any> {
    const url = `${this.serverUrl}/groups`;
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getAllContactAsync() {
    const url = `${this.serverUrl}/user/contact`;
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
    return {};
  }

  public async updateContactForUser(userId: number, tag: string, block: number) {
    const url = `${this.serverUrl}/user/contact/${userId}`;
    try {
      const response = await this.axiosInstance.put(url, { tag, block });
      if (response.status === 200) {
        this.myTagsForUsers[userId] = tag;
        this.myBlocks[userId] = block;
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  public getDisplayNameForUser(user: any) {
    return `${user.displayName} ${this.myTagsForUsers[user.userId || user.id] ?? ''} (${user.loginId})`;
  }

  public getMyBlockForUser(userId: number) {
    return !!this.myBlocks[userId];
  }

  public getMyTagForUser(userId: number) {
    return this.myTagsForUsers[userId] ?? '';
  }

  public async getUserContacts(filterBlocked = true): Promise<any> {
    const url = `${this.serverUrl}/user/inviteUsers`;
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        let inviteUsers = response.data;
        if (!filterBlocked) {
          return inviteUsers;
        }
        const contactInfo = await this.getAllContactAsync();
        const blockedUsers = new Set([
          ...contactInfo.me.filter((u: any) => u.block).map((u: any) => u.userId),
          ...contactInfo.blockedBy
        ]);

        this.myBlocks = contactInfo.me.reduce((blocks: any, contact: any) => {
          blocks[contact.userId] = contact.block !== 0;
          return blocks;
        }, {});

        this.myTagsForUsers = contactInfo.me.reduce((tags: any, contact: any) => {
          tags[contact.userId] = contact.tag;
          return tags;
        }, {});

        inviteUsers = inviteUsers.filter((it: any) => !blockedUsers.has(it.userId));
        inviteUsers.forEach((it: any) => (it.name = this.getDisplayNameForUser(it)));
        return inviteUsers;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  public async inviteUser(email: string, greetings: string): Promise<any> {
    const url = `${this.serverUrl}/user/invite`;
    const data = {
      email: email,
      greetings: greetings
    };

    try {
      const response = await this.axiosInstance.post(url, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public async getCurrentLoggedInUser(): Promise<any> {
    try {
      const response = await this.axiosInstance.get(`${this.serverUrl}/user/profile`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async createSmallGroup(groupName: string, members: string[]): Promise<void> {
    const classId = Date.now(); // Simple timestamp ID generator.
    const url = `${this.serverUrl}/group/${classId}/create`;
    const payload = {
      groupName,
      members
    };

    try {
      const response = await this.axiosInstance.post(url, payload);
      console.log('Group created:', response.data);
    } catch (error) {
      console.error('Error creating group:', error);
      throw new Error('Failed to create group'); // Optionally re-throw to handle errors in the component
    }
  }

  public async createOneOnOneGroup(friendId: number): Promise<any> {
    const url = `${this.serverUrl}/group/createOneOnOne`;
    const payload = {
      friendId
    };

    try {
      const response = await this.axiosInstance.post(url, payload);
      return response.data;
    } catch (error) {
      console.error('Error creating group:', error);
      return null;
    }
  }

  public async getUserGroupsAsLeader(): Promise<any> {
    const url = `${this.serverUrl}/groups`;
    const groupList = [];
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isGroupLeader === 1 && response.data[i].isOrgGroup === 0) {
            groupList.push(response.data[i].name);
          }
        }
        return groupList;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getOrgGroup(): Promise<any> {
    const url = `${this.serverUrl}/groups`;
    const groupList = [];
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isOrgGroup === 1 && response.data[i].isGroupLeader === 0) {
            groupList.push(response.data[i].name);
          }
        }
        return groupList;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getAllUsersForGroupLeader(check: boolean): Promise<any> {
    const url = `${this.serverUrl}/groups`;
    const groupList = [];
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        if (check === false) {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].isGroupLeader === 1) {
              const secondUrl = `${this.serverUrl}/group/${response.data[i].groupId}`;

              const secondResponse = await this.axiosInstance.get(secondUrl);
              if (secondResponse.status === 200) {
                groupList.push(secondResponse.data.users.length);
              }
            }
          }
        } else {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].isGroupLeader === 1 && response.data[i].isOrgGroup === 1) {
              const secondUrl = `${this.serverUrl}/group/${response.data[i].groupId}`;

              const secondResponse = await this.axiosInstance.get(secondUrl);
              if (secondResponse.status === 200) {
                groupList.push(secondResponse.data.users.length);
              }
            }
          }
        }
        return groupList;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getAllUsersForGroupMember(check: boolean): Promise<any> {
    const url = `${this.serverUrl}/groups`;
    const groupList = [];
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        if (check === false) {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].isGroupLeader === 0 && response.data[i].isOrgGroup === 0) {
              const secondUrl = `${this.serverUrl}/group/${response.data[i].groupId}`;
              try {
                const secondResponse = await this.axiosInstance.get(secondUrl);
                if (secondResponse.status === 200) {
                  groupList.push(secondResponse.data.users.length);
                }
              } catch (e) {
                console.log(e);
              }
            }
          }
        } else {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].isGroupLeader === 0 && response.data[i].isOrgGroup === 1) {
              const secondUrl = `${this.serverUrl}/group/${response.data[i].groupId}`;
              try {
                const secondResponse = await this.axiosInstance.get(secondUrl);
                if (secondResponse.status === 200) {
                  groupList.push(secondResponse.data.users.length);
                }
              } catch (e) {
                console.log(e);
              }
            }
          }
        }
        return groupList;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getSpecificGroupID(): Promise<any> {
    const url = `${this.serverUrl}/groups`;
    const groupList = [];
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isGroupLeader === 1) {
            groupList.push(response.data[i].groupId);
          }
        }
        return groupList;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getOrgGroupList(orgId: number): Promise<any> {
    const url = `${this.serverUrl}/webAdmin/${orgId}/groupList`;
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getAdminGroupsAttendance(orgId: number, check: boolean): Promise<any> {
    const url = `${this.serverUrl}/webAdmin/${orgId}/groupList`;
    const groupList = [];
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        if (check === false) {
          for (let i = 0; i < response.data.length; i++) {
            const GroupId = response.data[i].GroupId;
            const secondUrl = `${this.serverUrl}/webAdmin/groups`;
            try {
              const secondResponse = await this.axiosInstance.get(secondUrl);
              if (secondResponse.status === 200) {
                let flag = false;
                for (let j = 0; j < secondResponse.data.length; j++) {
                  if (secondResponse.data[j].groupId === GroupId) {
                    flag = true;
                    groupList.push(secondResponse.data[j].users.length);
                    break;
                  }
                }
                if (flag === false) {
                  groupList.push(0);
                }
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
        console.log(groupList);
        return groupList;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async signUp(displayName: string, email: string, password: string): Promise<void> {
    const url = `${this.serverUrl}/user/create`;
    const data = {
      displayName: displayName,
      loginId: email,
      password: password
    };

    try {
      const response = await this.axiosInstance.post(url, data);
      if (response.status === 201) {
        console.log('Signup successful:', response.data);
        return response.data;
      } else {
        console.error('Signup failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error during signup:', error);
      if (error.response?.status === 400) {
        // Log or alert the specific error message received from the backend
        console.error('Error during signup:', error.response.data.error);
        alert(error.response.data.error);
      }
      if (error.response?.status === 401) {
        alert('Session has expired, please log in again.');
      }
      throw error;
    }
  }

  public async getUserGroupsAsMember(): Promise<any> {
    const url = `${this.serverUrl}/groups`;
    const groupList = [];
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isGroupLeader === 0 && response.data[i].isOrgGroup === 0) {
            groupList.push(response.data[i].name);
          }
        }
        return groupList;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getUserData(dataId: string): Promise<string> {
    const url = `${this.serverUrl}/user/data/${dataId}`;
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        return response.data.content;
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        return 'Enter some notes here';
      }
    }

    return 'Data retrieval failed';
  }

  // get a json from server about student inputs
  public async getClassAnswers(orgId: number | undefined, trainingId: number | null, classId: number) {
    const url = `${this.serverUrl}/organizations/${orgId}/trainings/${trainingId}/classes/${classId}/answers`;
    try {
      const response = await this.axiosInstance.get(url);
      console.log('here is the response json from class answers:', response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch class answers:', error);
      throw error; // Optionally rethrow the error for the caller to handle
    }
  }

  // checks if the user has an avatar
  public async checkUserAvatar(userId: number) {
    const url = `${this.serverUrl}/user/avatar/${userId}`;
    try {
      const response = await this.axiosInstance.get(url);
      console.log('response', response);
      console.log('user avatar fetched successfully for user:', userId);
      return true;
    } catch (error) {
      console.log('Failed to fetch user avatar:', error);
      return false;
    }
  }

  public async getWebAdminUserList(orgId: number) {
    const url = `${this.serverUrl}/webAdmin/${orgId}/userList`;
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        return response.data.length;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getAttendanceData(orgId: number): Promise<any> {
    try {
      const url = `${this.serverUrl}/webAdmin/attendance/${orgId}`;
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getSortedGroupsAttendance(orgId: number, flag: boolean): Promise<any> {
    const leaderList = [];
    const groupList = new Map();
    const returnLeaderList = [];
    const returnMemberList: any[] = [];
    try {
      const url = `${this.serverUrl}/groups`;
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isGroupLeader === 1) {
            leaderList.push(response.data[i].groupId);
          }
        }
        try {
          const secondUrl = `${this.serverUrl}/webAdmin/${orgId}/grouplist`;
          const attendance = await this.axiosInstance.get(secondUrl);
          if (attendance.status === 200) {
            for (let i = 0; i < attendance.data.length; i++) {
              groupList.set(attendance.data[i].GroupId, attendance.data[i].GroupName);
            }
            for (let i = 0; i < leaderList.length; i++) {
              if (groupList.has(leaderList[i])) {
                returnLeaderList.push(groupList.get(leaderList[i]));
                groupList.delete(groupList.get(leaderList[i]));
              }
            }
            groupList.forEach((item) => {
              returnMemberList.push(item);
            });
            if (flag) {
              return returnLeaderList;
            } else {
              return returnMemberList;
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getSpecificGroupAttendance(groupNumber: number): Promise<any> {
    try {
      const url = `${this.serverUrl}/attendance/${groupNumber}`;
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /// for book authors
  public async getOwnerBooks(): Promise<IBook[]> {
    try {
      let response;
      if (this.orgId) {
        response = await this.axiosInstance.get(`${this.serverUrl}/user/classes`);
      } else {
        response = await this.axiosInstance.get(`${this.serverUrl}/bookAdmin/lessons`);
      }
      if (response.status === 200) {
        if (this.orgId) {
          return response.data.classes.map((cls: any) => ({
            name: cls.name,
            classId: cls.classId,
            classNameChs: cls.classNameChs,
            status: cls.status,
            classType: cls.classType, //free ? 0 : 1,
            orgId: cls.orgId
          }));
        } else {
          return response.data;
        }
      }
    } catch (error) {
      console.log(error);
    }

    return [];
    /*
    // retain the testing sample data --- while in development.
    return [
      {
        name:'1',
        classId: 0,  // the classID in the database --
        classNameChs: '',
        classNameCht: '',
        classNameEng: '子女心，父母情',
        status: 0
      },
      {
        name:'2',
        classId: 1,  // the classID in the database --
        classNameChs: '',
        classNameCht: '',
        classNameEng: 'Example book2',
        status: 1,
        classType: 0
      },
      {
        name:'3',
        classId: 2,  // the classID in the database --
        classNameChs: '',
        classNameCht: '',
        classNameEng: 'Example book3',
        status: 1,
        classType: 1
      }
    ];
    */
  }

  public async createBook(bookName: string): Promise<IBook> {
    try {
      // post: {titleEng,titleCht,titleChs, image}
      // we need to get an image URL -- but for now -- it's missing
      let response;
      if (this.orgId) {
        response = await this.axiosInstance.post(`${this.serverUrl}/bookAdmin/lesson/create`, {
          titleEng: bookName,
          organizationId: this.orgId
        });
      } else {
        response = await this.axiosInstance.post(`${this.serverUrl}/bookAdmin/lesson/create`, { titleEng: bookName });
      }
      if (response.status === 200 || response.status === 201) {
        const newBook = {
          name: response.data.lessonId,
          classId: 0, //this is not return;
          classNameChs: response.data.titleChs,
          classNameCht: response.data.titleCht,
          classNameEng: response.data.titleEng,
          status: 0,
          classType: 0
        };
        //return response.data;
        this.refresh = true;
        return newBook;
      }
    } catch (error) {
      console.log(error);
    }

    return {
      classId: 0, // the classID in the database --
      name: '__fakeId',
      classNameChs: bookName,
      classNameCht: bookName,
      classNameEng: bookName,
      status: 0,
      classType: 0
    };
  }

  ///bookAdmin/lesson/:lessonId/update
  public async updateBookImage(lessonId: string, bookUrl: string): Promise<boolean> {
    const url = `${this.serverUrl}/bookAdmin/lesson/${encodeURIComponent(lessonId)}/update`;
    try {
      const response = await this.axiosInstance.post(url, { ImageUrl: bookUrl });
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  public async deleteBook(lessonId: string): Promise<boolean> {
    const url = `${this.serverUrl}/bookAdmin/lesson/${encodeURIComponent(lessonId)}/update`;
    try {
      const book = {
        Status: -1
      };
      const response = await this.axiosInstance.post(url, book);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  public async updateBook(lessonId: string, Status: number, ClassType: number, allowDownload = 0): Promise<boolean> {
    const url = `${this.serverUrl}/bookAdmin/lesson/${encodeURIComponent(lessonId)}/update`;
    try {
      const book = {
        Status,
        ClassType,
        allowDownload
      };

      const response = await this.axiosInstance.post(url, book);
      console.log('updateBook: ', book, response);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  ///bookAdmin/lesson/:lessonId/update
  public async updateBookTitle(lessonId: string, title: string): Promise<boolean> {
    const url = `${this.serverUrl}/bookAdmin/lesson/${encodeURIComponent(lessonId)}/update`;
    try {
      const book = {
        ClassNameChs: title
      };
      const response = await this.axiosInstance.post(url, book);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  public async uploadFile(lessonId: string, file: any, callback: any, serverFilename?: string): Promise<boolean> {
    const url = `${this.serverUrl}/bookAdmin/lesson/${encodeURIComponent(
      lessonId || ''
    )}/uploadFile?newName=${encodeURIComponent(serverFilename || file?.name || Date.now())}`;

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await this.axiosInstance.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent: any) => {
          const progress = progressEvent.loaded / progressEvent.total;
          if (callback) {
            callback(progress);
          }
        }
      });

      console.log('upload response: ', response);
      if (response.status === 200) {
        /* /// the client or any code, is no longer looking at the image field in the db
        const newURL = `${this.serverUrl}/lesson/${encodeURIComponent(
          lessonId
        )}/cover`;
        const success = await this.updateBookImage(lessonId, newURL);
      */
        file!.newName = response.data.filename; //serverFilename;
        return true;
      }
    } catch (error) {
      console.log('upload file error: ', error);
    }

    return false;
  }

  public async saveContent(content: IBookTable | IBookContent, filename: string): Promise<boolean> {
    const url = `${this.serverUrl}/bookAdmin/lesson/${encodeURIComponent(content.lessonId as string)}/save/${filename}`;
    try {
      //remove the properties we added, but not necessary, in the process of passing data around
      //// eslint-disable-line
      // eslint-disable-next-line
      const { lessonId = '', chapterId = '', bookTitle = '', ...ccc } = content;
      //@typescript-eslint/no-unused-vars

      const response = await this.axiosInstance.post(url, ccc);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  public getEmptyBook(lessonId: string): IBookTable {
    BookTable.sessions[0].title = i18n.t('authoring.Chapter1');
    return {
      title: i18n.t('authoring.NoName'), //"我的新课程1",
      lessonId,
      ...JSON.parse(JSON.stringify(BookTable))
    };
  }

  public async getContent(lessonId: string, filename: string): Promise<IBookTable | IBookContent> {
    const url = `${this.serverUrl}/bookAdmin/lesson/${encodeURIComponent(lessonId)}/content/${filename}`;
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        response.data.lessonId = lessonId; //this looks evil, but, works!
        if (filename !== 'free.json') {
          response.data.chapterId = filename; //this looks evil, but, works!
        }
        return response.data;
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        //the book was first created or the chapter first created, so handle this gracefully
        if (filename === 'free.json') {
          return this.getEmptyBook(lessonId);
        } else {
          //return { title: '404', lessonId, description: '', sessions: [], progress: [] };
          // an empty object for non-existent free.json
          // -- this is okay state when book is first created.
          const emptyLesson = {
            title: '404',
            version: 0,
            sessions: [{ title: '', content: [] }],
            lessonId,
            chapterId: filename
          };
          return emptyLesson;
        }
      }
    }

    //BookTable.lessonId = "1";
    //title 550 --- bad failure!!!
    return {
      title: '550',
      lessonId,
      ...JSON.parse(JSON.stringify(BookTable)),
      progress: []
    };
  }

  public async getLessonPreview(lessonId: string, weekN: string): Promise<ILessonPreview> {
    const url = `${this.serverUrl}/preview/${lessonId}/${weekN}`;
    try {
      const response = await this.axiosInstance.get(url);

      if (response.status === 200) {
        response.data.lessonId = lessonId; //this looks evil, but, works!
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return {
      title: '550',
      lessonId,
      ...JSON.parse(JSON.stringify(BookTable))
    };
  }

  public async getPreviewContent(lessonId: string, filename: string): Promise<IBookTable | IBookContent> {
    const url = `${this.serverUrl}/lesson/${encodeURIComponent(lessonId)}/content/${filename}`;
    try {
      const response = await this.axiosInstance.get(url);

      if (response.status === 200) {
        response.data.lessonId = lessonId; //this looks evil, but, works!
        if (filename !== 'free.json') {
          response.data.chapterId = filename; //this looks evil, but, works!
        }
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return {
      title: '550',
      lessonId,
      ...JSON.parse(JSON.stringify(BookTable))
    };
  }

  //
  // app.post('/group/createDiscussion', useAuth, createDiscussionAsync);
  // sample:
  // request payload: {classId: 334, week: 1, questionId: "C2_JOHN_2_5_1_0"}
  // response payload: {"studyGroupId":49158}
  //
  public async postCreateDiscussion(classId: number, week: number, questionId: string): Promise<number> {
    const url = `${this.serverUrl}/group/createDiscussion`;

    try {
      const response = await this.axiosInstance.post(url, { classId, week, questionId });

      if (response.status === 200) {
        return response.data.studyGroupId;
      }
    } catch (error) {
      console.log(error);
    }

    return 0;
  }

  //app.post('/chat/sendMessage', useAuth, sendChatMessageAsync);
  //  const groupId = getGroupIdFromChatId(req.body.room);
  //      just give room# as the groupId would do...
  //      let message = req.body.message;
  public async postGroupMessage(
    groupId: number,
    message: string,
    replyMessageId = -1,
    mentionedUsers?: any
  ): Promise<boolean> {
    const url = `${this.serverUrl}/chat/sendMessage`;
    try {
      const response = await this.axiosInstance.post(url, { room: groupId, message, replyMessageId, mentionedUsers });
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  public async deleteChatMessage(chatId: number, messageId: number) {
    const url = `${this.serverUrl}/chat/${chatId}/${messageId}`;
    try {
      const response = await this.axiosInstance.delete(url);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  public async getGroupMessage(groupId: number, queryString = ''): Promise<any> {
    const url = `${this.serverUrl}/chat/getMessages/${groupId}?${queryString}`;
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        return response.data.messages;
      }
    } catch (error) {
      console.log(error);
    }

    return [];
  }
  public async uploadFile2Chat(file: any): Promise<string> {
    const formData = new FormData();
    formData.append('chatFile', file);
    const UploadURL = `${this.serverUrl}/chat/uploadFile`;
    try {
      const response = await this.axiosInstance.post(UploadURL, formData, {
        headers: {
          Authorization: getAuthStr()
        }
      });
      console.log('upload file response: ', response);
      if (response.status === 200) {
        const data = await response.data;
        return data.filename;
      }
    } catch (error) {
      console.log('upload file error: ', error);
    }

    return '';
  }

  public async uploadFileWithChatMessage(file: any, groupId: number): Promise<boolean> {
    const formData = new FormData();
    formData.append('chatFile', file);
    const type = file.type.indexOf('image') !== -1 ? 'image' : 'file';
    const queryString = `?groupId=${groupId}&type=${type}&fileSize=${file.size}&displayName=${file.name}`;
    const UploadURL = `${this.serverUrl}/chat/sendFileMessage` + queryString;
    try {
      const response = await this.axiosInstance.post(UploadURL, formData, {
        headers: {
          Authorization: getAuthStr()
        }
      });
      console.log('upload file response: ', response);
      if (response.status === 201) {
        return true;
      }
    } catch (error) {
      console.log('upload file error: ', error);
    }

    return false;
  }

  public async getManagedOrgsAndGroups(): Promise<any> {
    const url = `${this.serverUrl}/organizations`;
    try {
      const response = await this.axiosInstance.get(url);
      if (response.status === 200) {
        //const { /*organizations,*/ groups } = response.data;
        //this.groups = groups;
        //console.log("getManagedOrgsAndGroups", groups);
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }

    return null;
  }

  public async postFeedback(message: string): Promise<boolean> {
    try {
      const response = await this.axiosInstance.post(`${this.serverUrl}/feedback`, { message });
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  public async getGroupInfo(id: number): Promise<any> {
    try {
      const response = await this.axiosInstance.get(`${this.serverUrl}/group/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }

    return null;
  }

  /*
      const result = await callWebServiceAsync(getHttpsServer(`group/${groupId}/meetingLink`));
      const succeed = await showWebServiceCallErrorsAsync(result, 200);
      if (succeed) {
  */
  public async getGroupMeetLink(id: number): Promise<any> {
    try {
      const response = await this.axiosInstance.get(`${this.serverUrl}/group/${id}/meetingLink`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }

    return null;
  }

  async createGroup(groupName: string) {
    try {
      const response = await this.axiosInstance.post(`${this.serverUrl}/group/0/create`, { groupName });
      return response.data;
    } catch (error) {
      console.error('创建新组失败:', error);
      return null;
    }
  }

  async updateGroup(groupId: number, body: any) {
    const url = `${this.serverUrl}/group/${groupId}`;
    try {
      const response = await this.axiosInstance.put(url, body);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.error('创建新组失败:', error);
    }
    return false;
  }

  async respondToRequest(groupId: number, requestUserId: number, accept: boolean) {
    const url = `${this.serverUrl}/group/${groupId}/${accept ? 'accept' : 'reject'}`;
    try {
      const response = await this.axiosInstance.post(url, { requestUserId });
      console.log('response for respondToRequest:', response);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.error('Failed respondToRequest:', error);
    }
    return false;
  }

  // remove student from a class
  // takes groupId and studentId
  async deleteFromGroup(groupId: number, studentId: number) {
    console.log(`delete user to group with ${this.serverUrl}/group/${groupId}/removeUser/${studentId}`);
    try {
      const response = await this.axiosInstance.post(`${this.serverUrl}/group/${groupId}/removeUser/${studentId}`, {});
      console.log('response for delete:', response);
      return 'success';
    } catch (error) {
      console.error('Failed to remove student:', error);
      return null;
    }
  }

  // promotes a member to leader, only works if you are the leader
  async promoteMember(groupId: number, memberUserId: number) {
    try {
      const response = await this.axiosInstance.post(`${this.serverUrl}/group/${groupId}/${memberUserId}/promote`, {});
      console.log('Response for promote:', response);
      return 'success';
    } catch (error) {
      console.error('Failed to promote member:', error);
      if (error.response?.status === 401) {
        console.log('Error 401');
      }
      return null;
    }
  }

  // demotes an admin
  async demoteMember(groupId: number, leaderUserId: number) {
    try {
      const response = await this.axiosInstance.post(`${this.serverUrl}/group/${groupId}/${leaderUserId}/demote`, {});
      console.log('Response for demote:', response);
      return 'success';
    } catch (error) {
      console.error('Failed to demote member:', error);
      if (error.response?.status === 401) {
        console.log('Error 401');
      }
      return null;
    }
  }

  // add student to group
  // uses groupid and studentlogin instead of studentid
  async addUserToGroup(groupId: number, studentLogin: string) {
    console.log(`adding user to group with ${this.serverUrl}/group/${groupId}/addUser/${studentLogin}`);
    try {
      const response = await this.axiosInstance.post(`${this.serverUrl}/group/${groupId}/addUser/${studentLogin}`, {});
      console.log('response for adding user:', response);
      return 'success';
    } catch (error) {
      console.error('Failed to add student:', error);
      return null;
    }
  }

  // Classmanagement and Classregistration api:
  async handleCreateNewTraining(trainingDetails: ITrainingDetails) {
    try {
      const responseTraining = await this.axiosInstance.post(
        `${this.serverUrl}/organizations/${this.orgId}/training`,
        trainingDetails
      );
      const responseGroup = await this.createGroup(trainingDetails.name + '学习小组');
      if (responseGroup) {
        await this.handleAddGroup2Training(responseGroup.response.studyGroupId, responseTraining.data.trainingId);
      }

      return responseTraining.data;
    } catch (error) {
      console.error('创建新课程失败:', error);
      return null;
    }
  }

  async handleAddGroup2Training(groupId: number, trainingId: number) {
    try {
      await this.axiosInstance.post(
        `${this.serverUrl}/organizations/${this.orgId}/trainings/${trainingId}/groups/${groupId}`,
        {}
      );
    } catch (error) {
      console.error('添加学习小组失败:', error);
    }
  }

  async fetchTrainings() {
    try {
      console.log('orgid = : ', this.orgId);
      const response = await this.axiosInstance.get(`${this.serverUrl}/organizations/${this.orgId}/trainings`);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('获取课程列表失败:', error);
      return [];
    }
  }

  async fetchTrainingDetail(trainingId: number) {
    try {
      const response = await this.axiosInstance.get(
        `${this.serverUrl}/organizations/${this.orgId}/trainings/${trainingId}`
      );

      if (response.status === 200) {
        console.log('获取课程详细信息: ', response.data);
        return response;
      } else {
        return { groups: [], classes: [], orgClasses: [], status: [] };
      }
    } catch (error) {
      console.error('获取课程详细信息失败:', error);
      return { groups: [], classes: [], orgClasses: [], status: [] };
    }
  }

  async fetchCourses(): Promise<ICourse[] | undefined> {
    try {
      const response = await this.axiosInstance.get(`${this.serverUrl}/user/classes`);

      if (response.status === 200) {
        console.log('user/classes: courses: ', response);
        return response.data.classes
          .filter((it: any) => it.orgId === this.orgId && it.status !== -1 && it.status !== 0)
          .map((course: any) => {
            return {
              // ...course,
              ClassId: course.name,
              ClassNameChs: course.classNameChs
            };
          });
      }
    } catch (error) {
      console.error('获取课程信息失败:', error);
      return [];
    }
  }

  async handleAddClassToTraining(trainingId: number, classId: number) {
    try {
      const response = await this.axiosInstance.post(
        `${this.serverUrl}/organizations/${this.orgId}/trainings/${trainingId}/classes/${classId}`,
        {}
      );
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      console.error('添加课程失败:', error);
      return false;
    }
  }

  async handleRenameTraining(trainingId: number, newName: string): Promise<boolean> {
    try {
      const response = await this.axiosInstance.put(
        `${this.serverUrl}/organizations/${this.orgId}/trainings/${trainingId}`,
        { name: newName }
      );

      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('课程名称更新错误:', error);
      return false;
    }
  }

  async saveTrainingData(trainingId: number, data: any): Promise<boolean> {
    try {
      const response = await this.axiosInstance.put(
        `${this.serverUrl}/organizations/${this.orgId}/trainings/${trainingId}`,
        { data }
      );

      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('课程名称更新错误:', error);
      return false;
    }
  }

  async updateTrainingStatus(trainingId: number, newStatus: number): Promise<boolean> {
    try {
      const response = await this.axiosInstance.put(
        `${this.serverUrl}/organizations/${this.orgId}/trainings/${trainingId}`,
        { status: newStatus }
      );

      return response.status === 200;
    } catch (error) {
      console.error('更新培训状态失败:', error);
      return false;
    }
  }

  async handleDeleteTraining(trainingId: number): Promise<boolean> {
    try {
      const response = await this.axiosInstance.delete(
        `${this.serverUrl}/organizations/${this.orgId}/trainings/${trainingId}`
      );

      return response.status === 200;
    } catch (error) {
      console.error('删除培训失败:', error);
      return false;
    }
  }

  async handleRemoveClassFromTraining(trainingId: number, classId: number): Promise<boolean> {
    try {
      console.log('handleRemoveClassFromTraining classid is:', classId);
      const response = await this.axiosInstance.delete(
        `${this.serverUrl}/organizations/${this.orgId}/trainings/${trainingId}/classes/${classId}`
      );

      return response.status === 200;
    } catch (error) {
      console.error('删除课程失败:', error);
      return false;
    }
  }

  async fetchTrainingStatus(trainingId: number): Promise<void> {
    try {
      const response = await this.axiosInstance.get(
        `${this.serverUrl}/organizations/${this.orgId}/trainings/${trainingId}`
      );

      if (response.status === 200) {
        console.log('获取培训状态成功');
      }
    } catch (error) {
      console.error('获取培训状态失败:', error);
    }
  }

  async handleRemoveGroupFromTraining(trainingId: number, groupId: number): Promise<boolean> {
    try {
      const response = await this.axiosInstance.delete(
        `${this.serverUrl}/organizations/${this.orgId}/trainings/${trainingId}/groups/${groupId}`
      );
      return response.status === 200;
    } catch (error) {
      console.error('移除学习小组失败:', error);
      return false;
    }
  }

  async getUsersByIds(userIds: number[], trainingId: number): Promise<any[]> {
    try {
      const response = await this.axiosInstance.get(
        `${this.orgServerUrl}/trainings/${trainingId}/users/${userIds.join(',')}`
      );

      if (response.status === 200) {
        return response.data.map((user: any) => {
          let userInfo = {};
          if (user.Data && typeof user.Data === 'string') {
            try {
              const parsedData = JSON.parse(user.Data);
              userInfo = JSON.parse(parsedData);
            } catch (error) {
              console.error('Error parsing user data:', error);
            }
          }
          return {
            ...userInfo,
            userId: user.userId
          };
        });
      } else {
        console.error('获取用户信息失败: 响应状态非200');
        return [];
      }
    } catch (error) {
      console.error('获取用户信息失败:', error);
      return [];
    }
  }

  async getTrainingAllUsers(trainingId: number): Promise<IStudent[]> {
    try {
      if (!this.orgServerUrl) {
        return [];
      } else {
        const response = await this.axiosInstance.get(`${this.orgServerUrl}/trainings/${trainingId}/users`);
        if (response.status === 200) {
          return response.data
            .map((user: any) => {
              try {
                const userData = JSON.parse(JSON.parse(user.Data));
                console.log('userData: ', userData);
                return {
                  key: user.userId,
                  realName: userData.realName || '',
                  trainingGroup: '',
                  displayName: userData.displayName || '',
                  uniqueId: '',
                  gender: userData.gender || '',
                  phone: userData.phone || '',
                  email: userData.email || '',
                  wechatId: userData.weChat || '',
                  education: userData.education || '',
                  provinceCity: userData.provinceCity || '',
                  yearsAsChristian: userData.yearsAsChristian || '',
                  careExperience: userData.careExperience || '',
                  trainingExpectation: userData.trainingExpectation || ''
                } as IStudent;
              } catch (error) {
                console.error('Error parsing user data:', error);
                return null;
              }
            })
            .filter((student: IStudent | null): student is IStudent => student !== null);
        } else {
          console.error('获取所有用户信息失败: 响应状态非200');
          return [];
        }
      }
    } catch (error) {
      console.error('获取所有用户信息失败:', error);
      return [];
    }
  }

  async getTrainingUser(trainingId: number): Promise<any> {
    try {
      const response = await this.axiosInstance.get(`${this.orgServerUrl}/trainings/${trainingId}/user`);

      return response.data;
    } catch (error) {
      console.error('获取用户信息失败:', error);
      throw error;
    }
  }

  async fetchStudentsForGroup(groupId: number, trainingId: number): Promise<IStudent[]> {
    try {
      const groupInfo = await this.getGroupInfo(groupId);
      if (!groupInfo || !groupInfo.users) {
        return [];
      }

      let students = [];
      if (!this.orgServerUrl) {
        //there is no org specific user data
        students = groupInfo.users.map((user: any) => {
          return {
            ...user,
            key: user.userId,
            trainingGroup: groupInfo.name,
            displayName: user.name,
            uniqueId: user.loginId
          };
        });
      } else {
        const allUsers = await this.getTrainingAllUsers(trainingId);
        students = allUsers
          .filter((user) => groupInfo.users.some((groupUser: IGroupUser) => groupUser.userId === user.key))
          .map((user) => {
            const groupUser = groupInfo.users.find((groupUser: IGroupUser) => groupUser.userId === user.key);

            return {
              ...user,
              trainingGroup: groupInfo.name,
              displayName: groupUser ? groupUser.name : '',
              uniqueId: groupUser ? groupUser.loginId : ''
            };
          });
      }
      console.log('学员信息:', students);
      return students;
    } catch (error) {
      console.error('获取小组学员信息失败:', error);
      return [];
    }
  }

  async checkUserRegistration(
    orgId: string,
    trainingId: string,
    joinToken: string
  ): Promise<{ canJoin?: boolean; error?: string }> {
    try {
      const response = await this.axiosInstance.get(
        `${this.serverUrl}/organizations/${orgId}/trainings/${trainingId}/joincheck/${joinToken}`
      );

      return { canJoin: response.data.canJoin };
    } catch (error) {
      console.error('检查报名状态失败:', error);
      if (error.response?.status === 500) {
        const message = error.response.data.error || '内部服务器错误';
        if (message === 'Training is not in registration or cannot find training!') {
          return { error: '报名尚未开始或未找到该培训' };
        }
        return { error: message };
      }
      return { error: '网络错误或服务器无法访问' };
    }
  }

  async submitRegistrationData(submissionData: any, trainingId: string): Promise<boolean> {
    const userDataJsonString = JSON.stringify(submissionData);

    try {
      const response = await this.axiosInstance.put(`${this.orgServerUrl}/trainings/${trainingId}/user`, {
        Data: userDataJsonString
      });

      if (response.status === 201) {
        console.log('提交成功:', response);
        return true;
      } else {
        console.error('提交失败，响应状态码:', response.status);
        return false;
      }
    } catch (error) {
      console.error('最终提交失败:', error);
      return false;
    }
  }

  async joinTrainingGroup(trainingId: string, joinToken: string) {
    try {
      const joinResponse = await this.axiosInstance.post(
        `${this.serverUrl}/organizations/${this.orgId}/trainings/${trainingId}/join/${joinToken}`,
        null
      );

      if (joinResponse.status === 201 || joinResponse.status === 200) {
        console.log('加入小组成功');
        return joinResponse.data;
      } else {
        console.error('加入小组失败，响应状态码:', joinResponse.status);
        throw new Error(`加入小组失败，响应状态码: ${joinResponse.status}`);
      }
    } catch (error) {
      console.error('请求失败:', error);
      throw error;
    }
  }

  async deleteRegistration(trainingId: string, userIds: number[]) {
    try {
      const deleteResponse = await this.axiosInstance.delete(
        `${this.serverUrl}/trainings/${trainingId}/users/${userIds}`
      );

      if (deleteResponse.status === 200) {
        console.log('成功删除注册信息');
        return true;
      } else {
        console.error('删除注册信息失败，响应状态码:', deleteResponse.status);
        return false;
      }
    } catch (error) {
      console.error('删除注册请求失败:', error);
      return false;
    }
  }

  async resetPassword(email: string): Promise<boolean> {
    if (!email || email.trim().length === 0) {
      console.error('Email is required for resetting password.');
      return false;
    }

    try {
      const response = await this.axiosInstance.put(`${this.serverUrl}/user/resetPassword`, { loginId: email.trim() });
      return response.status === 200;
    } catch (error) {
      console.error('Reset password failed:', error.response?.data || error.message);
      return false;
    }
  }

  async newPassword(newPass: string): Promise<boolean> {
    try {
      const response = await this.axiosInstance.put(`${this.serverUrl}/user/profile`, { password: newPass });
      return response.status === 200;
    } catch (error) {
      console.error('Reset password failed:', error.response?.data || error.message);
      return false;
    }
  }

  public async signIn(loginId: string, password: string): Promise<any> {
    try {
      const response = await axios.post(`${this.serverUrl}/user/login`, {
        loginId,
        password
      });

      if (response.status === 200) {
        const signInState = {
          loginId: loginId,
          ...response.data
        };

        localStorage.setItem('SignInState', JSON.stringify(signInState));
        localStorage.setItem('access_token', response.data.accessToken);

        return { success: true, data: response.data };
      } else {
        return { success: false, error: '登录失败' };
      }
    } catch (error) {
      let errorMessage = '登录失败';
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      return { success: false, error: errorMessage };
    }
  }

  async getFileListInGroup(groupId: number, dayRange = 36): Promise<IChatMessage[] | null> {
    try {
      const response = await this.axiosInstance.get(`${this.serverUrl}/chat/${groupId}/files?fromDays=${dayRange}`);
      if (response.status === 200) {
        const files = response.data as Array<IChatMessage>;
        return files.sort((a, b) => b.time - a.time);
      }
      throw new Error(`response is not 200: ${response.status}`);
    } catch (error: any) {
      console.error('GetFileListInGroup:', error);
      return null;
    }
  }

  getFileUrlInChat(groupId: number, fileId: number): string {
    return `${this.serverUrl}/chat/${groupId}/files/${fileId}`;
  }

  async deleteUser(): Promise<string> {
    if (!isUserSignedIn()) {
      return 'NOT_SIGNED_IN';
    }
    const url = `${this.serverUrl}/user/delete`;
    try {
      const response = await this.axiosInstance.delete(url);
      if (response.status === 200) {
        wipeSignInState();
        return 'SUCCESS';
      }
    } catch (error) {
      console.log(error);
    }

    return 'FAILED';
  }

  async getUsersInRole(contextId: string | number, role: string): Promise<IUser[]> {
    try {
      let apiUrl;
      switch (role) {
        case 'Author':
          apiUrl = `${this.serverUrl}/bookAdmin/lesson/${contextId}/authors`;
          break;
        case 'Admin':
          apiUrl = `${this.serverUrl}/organizations/${contextId}/admins`;
          break;
        default:
          return [];
      }
      const response = await this.axiosInstance.get(apiUrl);
      if (response.status === 200) {
        const ru = response.data as IUser[];
        if (role === 'Admin') {
          if (contextId !== 1) {
            return ru.filter((uu) => [3, 15, 68].indexOf(uu.userId) === -1);
          }
        }
        return ru;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  async removeSingleRoleUser(role: string, contextId: string, userId: number): Promise<boolean> {
    try {
      let apiUrl;
      switch (role) {
        case 'Author':
          apiUrl = `${this.serverUrl}/bookAdmin/lesson/${contextId}/authors/${userId}`;
          break;
        case 'Admin':
          apiUrl = `${this.serverUrl}/organizations/${contextId}/admins/${userId}`;
          break;
        default:
          return false;
      }
      const response = await this.axiosInstance.delete(apiUrl);
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  async addSingleRoleUser(role: string, contextId: string, userId: number): Promise<boolean> {
    console.log('addSingleRoleUser: ', role, contextId, userId);
    try {
      let apiUrl;
      switch (role) {
        case 'Author':
          apiUrl = `${this.serverUrl}/bookAdmin/lesson/${contextId}/authors/${userId}`;
          break;
        case 'Admin':
          apiUrl = `${this.serverUrl}/organizations/${contextId}/admins/${userId}/1`;
          break;
        default:
          return false;
      }
      const response = await this.axiosInstance.post(apiUrl);
      console.log('addSingleRoleUser 2: ', response);
      if (response.status === 201) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  async launchUrl(launchUrl: string) {
    try {
      const response = await this.axiosInstance.post(`${this.serverUrl}/user/launchUrl`, { launchUrl });
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(`response is not 200: ${response.status}`);
    } catch (error: any) {
      console.error('network call error: ', error);
      return null;
    }
  }
}

export const _dataProvider = new DataProvider('');
