import React from 'react';
//import ReactNative from 'react-native-web';
import { Text } from 'react-native-web';
import PropTypes from 'prop-types';

import TextExtraction from './TextExtraction';

const PATTERNS = {
  //eslint-disable-next-line
  url: /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/i,
  //eslint-disable-next-line
  phone: /[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}/,
  email: /\S+@\S+\.\S+/
};

const defaultParseShape = PropTypes.shape({
  //eslint-disable-next-line
...Text.propTypes,
  type: PropTypes.oneOf(Object.keys(PATTERNS)).isRequired
});

const customParseShape = PropTypes.shape({
  //eslint-disable-next-line
...Text.propTypes,
  pattern: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]).isRequired
});

class ParsedText extends React.Component {
  static displayName = 'ParsedText';

  static propTypes = {
    ...Text.propTypes,
    parse: PropTypes.arrayOf(PropTypes.oneOfType([defaultParseShape, customParseShape])),
    childrenProps: PropTypes.shape(Text.propTypes)
  };

  static defaultProps = {
    parse: null
  };

  setNativeProps(nativeProps) {
    this._root.setNativeProps(nativeProps);
  }

  getPatterns() {
    return this.props.parse.map((option) => {
      const { type, ...patternOption } = option;
      if (type) {
        if (!PATTERNS[type]) {
          throw new Error(`${option.type} is not a supported type`);
        }
        patternOption.pattern = PATTERNS[type];
      }

      return patternOption;
    });
  }

  getParsedText() {
    if (!this.props.parse) {
      return this.props.children;
    }
    if (typeof this.props.children !== 'string') {
      return this.props.children;
    }

    const textExtraction = new TextExtraction(this.props.children, this.getPatterns());
    const childrenProps = this.props.childrenProps || {};
    const props = { ...this.props };
    delete props.childrenProps;
    return textExtraction
      .parse()
      .map((props, index) => <Text key={`parsedText-${index}`} {...childrenProps} {...props} />);
  }

  render() {
    const props = { ...this.props };
    delete props.childrenProps;
    return (
      <Text ref={(ref) => (this._root = ref)} {...props}>
        {this.getParsedText()}
        {this.props.currentMessage.icon && (
          <img src={this.props.currentMessage.icon.image} style={this.props.currentMessage.icon.style} />
        )}
      </Text>
    );
  }
}

export default ParsedText;
