import React from 'react';
import { IUser } from '../dataProvider/DataProvider';
//import { DefaultButton } from '@fluentui/react';
import { userAvatarName } from './UserAvatarName';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { DefaultButton } from '@fluentui/react';

export interface ISelectedUserProps {
  user: IUser | undefined;
  action: any;
}

export const SelectedUser: React.FunctionComponent<ISelectedUserProps> = ({ user, action }) => {
  if (!user) {
    return null; // Render nothing if user is undefined
  }
  return (
    <Dialog
      hidden={false}
      onDismiss={() => {
        action(0);
      }}
      dialogContentProps={{
        showCloseButton: true
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { borderRadius: '20px' } }
      }}>
      <div className='selected-user-container'>
        <br />
        {userAvatarName(user, 2, true)}
      </div>
      {!!action && (
        <div className='button-container'>
          {!!action(1, user) && (
            <DefaultButton style={{ padding: 0, margin: 'auto' }} onClick={() => action(1, user, true)}>
              {action(1)}
            </DefaultButton>
          )}
          {!!action(2, user) && (
            <DefaultButton style={{ padding: 0 }} onClick={() => action(2, user, true)}>
              {action(2, user)}
            </DefaultButton>
          )}
        </div>
      )}
    </Dialog>
  );
};
