import React, { useState } from 'react';
import { GroupedList, IGroup, IGroupHeaderProps } from '@fluentui/react/lib/GroupedList';
import { IColumn, SelectionMode, DetailsRow } from '@fluentui/react/lib/DetailsList';
import './CourseProgressReport.css';

// 模拟数据
const mockData = [
  {
    sectionId: '1',
    sectionCompleteness: '75%',
    subSection: {
      分单元1: '80%',
      分单元2: '70%',
      分单元3: '60%',
      分单元4: '50%'
    }
  },
  {
    sectionId: '2',
    sectionCompleteness: '65%',
    subSection: {
      分单元1: '90%',
      分单元2: '40%',
      分单元3: '70%'
    }
  },
  {
    sectionId: '3',
    sectionCompleteness: '75%',
    subSection: {
      分单元1: '10%',
      分单元2: '60%',
      分单元3: '60%'
    }
  },
  {
    sectionId: '4',
    sectionCompleteness: '62%',
    subSection: {
      分单元1: '98%',
      分单元2: '4%',
      分单元3: '75%'
    }
  }
];

const columns: IColumn[] = [
  {
    key: 'subsection',
    name: '分单元',
    fieldName: 'subsection',
    minWidth: 300,
    maxWidth: 400,
    isResizable: true
  },
  {
    key: 'completion',
    name: '完成率',
    fieldName: 'completion',
    minWidth: 500,
    maxWidth: 600,
    isResizable: true,
    onRender: (item) => (
      <div
        style={{
          width: '100%',
          paddingRight: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        {item.completion}
      </div>
    )
  }
];

const CourseProgressReport: React.FC = () => {
  const items: any[] = [];
  const groups: IGroup[] = [];

  mockData.forEach((unit) => {
    groups.push({
      key: `group${unit.sectionId}`,
      name: `单元${unit.sectionId}`,
      startIndex: items.length,
      count: Object.keys(unit.subSection).length,
      isCollapsed: true
    });

    Object.entries(unit.subSection).forEach(([key, value]) => {
      items.push({
        key: `unit${unit.sectionId}-${key}`,
        subsection: key,
        completion: value
      });
    });
  });

  const [groupedListGroups, setGroupedListGroups] = useState<IGroup[]>(groups);

  const onGroupHeaderClick = (clickedGroup: IGroup): void => {
    const newGroups = groupedListGroups.map((g) => ({
      ...g,
      isCollapsed: g.key === clickedGroup.key ? !g.isCollapsed : true
    }));

    setGroupedListGroups(newGroups);
  };

  const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element | null => {
    if (!props || !props.group) {
      return null;
    }

    const group = props.group;
    const sectionData = mockData.find((data) => `group${data.sectionId}` === group.key);
    const isCollapsed = group.isCollapsed;

    const headerStyles = {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: isCollapsed ? '#f5f5f5' : '#7c7c7c',
        color: isCollapsed ? 'black' : 'white',
        borderRadius: '20px',
        padding: '5px 10px',
        cursor: 'pointer',
        fontSize: '2em',
        width: '100%'
      },
      completionRate: {
        marginLeft: 'auto',
        paddingRight: '10px'
      },
      toggle: {
        paddingLeft: '10px'
      }
    };

    return (
      <div
        style={headerStyles.root}
        onClick={() => {
          onGroupHeaderClick(group);
        }}>
        <span>{group.name}</span>
        <span style={headerStyles.completionRate}>{sectionData ? sectionData.sectionCompleteness : ''}</span>
        <span style={headerStyles.toggle}>{group.isCollapsed ? '▼' : '▲'}</span>
      </div>
    );
  };

  const onRenderCell = (nestingDepth?: number, item?: any, itemIndex?: number): JSX.Element => {
    const customStyles = {
      root: {
        fontSize: '1.25em'
      }
    };
    return (
      <DetailsRow
        columns={columns}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex!}
        selectionMode={SelectionMode.none}
        styles={customStyles}
      />
    );
  };

  return (
    <div className='groupedListContainer'>
      <div
        style={{
          color: 'red',
          fontSize: '20px',
          textAlign: 'center',
          marginBottom: '20px'
        }}>
        该功能待开发，当前页面仅供参考
      </div>
      <GroupedList
        items={items}
        onRenderCell={onRenderCell}
        groups={groupedListGroups}
        groupProps={{
          headerProps: {
            onToggleCollapse: onGroupHeaderClick
          },
          onRenderHeader: onRenderHeader
        }}
        selectionMode={SelectionMode.none}
      />
    </div>
  );
};

export default CourseProgressReport;
