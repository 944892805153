import React, { useState, ChangeEvent } from 'react';
import { IUser } from '../dataProvider/DataProvider';
import { DefaultButton } from '@fluentui/react';
import { SearchBox } from '@fluentui/react';
import i18n from '../i18n';
import './GroupMemberTool.css';
import { userAvatarName } from '../SharedCommon/UserAvatarName';

interface IChecklistContainerProps {
  otherUsers: IUser[]; // represents what users can be displayed in checklist
  selectedUsers: string[]; // Imported state from parent component
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>; // Setter function for selectedUsers
  handleUpdate: () => void; // function called when button is pressed
  addMemberButtonText: string; // text for button
  pickSingleUser?: boolean;
}

const ChecklistContainer: React.FC<IChecklistContainerProps> = ({
  otherUsers,
  selectedUsers,
  setSelectedUsers,
  handleUpdate,
  addMemberButtonText,
  pickSingleUser
}) => {
  const [textBoxInput, setTextBoxInput] = useState('');
  const [searchValue, setSearchValue] = useState('');

  // when text box inputchanges, split it based on commas
  // only when the user types into the box
  // then set selectedUsers to the text between commas
  const handleTextBoxInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const input = event.target.value;
    setTextBoxInput(input);
    const ids = input
      .split(',')
      .map((id) => id.trim())
      .filter((id) => id !== '');
    setSelectedUsers(ids); // Update selectedUsers in the parent component
  };

  // when checkbox is changed, uncheck or check it
  // inaddition to that, change the input box
  const handleCheckboxChange = (loginId: string) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (pickSingleUser) {
        setTextBoxInput(loginId);
        return [loginId];
      } else {
        const updatedSelectedUsers = prevSelectedUsers.includes(loginId)
          ? prevSelectedUsers.filter((id) => id !== loginId)
          : [...prevSelectedUsers, loginId];
        // Set the textBoxInput as a comma-separated string of selectedUsers
        setTextBoxInput(updatedSelectedUsers.join(','));
        return updatedSelectedUsers;
      }
    });
  };

  // renders check boxes for 1 user
  const renderCheckBox = (user: IUser) => (
    <div key={user.userId} className='checklist-item'>
      <label className='checkbox-label'>
        <input
          type={pickSingleUser ? 'radio' : 'checkbox'}
          checked={selectedUsers.includes(user.loginId.toString())}
          onChange={() => handleCheckboxChange(user.loginId.toString())}
        />
        <div className='checkbox-content'>
          <span style={{ marginRight: '10px' }}>{userAvatarName(user, 0, false, '30px')}</span>
          <div className='user-info'>
            <span>{user.name || user.displayName}</span>
          </div>
        </div>
      </label>
    </div>
  );

  // check boxes, filtered based off of a search box
  const renderCheckBoxes = () => {
    const filteredUsers = otherUsers.filter((user) =>
      (user.name || user.displayName).toLowerCase().includes(searchValue.toLowerCase())
    );

    return (
      <div>
        <SearchBox
          placeholder={i18n.t('Search by name or login ID')}
          onChange={(event, newValue) => setSearchValue(newValue || '')}
          value={searchValue}
          styles={{ root: { marginBottom: '10px', marginTop: '10px' } }}
          onClear={() => setSearchValue('')}
        />
        {filteredUsers.map(renderCheckBox)}
      </div>
    );
  };

  return (
    <div className='checklist-container'>
      {!pickSingleUser && <h3>{i18n.t('AddMembers')}</h3>}
      {!pickSingleUser && i18n.t('AddMembersInstruction')}
      {!!pickSingleUser && (
        <textarea readOnly value={textBoxInput} onChange={handleTextBoxInputChange} className='selected-users-box' />
      )}
      {!pickSingleUser && (
        <textarea value={textBoxInput} onChange={handleTextBoxInputChange} className='selected-users-box' />
      )}
      <DefaultButton
        onClick={() => {
          handleUpdate();
          setTextBoxInput('');
        }}>
        {i18n.t(addMemberButtonText)}
      </DefaultButton>
      <div className='checklist'>{renderCheckBoxes()}</div>
    </div>
  );
};

export default ChecklistContainer;
