import React from 'react';

import './Org.css';
//import { IAppSessionSettings } from "../dataProvider/IAppSessionSettings";

import { DefaultButton } from '@fluentui/react/lib/Button';
import { SearchBox, ISearchBoxStyles } from '@fluentui/react/lib/SearchBox';

import { _dataProvider } from '../dataProvider/DataProvider';
import groupLeader from '../img/icon-Group-leader.png';
//import groupMember from "../img/icon-Group-member-regular.png";
import checkMark from '../img/icon-checkmark.png';

const colorArray = ['yellow', 'green', 'blue', 'red'];

interface IGroupsDetailProps {
  groupList: any[];
  specificgroup: number; //the group picked at the entry
  headerHeight: number;
}

interface IGroupsDetailState {
  groups: any;
  users: any;
  specificgroup: any;
  previousId: any;
  loading: boolean;
  tabIndex: any;
}

class GroupsDetail extends React.Component<IGroupsDetailProps, IGroupsDetailState> {
  private _groupRecent3Count: any;
  private _groupRecent5Count: any;
  private _groupRecentTotCount: any;
  private _uList: any;

  constructor(props: IGroupsDetailProps) {
    super(props);
    this._groupRecent3Count = [];
    this._groupRecent5Count = [];
    this._groupRecentTotCount = [];
    this._uList = [];

    this.state = {
      groups: [],
      users: [],
      specificgroup: props.specificgroup,
      previousId: null,
      loading: true,
      tabIndex: undefined
    };
  }

  /* too big a loop over http
  async load() {
    const usersList = [];
    const groupsList = [];
    for (let i = 0; i < this.props.groupList.length; i++) {
      const { attendance, users } =
        await _dataProvider.getSpecificGroupAttendance(
          this.props.groupList[i].Id
        );
      groupsList.push(attendance);
      usersList.push(users);
    }
    await this.setState({
      groups: groupsList,
      users: usersList,
      loading: false,
      tabIndex: 1,
    });
  }
  */

  async loadSpecificGroup(groupIdx: number) {
    const { groups } = this.state;
    if (groups[groupIdx]) {
      this.setState({ specificgroup: groupIdx });
      return; // already loaded
    }

    const usersList = this.state.users;

    const { attendance, users } = await _dataProvider.getSpecificGroupAttendance(this.props.groupList[groupIdx].Id);
    groups[groupIdx] = attendance.reverse();
    usersList[groupIdx] = users;

    /// algorithm ===
    /// _groupRecent3Count[groupIdx][userId] === the total count of attendance of last 3 record
    /// _groupRecent5Count[groupIdx][userId] === the total count of attendance of last 5 record
    /// _groupRecentTotCount[groupIdx][userId] === the total count of attendance of the user
    /// The significance
    /// those who need welcome / strengthening:  recent_3 > 0, recent_3 === tot
    ///     this would indicate this is a brand new user, joined in last 3 weeks
    /// those who are in danger of losing:  recent_3===0, but recent_5 > 0.
    /// those who are definitely lost: recent_5 === 0

    this._groupRecent3Count[groupIdx] = [];
    this._groupRecent5Count[groupIdx] = [];
    this._groupRecentTotCount[groupIdx] = [];

    groups[groupIdx].forEach((item: any, index: number) => {
      item.users.forEach((userId: number) => {
        if (index < 3) {
          this._groupRecent3Count[groupIdx][userId] = (this._groupRecent3Count[groupIdx][userId] || 0) + 1;
        }
        if (index < 5) {
          this._groupRecent5Count[groupIdx][userId] = (this._groupRecent5Count[groupIdx][userId] || 0) + 1;
        }
        this._groupRecentTotCount[groupIdx][userId] = (this._groupRecentTotCount[groupIdx][userId] || 0) + 1;
      });
    });

    this._uList[groupIdx] = Object.keys(usersList[groupIdx]);

    this._uList[groupIdx].forEach((user: string) => {
      const userId = parseInt(user);
      if (!this._groupRecent5Count[groupIdx][userId]) {
        usersList[groupIdx][userId].color = 3;
      }
      //'lost';
      else if (!this._groupRecent3Count[groupIdx][userId]) {
        usersList[groupIdx][userId].color = 0;
      }
      //'danger';
      else if (this._groupRecent3Count[groupIdx][userId] === this._groupRecentTotCount[groupIdx][userId]) {
        usersList[groupIdx][userId].color = 1;
      }
      //'welcome';
      else {
        usersList[groupIdx][userId].color = 2;
      } //'regular';
    });

    //this._uList[groupIdx] =
    this._uList[groupIdx].sort(
      (u1: string, u2: string) => usersList[groupIdx][parseInt(u1)].color - usersList[groupIdx][parseInt(u2)].color
    );

    this.setState({
      groups,
      users: usersList,
      loading: false,
      tabIndex: 1,
      specificgroup: groupIdx
    });
  }

  public async componentDidMount(): Promise<void> {
    await this.loadSpecificGroup(this.state.specificgroup);
  }

  public renderByGroupsDetails() {
    const { groups, users } = this.state;
    let index = 0;

    const groupIdx = this.state.specificgroup;
    const groupData = groups[groupIdx];

    // let userIndex = 1;
    return (
      <div key={index++} className='responsiveDiv'>
        <table id='attendance'>
          <thead>
            <tr>
              <th>
                <b>
                  <SearchBoxGroups></SearchBoxGroups>
                </b>
              </th>
              {groupData.map((item: { date: any }) => (
                <th key={index++}>{item.date?.toString().split('T')[0].replaceAll('-', '/')}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this._uList[groupIdx].map((user: string) => {
              return (
                <tr key={index++}>
                  <td
                    style={{
                      backgroundColor: colorArray[users[groupIdx][parseInt(user)].color]
                    }}>
                    <span style={{ color: 'black' }}>{users[groupIdx][parseInt(user)].name}</span>
                  </td>
                  {groupData.map((item: { users: number[] }) => {
                    return (
                      <td key={index++}>
                        {item.users.indexOf(parseInt(user)) === -1 ? (
                          ''
                        ) : (
                          <img alt='check mark' src={checkMark} style={{ width: '10px', height: '10px' }}></img>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  /*
            <h2 style={{ color: "grey", marginLeft: 40, marginBottom: "15px" }}>
              <img
                src={GreySquare}
                alt="square"
                style={{ width: 20, height: 20 }}
              ></img>{" "}
              小组
            </h2>
*/
  render(): React.ReactNode {
    const { tabIndex, loading } = this.state;
    if (loading) {
      return <h1>Loading...</h1>;
    }

    return (
      <div>
        <div>
          <div className='table'>
            <div key='div1'>
              <label style={{ color: 'black', marginLeft: 40, fontWeight: 'bold' }}>
                <button className='bookCategoryMark' />
                小组
              </label>
            </div>
            <div>
              {this.props.groupList.map((item, index) => (
                <ul key={index}>
                  <DefaultButton
                    styles={{
                      flexContainer: {
                        justifyContent: 'left',
                        marginLeft: 25
                      }
                    }}
                    style={{
                      width: 250,
                      backgroundColor: this.state.specificgroup === index ? '#7E7E7E' : '#E8E8E8',
                      color: this.state.specificgroup === index ? 'white' : 'black',
                      border: 'none',
                      textAlign: 'left'
                    }}
                    id={item.Id}
                    onClick={() => {
                      this.loadSpecificGroup(index);
                      //this.changeButtonColor(item.Id);
                    }}>
                    <img
                      id='GroupImage'
                      src={groupLeader}
                      alt='Group Symbol'
                      style={{ position: 'absolute', left: 5 }}></img>
                    {item.Name}
                    <span style={{ position: 'absolute', right: 5 }}>{this.props.groupList[index].Size}</span>
                  </DefaultButton>
                </ul>
              ))}
            </div>
          </div>
          {tabIndex === 1 && <>{this.renderByGroupsDetails()}</>}
        </div>
      </div>
    );
  }
}

const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: { width: 180, height: 20 }
};

function filterFunction() {
  let filter, table, tr, td, i, txtValue;
  const input = document.getElementById('input');
  if (input !== null) {
    filter = (input as HTMLInputElement).value.toUpperCase();
    table = document.getElementById('attendance');
    if (table !== null) {
      tr = table.getElementsByTagName('tr');
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName('td')[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = '';
          } else {
            tr[i].style.display = 'none';
          }
        }
      }
    }
  }
}

export const SearchBoxGroups = () => (
  <SearchBox
    id='input'
    styles={searchBoxStyles}
    placeholder='Search'
    onEscape={() => filterFunction()}
    onClear={() => filterFunction()}
    onChange={() => filterFunction()}
    onSearch={() => filterFunction()}
  />
);

export default GroupsDetail;
