import React, { useState } from 'react';
import { _dataProvider, IUser } from '../dataProvider/DataProvider';
import { userAvatarName } from './UserAvatarName';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { DefaultButton } from '@fluentui/react';
import i18n from '../i18n';

export interface IUserProfileDialogProps {
  user: IUser | undefined;
  dismiss: any;
}

export interface IUserProfileProps {
  user: IUser | undefined;
  updateFrameStyle?: any;
}

export const UserProfile: React.FunctionComponent<IUserProfileProps> = ({ user, updateFrameStyle }) => {
  if (!user) {
    return null; // Render nothing if user is undefined
  }
  const [block, setBlock] = useState(user.block);
  const [tag, setTag] = useState(_dataProvider.getMyTagForUser(user.id || user.userId));

  const updateTag = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTag(ev.target.value);
  };

  return (
    <div>
      <div className='selected-user-container'>
        <br />
        {userAvatarName(user, 2, true)}
      </div>
      <div style={updateFrameStyle}>
        <div className='avatar-name'>
          <h3>{i18n.t('MemberScreen.UserTag')}</h3>
          <input
            key='titleText'
            placeholder={tag ? tag : i18n.t('MemberScreen.NoTag')}
            value={tag}
            onChange={updateTag}
          />
        </div>
        <div className='avatar-name'>
          <h3>
            <input type={'checkbox'} checked={block} onChange={() => setBlock(!block)} />
            <span>{i18n.t('MemberScreen.BlockUser')}</span>
          </h3>
          <div className='checkbox-content'>{i18n.t('MemberScreen.BlockUserText')}</div>
        </div>
        <br></br>
        <div className='button-container'>
          <DefaultButton
            style={{ padding: 0, margin: 'auto' }}
            onClick={async () => {
              const result = await _dataProvider.updateContactForUser(user.id || user.userId, tag, Number(!!block));
              alert(i18n.t(result ? 'MemberScreen.UpdateSuccess' : 'MemberScreen.UpdateFail'));
            }}>
            {i18n.t('Update')}
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export const UserProfileDialog: React.FunctionComponent<IUserProfileDialogProps> = ({ user, dismiss }) => {
  return (
    <Dialog
      hidden={false}
      onDismiss={() => {
        dismiss(undefined);
      }}
      dialogContentProps={{
        showCloseButton: true
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { borderRadius: '20px' } }
      }}>
      <UserProfile user={user} />
    </Dialog>
  );
};
