import React, { useState } from 'react';
//, { useState, useEffect, useRef } from "react";
//import { Text, DefaultButton } from "@fluentui/react";
import { _dataProvider } from '../dataProvider/DataProvider';
import { PromptPickGroup } from './Dialogues';

const imagePrefix = '!image!';

const debugFlag = false;

/*
 */
interface IShare2Props {
  file: any;
  msg: string;
}

const Share2 = (props: IShare2Props) => {
  //Notification.permission *** the property of permission
  /*
    const img = "/to-do-notifications/img/icon-128.png";
    const text = `HEY! Your task "${title}" is now overdue.`;
    const nt = new Notification("To do list", { body: text, icon: img });
    //the above would generate the notification
    nt.close(); // this would dismiss the notification, as in the user read the message
  */

  // the following should be inside a user action, like clicking a button
  // maybe at the start of the page, or when the user login ****

  const [pickGroup4Post, setPickGroup4Post] = useState(true);

  Notification.requestPermission().then((result) => {
    console.log(result);
  });
  //search doesn't work for query string after /#/
  //const urlParams = new URLSearchParams(window.location.search);
  const qqq = window.location.href.split('?').pop();
  const uq = new URLSearchParams(qqq);
  console.log('share2: ', window.location.href, qqq, window.location.search);
  const title = uq.get('title') || '';
  const text = uq.get('text') || '';
  const url = uq.get('url') || '';
  const img = parseInt(uq.get('img') || '0');
  const message = `Share received: <${title}>, <${text}>, <${url}>, <${img}>`;
  const message2Post = `${title} ${text} ${url}`;

  /*
  useEffect(() => {
    this.prop.getOnline();
  });
  */

  const _pickGroup = (id: number) => {
    setPickGroup4Post(false);

    if (!id) {
      window.close();
      return; // dialogue is dismissed
    }

    let messageText;
    console.log('posting shared data: ', props.file);
    if (props.file) {
      _dataProvider.uploadFile2Chat(props.file).then((filename) => {
        if (!filename) {
          alert('Network connection failed, try again');
          window.close();
          return;
        }

        messageText = props.msg;
        if (props.msg !== imagePrefix) {
          messageText += `${encodeURIComponent(props.file.name)}!${props.file.size}!`;
        }
        _dataProvider.postGroupMessage(id, messageText + filename).then((result) => {
          if (!result) {
            alert('Network connection failed, try again');
            //return;
          } else {
            //alert("Post successful");
            //return;
          }
          window.close();
        });
      });
    } else {
      _dataProvider.postGroupMessage(id, message2Post).then((result) => {
        if (!result) {
          alert('Network connection failed, try again');
          //return;
        } else {
          //alert("Post successful");
          //return;
        }
        window.close();
      });
    }
    return;
  };

  return (
    <div className='App'>
      {debugFlag && <img id='image' className='App-logo' alt='logo' />}
      {debugFlag && (
        <header className='App-header'>
          <p>{message}</p>
        </header>
      )}
      {pickGroup4Post && <PromptPickGroup pickGroup={_pickGroup} />}
    </div>
  );
};

/*
  sendAttachmentAsync = async (message) => {
    try {
      let messageText;
      let localUri;
      if (message.image) {
        let actions = [];
        const MaxImageWidth = 1080;
        if (message.image.width > MaxImageWidth) {
          // Resize image if width is exceeding MaxImageWidth
          const ratio = message.image.width / MaxImageWidth;
          actions.push({ resize: { width: MaxImageWidth, height: message.image.height / ratio } });
        }

        // Compress image to 70% JPEG
        const result = await ImageManipulator.manipulateAsync(message.image.uri, actions, {
          compress: 0.7,
          format: ImageManipulator.SaveFormat.JPEG
        });

        localUri = result.uri;

        // Image: !image!fileName
        messageText = imagePrefix;
      } else if (message.audio) {
        // Audio: !audio!duration!fileName
        messageText = `${audioPrefix}${message.audio.duration}!`;
        localUri = message.audio.uri;
      } else if (message.file) {
        // File:  !file!filename!fileSize!fileName
        messageText = `${filePrefix}${encodeURIComponent(message.file.name)}!${message.file.size}!`;
        localUri = message.file.uri;
      }

      const result = await uploadChatFileAsync(getHttpsServer('chat/uploadFile'), localUri);
      const succeed = await showWebServiceCallErrorsAsync(result, 200);
      if (!succeed) {
        return;
      }

      if (typeof result.body.filename !== 'string') {
        Alert.alert(getI18nText('Upload failed, please try again!'));
        return;
      }

      const sendResult = await this.chatServer.sendMessageAsync({
        room: this.chatId,
        message: messageText + result.body.filename,
        isAnonymous: this.isAnonymous,
        shareInGroup: this.showGroups.length > 0 ? this.state.currentTag : 0
      });
      if (!sendResult) {
        Alert.alert(getI18nText('Message send failed, please try again!'));
        return;
      }

      this.setState({ replyMessageId: -1, replyMessageText: '' });
    } catch (error) {
      alert(error);
    }
  };
*/

export default Share2;
