import React, { useState, useEffect } from 'react';
import BibleNavigation from './BibleNavigation';
import { _dataProvider } from '../dataProvider/DataProvider';
import {
  IBookContent,
  IChapterContentAndAnswers
  //ILessonPreview,
} from '../dataProvider/IBookContent';

import { useParams } from 'react-router-dom';
import { getOrgLogo } from '../SharedCommon/OrgList';

import { pubContentUrl } from '../SharedCommon/utils';

const templateUri = './html/template.html';

import preval from 'preval.macro';
const dateTimeStamp = preval`module.exports = Date.now();`;

const PubLesson = () => {
  const [bookBible, setBookBible] = useState({
    // @ts-ignore.
    bookContent: undefined as IBookContent,
    bibleData: undefined as any
  });
  const params = useParams();
  const lessonId = params.id;
  const fileName = params.file;

  if (!lessonId || !fileName) {
    return (
      <p>
        Loading...wrong parameters: ${params.id}$ and ${params.file}$
      </p>
    );
  }
  /*
  const load = () => {
    //load Offer for props.date, that should be an array, but for now, just pick the first element
    // in the array, until we get another page that renders the list.
    _dataProvider
      .getPreviewContent(lessonId!, "free.json")
      .then((book) => {
        _dataProvider
          .getPreviewContent(lessonId!, fileName!)
          .then((chapter) => {
            const bookContent = chapter as IBookContent;
            chapter.bookTitle = book.title;
            document.title = book.title + " - " + chapter.title;
            setBookBible({
              bookContent,
              bibleData: bookBible.bibleData,
            });
          });
      });
  };
  */

  const load2 = () => {
    //load Offer for props.date, that should be an array, but for now, just pick the first element
    // in the array, until we get another page that renders the list.
    _dataProvider.getLessonPreview(lessonId!, fileName!).then((previewContent) => {
      const bookContent = previewContent.content;
      bookContent.bookTitle = previewContent.title;
      document.title = previewContent.title + ' - ' + bookContent.title;
      //document.title = book.title + " - " + chapter.title;
      setBookBible({
        bookContent,
        bibleData: bookBible.bibleData
      });
    });
  };

  //useEffect(load, []);
  useEffect(load2, []);

  if (!bookBible.bookContent) {
    return (
      <p>
        Loading... {params.id} and {params.file}
      </p>
    );
  }

  const _setBible = (bibleData: any): void => {
    setBookBible({ bookContent: bookBible.bookContent, bibleData });
  };

  const getAccessUrl = (src: string): string => {
    if (src.toLowerCase().startsWith('http://') || src.toLowerCase().startsWith('https://')) {
      return src;
    }
    const curl = pubContentUrl + '/lesson/' + lessonId + '/content/';
    return `${curl}${src}`;
  };

  // eslint-disable-next-line
  window.onmessage = (e:any):void => {
    if (typeof e.data !== 'string') {
      return;
    }
    const data = JSON.parse(e.data);
    switch (data.command) {
      case 'openLink':
        window.open(getAccessUrl(data.url));
        break;
      /*
      case "goToBible":
        _setBible(data);
        break;
      */
    }
  };

  const initContent = (): void => {
    console.log('initContent');
    // eslint-disable-next-line
    const frames:any = window.frames;
    const previewFrame = frames['preview'];

    //swap out a couple of functions
    //previewFrame.oldGetAnswer = previewFrame.getAnswer;
    //previewFrame.getAnswer = previewFrame.newGetAnswer;
    //previewFrame.oldCreateQuestionNode = previewFrame.createQuestionNode;
    previewFrame.createQuestionNode = previewFrame.newCreateQuestionNode;

    previewFrame.oldSendCmd = previewFrame.sendCmd;
    previewFrame.sendCmd = previewFrame.new_sendCmd;

    previewFrame.oldAddTextNode = previewFrame.addTextNode;
    previewFrame.addTextNode = previewFrame.new_addTextNode;

    previewFrame.oldCreateTextNode = previewFrame.createTextNode;
    previewFrame.createTextNode = previewFrame.new_createTextNode;

    /*
    bookContent.position = {
      day: 1,
      scrollTop: 0,
    };
    */
    const feedContent: IChapterContentAndAnswers = {
      lesson: bookBible.bookContent,
      accessToken: ''
    };
    previewFrame.content = feedContent;
    previewFrame.content.img = getOrgLogo(0);
    previewFrame.content.lesson.title =
      '小鸽子' + ' - ' + bookBible.bookContent.bookTitle + ' - ' + bookBible.bookContent.title;
    previewFrame.content.i18n = { NotAnswered: '未回答' };
    previewFrame.content.contentUrl = pubContentUrl + '/lesson/' + lessonId + '/content/';
    //previewFrame.content.title = bookContent.title;
    previewFrame.content.position = {
      day: 1,
      scrollTop: 0
    };

    //now, init the content into the phone emulator
    previewFrame.initContent();
    return;
  };
  /*
      <div style={{ lineHeight: "50px" }}>
          <img alt="iDigestApp" className="iDigestLogo" src={IDigestLogo} />
      </div>
*/
  return (
    <div style={{ height: '100%', display: 'flex' }}>
      {bookBible.bibleData && (
        <div className='TableOfContent BibleNavigation'>
          <BibleNavigation
            bibleData={bookBible.bibleData}
            bibleVersion1Language='简体中文'
            bibleVersion1Name='和合本'
            bibleVersion2Language='English'
            bibleVersion2Name='American Standard Version'
            merge={false}
            setBible={_setBible}
            setBibleCompareState={
              // eslint-disable-next-line
              (bbb: boolean) => (bbb = false)}
            contentHeight={'100%'}
          />
        </div>
      )}
      <div className='BookContent' style={{ height: '100%' }}>
        <div className='smartphone' style={{ height: '100%', border: 0 }}>
          <iframe
            title='Phone'
            id='idpreview'
            name='preview'
            src={templateUri + '?build=' + dateTimeStamp}
            style={{
              width: '100%',
              border: 'none',
              height: '100%'
            }}
            onLoad={initContent}></iframe>
        </div>
      </div>
    </div>
  );
};
/*
          <div id="idHeader" className="ColumnHead">
            内容
          </div>
*/
export default PubLesson;
