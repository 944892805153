import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, View } from 'react-native-web';
//import Video from 'react-native-web-video';

//eslint-disable-next-line
export default function MessageVideo({ containerStyle, videoProps, videoStyle, currentMessage }) {
  return (
    <View style={[styles.container, containerStyle]}>
      {/* 
      <Video
        {...videoProps}
        ref={(r) => { this.player = r; }}
        source={{ uri: currentMessage.video }}
        style={videoStyle}
        resizeMode="cover"
        onBuffer={this.onBuffer}
        onLoadStart={this.onLoadStart}
        onLoad={this.onLoad}
      /> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {}
});

MessageVideo.defaultProps = {
  currentMessage: {
    // video: null,
  },
  containerStyle: {},
  videoStyle: {
    width: 150,
    height: 100,
    borderRadius: 13,
    margin: 3
  },
  videoProps: {}
};

MessageVideo.propTypes = {
  currentMessage: PropTypes.object,
  /*
  containerStyle: ViewPropTypes.style,
  videoStyle: ViewPropTypes.style,
  */
  videoProps: PropTypes.object
};
